import Observable from "app/util/Observable";
import { BackgroundProps } from "./types";
import { DefaultProps } from "./config";

class Background extends Observable {
  private static instance?: Background;
  public static getInstance(): Background {
    if (!Background.instance) {
      Background.instance = new Background();
    }

    return Background.instance;
  }

  private _backgroundProps: BackgroundProps = DefaultProps;
  public get backgroundProps(): BackgroundProps {
    return this._backgroundProps;
  }
  private set backgroundProps(backgroundProps: BackgroundProps) {
    this._backgroundProps = backgroundProps;
    super.notify();
  }

  private constructor() {
    super();
  }

  public update(props: BackgroundProps): void {
    this.backgroundProps = props;
  }

  public reset(): void {
    this.backgroundProps = DefaultProps;
  }

}

export default (): Background => {
  return Background.getInstance();
};

