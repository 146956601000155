import CircularProgress from '@material-ui/core/CircularProgress';
import {
  useRef,
  forwardRef,
} from 'react';
import {
  useAuthModal,
} from 'app/components/common/Auth/useAuthModal';
import {
  authentication,
} from 'app/module';
import styles from './index.module.scss';

function Button(props, ref) {
  const {
    className,
    isDisabled,
    isProcessing,
    isAuthRequired = false,
    authProps,
    label,
    children,
    onClick: _onClick,
    _propsProgress,
    ..._props
  } = props;

  const isDebounce = useRef(false);

  const {
    showAuthModal,
  } = useAuthModal();

  const onClick = (e) => {
    if (!isDebounce.current && !isProcessing && !isDisabled) {
      isDebounce.current = true;

      if (isAuthRequired && authentication().authenticated === false) {
        showAuthModal(authProps);
      } else {
        _onClick && _onClick(e);
      }

      setTimeout(() => {
        isDebounce.current = false;
      }, 100);
    }
  };

  return (
    <div
      {..._props}
      ref={ref}
      className={`${styles.Button} ${isProcessing && styles.isProcessing} ${isDisabled &&
        styles.isDisabled} ${className}`}
      onClick={onClick}>
      {isProcessing ? (
        <CircularProgress size={20} color="#fff" {..._propsProgress} />
      ) : (
        children || label
      )}
    </div>
  );
}

export default forwardRef(Button);
