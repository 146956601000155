import { ReactElement } from 'react';
import SkemaLogo from '../skema.png';
import styles from './index.module.scss';

export default function Header(): ReactElement {
  return (
    <div className={styles.root}>
      <img src={SkemaLogo} alt='' />
    </div>
  );
}
