import { ReactElement, useState } from 'react';
import { Colors } from 'app/scenes/votePreview/config';
import styles from './index.module.scss';
import Video from './Video';
import { RootModuleProps, EventCampaign } from '../config';

export default function Projects(
  props: RootModuleProps,
): ReactElement {
  const { campaigns } = props;
  const [playingIndex, setPlayingIndex] = useState<number>(-1);

  const startPlaying = (): void => {
    setPlayingIndex(0);
  };

  const renderVideo = (
    item: EventCampaign,
    index: number,
  ): ReactElement => {
    const playing: boolean = playingIndex === index;

    const onEnded = (): void => {
      setPlayingIndex((index + 1) % campaigns.length);
    };

    return (
      <Video
        key={item.id}
        className={styles.video}
        style={{
          zIndex: playing ? 10 : 1,
        }}
        title={item.name}
        url={item.media}
        color={Colors[index]}
        playing={playing}
        onEnded={onEnded} />
    );
  };

  return (
    <div className={styles.root}>
      <div className={styles.title}>
        Les projets Skema
      </div>

      <div className={styles.videos}>
        {campaigns.map(renderVideo)}
      </div>

      <div
        className={styles.background} />

      {playingIndex === -1 && (
        <div
          className={styles.play}
          onClick={startPlaying} />
      )}
    </div>
  );
}
