import { ReactElement } from 'react';
import Logo from '../logo.png';
import styles from './index.module.scss';

export default function Header(): ReactElement {
  return (
    <div className={styles.root}>
      <img src={Logo} alt='' />
    </div>
  );
}
