import PropTypes from 'prop-types';
import React from 'react';
import Button from 'app/components/common/Button';
import { showCreditsModal } from './useCreditsModal';
import { renderPrice } from 'app/utils';
import styles from './index.module.scss';
// Assets
import IconPigWhite from './assets/pig-white.svg';
import IconPigBrand from './assets/pig-brand.svg';

Credits.propTypes = {
  className: PropTypes.string,
  isWhite: PropTypes.bool,
  amount: PropTypes.number,
  onClick: PropTypes.func,
};

export default function Credits(props) {
  const {
    className: _className,
    amount,
    isWhite = false,
    onClick,
  } = props;
  const className = isWhite ? styles.buttonToggleModal__isWhite : styles.buttonToggleModal__isBrand;

  return (
    <Button
      className={`${className} ${_className}`}
      onClick={onClick || showCreditsModal}
    >
      <img src={isWhite ? IconPigBrand : IconPigWhite} alt="" />
      {renderPrice(amount || 0)}
    </Button>
  );
}
