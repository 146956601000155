import {
  CategoryType,
} from 'app/graphql/types/category';

class CategoryFactory {

  public static createCategory(data: any): CategoryType.Category {
    return {
      id: data?.id || 0,
      name: data?.name || '',
      categoryType: data?.categoryType || 0,
      createdAt: data?.createdAt || 0,
      updatedAt: data?.updatedAt || 0,
      deletedAt: data?.deletedAt || undefined,
    };
  }

  public static createCategoryUpdate(data: any): CategoryType.CategoryUpdate {
    return {
      id: data?.id || 0,
      name: data?.name || '',
      categoryType: data?.categoryType || 0,
    };
  }

  public static createExplorerCategory(data: any[]): CategoryType.Explorer[] {
    return CategoryFactory.createCategoryGroups(data);
  }

  public static createExplorerCategoryUpdate(data: any[]): CategoryType.ExplorerUpdate[] {
    return CategoryFactory.createCategoryGroupsUpdate(data);
  }

  public static createExpertCategory(data: any[]): CategoryType.Expert[] {
    return CategoryFactory.createCategoryGroups(data);
  }

  public static createExpertCategoryUpdate(data: any[]): CategoryType.ExpertUpdate[] {
    return CategoryFactory.createCategoryGroupsUpdate(data);
  }

  private static createCategoryGroups(data: any[]): CategoryType.CategoryGroup[] {
    if (Array.isArray(data)) {
      return data.map(CategoryFactory.createCategoryGroupItem);
    }

    return [];
  }

  private static createCategoryGroupsUpdate(data: any[]): CategoryType.CategoryGroupUpdate[] {
    if (Array.isArray(data)) {
      return data.map(CategoryFactory.createCategoryGroupUpdateItem);
    }

    return [];
  }

  private static createCategoryGroupItem(data: any): CategoryType.CategoryGroup {
    const category: CategoryType.Category = CategoryFactory.createCategory(data?.category);

    return {
      id: data?.id || 0,
      categoryId: data?.categoryId || 0,
      userId: data?.userId || 0,
      category,
    };
  }

  private static createCategoryGroupUpdateItem(data: any): CategoryType.CategoryGroupUpdate {
    const category: CategoryType.CategoryUpdate = CategoryFactory.createCategoryUpdate(data?.category);

    return {
      categoryId: data?.categoryId || 0,
      userId: data?.userId || 0,
      category,
    };
  }

}

export default CategoryFactory;
