import {
  Post,
} from "app/graphql/types";

class PostFactory {

  public static createPost(data: any): Post {
    return new Post(data);
  }

  public static createPosts(data: any[]): Post[] {
    if (Array.isArray(data)) {
      return data.map(PostFactory.createPost);
    }

    return [];
  }

}

export default PostFactory;
