import ApolloClient from './ApolloClient';
import GenericRequest, {
  RequestMethod,
} from './GenericRequest';
import {
  Payload,
  Endpoint,
  GraphQL,
} from 'app/api';

class RequestFabric {

  public static async post(endpoint: Endpoint.Type, payload?: Payload.Type): Promise<unknown> {
    const request: GenericRequest = new GenericRequest(
      RequestMethod.Post,
      endpoint,
      payload,
    );

    return request.start();
  }

  public static async get(endpoint: Endpoint.Type, payload?: Payload.Type): Promise<unknown> {
    const request: GenericRequest = new GenericRequest(
      RequestMethod.Get,
      endpoint,
      payload,
    );

    return request.start();
  }

  public static async graphQL<Result>
  (
    query: any,
    variables?: GraphQL.Query.Payload,
    responseKey?: GraphQL.ResponseKey.Type,
    factory?: GraphQL.Query.Factory<Result>,
  ): Promise<Result> {
    const response: GraphQL.Query.Response = await ApolloClient.query({
      query,
      variables,
    });

    if (responseKey && factory) {
      return factory(response.data[responseKey]);
    }

    return response as unknown as Result;
  }
  
}

export default RequestFabric;
