
import {
  GetEvents,
  GetEvent,
} from 'app/graphql/queries';
import {
  VoteEvent,
} from 'app/graphql/mutations';
import {
  RequestFabric,
  GraphQL,
} from 'app/api';
import {
  EventFactory,
} from 'app/factory';
import {
  Event,
} from 'app/graphql/types';

class EventApi {

  public static getEvents(): Promise<Event[]> {
    return RequestFabric.graphQL<Event[]>(
      GetEvents,
      undefined,
      GraphQL.ResponseKey.Event.GetEvents,
      EventFactory.createEvents,
    );
  }

  public static getEvent(eventId: number): Promise<Event> {
    return RequestFabric.graphQL<Event>(
      GetEvent,
      {
        id: eventId,
      },
      GraphQL.ResponseKey.Event.GetEvent,
      EventFactory.createEvent,
    );
  }

  public static vote(
    eventId: number,
    campaignId: number,
    voteCampus?: string,
  ): Promise<void> {
    return RequestFabric.graphQL(
      VoteEvent,
      {
        id: eventId,
        campaignId,
        voteCampus,
      },
    );
  }

}

export default EventApi;