// import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { ROUTES } from 'app/config/routes';
import Modal from 'app/components/common/Modal';
import Button from 'app/components/common/Button';
import { useCreateCampaignModal } from './useCreateCampaignModal';
import { CampaignData } from 'app/graphql/types';
import { useMutation } from '@apollo/client';
import { CreateCampaign } from 'app/graphql/mutations';
import {
  useUser,
} from 'app/module';
import styles from './index.module.scss';

export default function ProjectCreateModalProvider() {
  const history = useHistory();

  const {
    user,
  } = useUser();

  const {
    isVisible,
    onClose,
    projectId
  } = useCreateCampaignModal();

  const [createCampaign, { loading: isProcessing }] = useMutation(CreateCampaign);

  const onSubmit = () => {
    createCampaign({
      variables: {
        data: new CampaignData({
          id: 0,
          name: '',
          description: '',
          isDraft: 1,
          userId: user?.id,
          projectId,
          fundingGoal: 0,
          fundingBreakdown: [{ "key": 0, "name": "Breakdown 1", "value": 0 }],
          milestoneBreakdown: [{ "key": 0, "name": "Breakdown 1", "value": 0 }],
        }),
      },
    }).then(({ data }) => {
      const { createCampaign } = data;
      const { id: campaignId, project } = createCampaign;
      const { projectUuid } = project;

      onClose();
      history.push(
        ROUTES.PROJECT_MANAGE.URL(projectUuid, {
          campaignId,
        })
      );
    });
  };

  if (!isVisible) {
    return null;
  }

  return (
    <Modal className={styles.Modal} onClose={onClose}>
      <div className={styles.title}>Créez une nouvelle action</div>

      <Button
        className={styles.buttonSubmit}
        isProcessing={isProcessing}
        label="Confirmer"
        onClick={onSubmit}
      />
    </Modal>
  );
}
