import { Unsubscribe } from 'app/global';
import {
  useEffect,
  useState,
} from 'react';
import director from './director';
import { GiftCodeResponse } from 'app/graphql/types';

export function useGiftCodeResponse(): GiftCodeResponse | null {
  const [giftCodeResponse, setGiftCodeResponse] = useState<GiftCodeResponse | null>(null);

  useEffect((): Unsubscribe => {
    return director().observe((): void => {
      setGiftCodeResponse(director().giftCodeResponse);
    });
  }, []);

  return giftCodeResponse;
}
