import React, { useState, useRef } from 'react';
import { createPortal } from 'react-dom';
import Button from 'app/components/common/Button';
import { useOutsideClick } from 'app/use/useOutsideClick';
import styles from './index.module.scss';
// Assets
import IconMenu from './assets/menu.svg';

export default function Menu(props) {
  const {
    className,
    labelLeft,
    labelRight,
    children,
    icon = IconMenu,
  } = props;
  const refButton = useRef();
  const refMenu = useRef();
  const refPosition = useRef({});
  const [isVisible, setIsVisible] = useState(false);
  const onClose = () => setIsVisible(false);
  const onShow = (e) => {
    const { height, width } = refButton.current.getBoundingClientRect();

    refPosition.current = {
      top: e.clientY + height / 2,
      left: e.clientX - width / 2,
    };

    setIsVisible(true);
  };

  useOutsideClick(refMenu, onClose);

  return (
    <>
      <Button ref={refButton} className={`${styles.Button} ${className}`} onClick={onShow}>
        {!!labelLeft && <span style={{ marginRight: icon ? 10 : 0 }}>{labelLeft}</span>}
        {!!icon && <img src={icon} alt="" />}
        {!!labelRight && <span style={{ marginLeft: icon ? 10 : 0 }}>{labelRight}</span>}
      </Button>

      {isVisible &&
        createPortal(
          <div ref={refMenu} className={styles.Menu} style={refPosition.current}>
            {children}
          </div>,
          window.document.body
        )}
    </>
  );
}

export function MenuItem(props) {
  const {
    isConfirmation, isExtra,
    onConfirm, onClick,
    renderExtra,
    ..._props
  } = props;
  const [isExtraVisible, setIsExtraVisible] = useState(false);

  const onShowExtra = () => setIsExtraVisible(true);
  const onHideExtra = () => setIsExtraVisible(false);

  return (
    <>
      <Button
        className={styles.MenuItem}
        _propsProgress={{
          size: 17,
        }}
        {..._props}
        onClick={(isConfirmation || isExtra) ? onShowExtra : onClick}
      >
        {props.children}
      </Button>

      {isExtraVisible && (
        renderExtra
          ? renderExtra({ className: styles.extra })
          : <div className={styles.extra}>
          <div className={styles.extra_title}>Êtes vous sûr ?</div>

          <Button className={styles.extra_yes} label="Oui" onClick={onConfirm} />

          <Button className={styles.extra_no} label="Non" onClick={onHideExtra} />
        </div>
      )}
    </>
  );
}
