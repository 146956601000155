
class Util {

  /**
   * Example uid: yiaj4e4
   */
  public static generateLocalUid(): string {
    return Math.random().toString(36).slice(2, 9);
  }

  public static deepCopyObject<Type>(object: Type): Type {
    return JSON.parse(JSON.stringify(object));
  }

  public static getRandomInteger(minInclusive: number, maxInclusive: number): number {
    minInclusive = Math.ceil(minInclusive);
    maxInclusive = Math.floor(maxInclusive);

    return Math.floor(Math.random() * (maxInclusive - minInclusive + 1)) + minInclusive;
  }

  public static getRandomArrayItem<Item>(array: Item[]): Item {
    return array[Util.getRandomInteger(0, array.length - 1)];
  }

  public static getRandomArrayItems<Item>(array: Item[], amount: number): Item[] {
    if (array.length < amount) {
      throw new Error('Array length must be greater than amount');
    }

    const randomItems: Item[] = [];
    const randomIndexes: { [key: string]: boolean } = {};

    while (randomItems.length < amount) {
      const randomIndex: number = Util.getRandomInteger(0, array.length - 1);

      if (randomIndexes[randomIndex] === true) {
        continue;
      }

      randomIndexes[randomIndex] = true;
      randomItems.push(array[randomIndex]);
    }

    return randomItems;
  }

  public static getUrlQueryValue(key: string): string {   
    return new URLSearchParams(window.location.search).get(key) || '';
  }

}

export default Util;
