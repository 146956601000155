import { gql } from "@apollo/client";

export const CreateProject = gql`
  mutation createProject($data: ProjectData!) {
    createProject(data: $data) {
      id
      projectUuid
    }
  }
`;

export const UpdateProject = gql`
  mutation updateProject($projectId: Int!, $data: IgnoreNoneUpdateProject!) {
    updateProject(projectId: $projectId, data: $data) {
      id
      projectUuid
      email
      name
      logo
      projectType
      description
      subtitle
      website
      descriptionFounder
      createdAt
      images
      videos
      interestCount
      accountNumber
      accountBic
      accountIban
      businessType
      businessName
      businessRegistrationNumber
      isRecusFiscaux
      recusFiscauxSignature
      isDraft
      status
      createdAt
      stripeAccountStatus
      stripeDocumentFront
      stripeDocumentFrontId
      stripeDocumentBack
      stripeDocumentBackId
      stripeDocumentType
      user {
        id
      }
      city {
        id
        city
        country
      }
      projectUsers {
        id
        userId
        projectId
        name
        avatar
        firstName
        lastName
        position
        role
        linkedinLink
      }
      goalProject {
        goalId
        projectId
        goal {
          id
          name
          color
          icon
          goalType
        }
      }
      categoryProject {
        categoryId
        projectId
        category {
          id
          name
          categoryType
        }
      }
      address {
        id
        address1
        address2
        name
        firstName
        lastName
        phone
        dob
        state
        zipCode
        addressType
        userId
        projectId
        lat
        lng
        city {
          id
          city
          country
        }
      }
    }
  }
`;

export const RequestProjectVerification = gql`
  mutation requestProjectVerification(
    $projectId: Int!
    $accountToken: String!
    $personalToken: String
    $accountHolderName: String
  ) {
    requestProjectVerification(
      projectId: $projectId
      accountToken: $accountToken
      personalToken: $personalToken
      accountHolderName: $accountHolderName
    )
  }
`;

export const DeleteProject = gql`
  mutation deleteProject($projectId: Int!) {
    deleteProject(projectId: $projectId)
  }
`;

export const UpdateProjectAdmin = gql`
  mutation updateProjectAdmin($projectId: Int!, $status: Int, $data: IgnoreNoneUpdateProject!) {
    updateProjectAdmin(projectId: $projectId, status: $status, data: $data) {
      id
      projectUuid
      email
      name
      logo
      projectType
      description
      subtitle
      website
      descriptionFounder
      createdAt
      interestCount
      accountNumber
      accountBic
      accountIban
      businessType
      businessName
      businessRegistrationNumber
      isDraft
      status
      createdAt
      stripeAccountStatus
      stripeDocumentFront
      stripeDocumentFrontId
      stripeDocumentBack
      stripeDocumentBackId
      stripeDocumentType
      user {
        id
      }
      city {
        id
        city
        country
      }
      projectUsers {
        id
        userId
        projectId
        name
        avatar
        firstName
        lastName
        position
        role
        linkedinLink
      }
      goalProject {
        goalId
        projectId
        goal {
          id
          name
          color
          icon
          goalType
        }
      }
      categoryProject {
        categoryId
        projectId
        category {
          id
          name
          categoryType
        }
      }
      address {
        id
        address1
        address2
        name
        firstName
        lastName
        phone
        dob
        state
        zipCode
        addressType
        userId
        projectId
        lat
        lng
        city {
          id
          city
          country
        }
      }
    }
  }
`;

export const ToggleProjectFavorite = gql`
  mutation toggleProjectFavorite($projectId: Int!) {
    toggleProjectFavorite(projectId: $projectId) {
      id
      projectUuid
    }
  }
`;

export const AcceptUserReuqest = gql`
  mutation acceptUserRequests($userRequestId: Int!, $replyType: Int!, $htmlText: String) {
    acceptUserRequests(userRequestId: $userRequestId, replyType: $replyType, htmlText: $htmlText)
  }
`;

export const UpdateVisibilityDemand = gql`
  mutation updateVisibilityDemand($projectId: Int!) {
    updateVisibilityDemand(projectId: $projectId)
  }
`;

export const StripeTosAccept = gql`
  mutation stripeTosAccept($projectId: Int!, $ip: String!) {
    stripeTosAccept(projectId: $projectId, ip: $ip)
  }
`;
