import {
  useEffect,
} from 'react';
import {
  useLocation,
} from 'react-router-dom';
import ReactGA from 'react-ga4';

export function useAnalytics() {
  const {
    pathname,
    search,
  } = useLocation();

  useEffect(() => {
    ReactGA.initialize('G-LCYXB93GPM');
  }, []);

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: `${pathname}${search}`,
    });
  }, [pathname, search]);
}
