import {
  useState,
  ChangeEvent,
} from 'react';
import styles from './index.module.scss';
import Button from 'app/components/common/Button';
import InputText from 'app/components/common/Input/Text';
import isEmail from 'validator/lib/isEmail';
import director from '../director';
import { CodeLength } from './config';
import { CodeProps } from './types';
import Header from '../Header';
import Util from 'app/util';

function Code(props: CodeProps): JSX.Element {
  const [code, setCode] = useState<string>((): string => {
    return Util.getUrlQueryValue('code');
  });

  const [email, setEmail] = useState<string>((): string => {
    return Util.getUrlQueryValue('email');
  });

  const [loading, setLoading] = useState<boolean>(false);

  const codeCharacters: JSX.Element[] = [];

  const submitDisabled: boolean = (
    isEmail(email) === false ||
    code.length !== CodeLength
  );

  for (let i = 0; i < CodeLength; i++) {
    codeCharacters[i] = (
      <div key={i}>
        {code.charAt(i)}
      </div>
    );
  }

  const onChangeCode = (event: ChangeEvent<HTMLInputElement>): void => {
    setCode(
      event.target.value.slice(0, CodeLength)
    );
  };

  const onSubmit = async (): Promise<void> => {
    setLoading(true);

    const success: boolean = await director().submitCode({
      email,
      code,
    });

    if (success === false) {
      setLoading(false);
    }
  };

  return (
    <div className={`${styles.root} ${props.className}`}>
      <Header />

      <div className={styles.title1}>
        Entrez le code que vous avez reçu
      </div>

      <div className={styles.title2}>
        par email ou par coupon
      </div>

      <InputText
        className={styles.email}
        placeholder='Entrez votre e-mail'
        autoComplete="email"
        type='email'
        name='email'
        value={email}
        onChangeText={setEmail} />

      <div className={styles.codeCharacters}>
        {codeCharacters}

        <input
          type='text'
          value={code}
          onChange={onChangeCode} />
      </div>

      <Button
        className={styles.submit}
        isDisabled={submitDisabled}
        isProcessing={loading}
        onClick={onSubmit}>
        Activer
      </Button>
    </div>
  );
}

export default Code;
