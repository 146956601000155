import React, { PureComponent } from 'react';
import Button from 'app/components/common/Button';
import styles from './index.module.scss';

export default function ErrorMessage(props) {
  return (
    <div className={styles.RootContainer}>
      <div className={styles.label}>{props.message}</div>
      <div className={styles.ButtonContainer}>
        <Button className={styles.buttonSkip} label="Cancel" onClick={props.onCancel} />
        <Button className={styles.buttonNext} label="Créer mon mot de passe" onClick={props.onOk} />
      </div>
    </div>
  );
}
