import { gql } from '@apollo/client';

export const getCampaigns = gql`
  query getCampaigns($limit: Int, $offset: Int, $projectId: Int, $q: String, $status: Int) {
    campaigns(limit: $limit, offset: $offset, projectId: $projectId, q: $q, status: $status) {
      id
      campaignUuid
      name
      project {
        id
        name
        logo
      }
    }
  }
`;

export const getCampaignWithdrawals = gql`
  query getCampaignWithdrawals($limit: Int, $offset: Int, $campaignId: Int, $status: Int) {
    withdrawals(limit: $limit, offset: $offset, campaignId: $campaignId, status: $status) {
      id
      amount
      status
      userId
      campaignId
      withdrawAt
      createdAt
      updatedAt
      isOffline
    }
  }
`;

export const getProjectCampaigns = gql`
  query getProjectCampaigns($limit: Int, $offset: Int, $projectId: Int) {
    campaigns(limit: $limit, offset: $offset, projectId: $projectId) {
      id
      campaignUuid
      name
      description
      fundingGoal
      fundingBreakdown
      milestoneBreakdown
      startDate
      endDate
      knowReporterSystem
      receivePhysicalHelp
      financedAmount
      financedText
      isDraft
      status
      expertHelp
      expertHelpCategory {
        id
        name
        categoryType
      }
      explorerHelp
      explorerHelpCategory {
        id
        name
        categoryType
      }
      postsCount
      campaignUsers {
        user {
          id
          email
          name
          firstName
          lastName
          dob
          helperDescription
          description
          expertTitle
          explorerCity {
            id
            city
            country
          }
          city {
            id
            city
            country
          }
          explorerCategory {
            id
            categoryId
            userId
            category {
              id
              name
              categoryType
            }
          }
          expertCategory {
            id
            categoryId
            userId
            category {
              id
              name
              categoryType
            }
          }
          campaignRoles
        }
        name
        role
      }
    }
  }
`;

export const getProjectManageCampaigns = gql`
  query getProjectCampaigns($limit: Int, $offset: Int, $projectId: Int) {
    manageCampaigns(limit: $limit, offset: $offset, projectId: $projectId) {
      id
      campaignUuid
      name
      status
      description
      fundingGoal
      fundingBreakdown
      milestoneBreakdown
      startDate
      endDate
      knowReporterSystem
      receivePhysicalHelp
      expertHelp
      expertHelpCategory {
        id
        name
        categoryType
      }
      explorerHelp
      explorerHelpCategory {
        id
        name
        categoryType
      }
      postsCount
      notificationCount
      strategyId
    }
  }
`;

export const getCampaignStrategy = gql`
  query campaignStrategy($campaignId: Int!) {
    campaignStrategy(campaignId: $campaignId) {
      id
      strategyUuid
      name
      startAt
      isScenario
      strategyDays {
        id
        whichDay
        whichTime
        itemType
        itemTypeId
        item
        itemPost {
          id
          postUuid
          content
          url
          postType
          likes
          tag
          publishedAt
          isDraft
        }
      }
    }
  }
`;

export const getCampaignUsers = gql`
  query campaignUsers($campaignId: Int!) {
    campaignUsers(campaignId: $campaignId) {
      user {
        id
        email
        name
        avatar
        phone
        helperDescription
        city {
          country
          city
        }
      }
      name
      role
    }
  }
`;

export const getUserRequestForCampaign = gql`
  query getUserRequests($campaignId: Int, $userId: Int) {
    userRequests(campaignId: $campaignId, userId: $userId) {
      id
      userId
      campaignId
      role
      status
      user {
        id
        userUuid
        name
        firstName
        lastName
        avatar
        title
        expertTitle
        description
      }
    }
  }
`;

export const getContributionsForCampaign = gql`
  query getContributionsForCampaign($campaignId: Int, $userId: Int) {
    contributions(campaignId: $campaignId, userId: $userId) {
      id
      contributionUuid
      userId
      campaignId
      amount
      transactionId
      createdAt
      updatedAt
      user {
        id
        userUuid
        name
        firstName
        lastName
        avatar
        title
        expertTitle
        description
      }
    }
  }
`;

export const getContributionsForCampaignFullUser = gql`
  query getContributionsForCampaign($campaignId: Int, $userId: Int) {
    contributions(campaignId: $campaignId, userId: $userId) {
      id
      contributionUuid
      userId
      campaignId
      amount
      transactionId
      createdAt
      updatedAt
      user {
        id
        userUuid
        name
        firstName
        lastName
        avatar
        title
        expertTitle
        description
        email
        address {
          address1
          address2
          zipCode
          city {
            city
            country
          }
        }
      }
    }
  }
`;

export const getSingleCampaign = gql`
  query campaign($uuid: String!) {
    campaign(uuid: $uuid) {
      id
      campaignUuid
      name
      description
      fundingGoal
      fundingBreakdown
      milestoneBreakdown
      startDate
      endDate
      knowReporterSystem
      receivePhysicalHelp
      expertHelp
      explorerHelp
      campaignUsers {
        user {
          id
          name
        }
        role
      }
      project {
        id
        projectUuid
        name
        businessType
        projectType
        logo
      }
    }
  }
`;

export const getSingleCampaignManage = gql`
  query campaign($uuid: String!) {
    campaign(uuid: $uuid) {
      id
      campaignUuid
      name
      description
      fundingGoal
      fundingBreakdown
      milestoneBreakdown
      startDate
      endDate
      knowReporterSystem
      receivePhysicalHelp
      expertHelp
      explorerHelp
      campaignUsers {
        user {
          id
          name
        }
        role
      }
      project {
        id
        projectUuid
        name
        businessType
        projectType
        logo
      }
      strategyId
      strategy {
        id
        strategyUuid
        name
        startAt
        isScenario
        strategyDays {
          id
          whichDay
          whichTime
          itemType
          itemTypeId
          item
          itemPost {
            id
            postUuid
            content
            url
            postType
            likes
            tag
            publishedAt
          }
        }
      }
    }
  }
`;
