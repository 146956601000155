
export namespace Endpoint {

  export enum User {
    Login = 'user/login',
    LoginAdmin = 'user/login-admin',
    LoginSocial = 'user/social-login',
    Register = 'user/register',
    Logout = 'user/logout',
    ForgotPassword = 'user/forgot',
    ResetPassword = 'user/forgot/reset', 
  }

  export enum Payment {
    GetIntent = 'user/payment/intents',
    GetMethods = 'user/payment/methods',
    DeleteMethod = 'user/payment/methods/delete',
  }

  export type Type = (
    User |
    Payment
  );

}


