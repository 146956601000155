
// REFACTOR BEGINS HERE //

export namespace CategoryType {

  export interface Category {
    id: number;
    name: string;
    categoryType: number;
    createdAt: number;
    updatedAt: number;
    deletedAt?: number
  }

  export interface CategoryUpdate {
    id: number;
    name: string;
    categoryType: number;
  }

  export type CategoryGroup = {
    id: number;
    categoryId: number;
    userId: number;
    category: Category;
  }

  export type CategoryGroupUpdate = {
    categoryId: number;
    userId: number;
    category: CategoryUpdate;
  }

  export interface Explorer extends CategoryGroup {}
  export interface Expert extends CategoryGroup {}
  export interface ExplorerUpdate extends CategoryGroupUpdate {}
  export interface ExpertUpdate extends CategoryGroupUpdate {}

}

// REFACTOR BEGINS HERE //

export interface Category {
  id: number;
  name: string;
  categoryType: number;
}

export class CategoryData {
  id: number;
  name: string;
  categoryType: number;

  constructor(data: Category) {
    this.id = data.id;
    this.name = data.name;
    this.categoryType = data.categoryType;
  }
}

export type GqlCategoryResponse = {
  categories: [Category];
};
