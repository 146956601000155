import { useState, ChangeEvent, useRef, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useMutation } from '@apollo/client';
import { Slider } from '@material-ui/core';
import Modal from 'app/components/common/Modal';
import InputText from 'app/components/common/Input/Text';
import Button from 'app/components/common/Button';
import Checkbox from 'app/components/common/Input/Checkbox';
import { CreateContribution } from 'app/graphql/mutations';
import { ROUTES } from 'app/config/routes';
import { renderPrice } from 'app/utils';
import { addContribution } from 'app/use/useCart';
import {
  user as userModule,
  useUser,
  useAuthentication,
} from 'app/module';
import {
  ContributeProps,
} from './types';
import Credits from 'app/components/common/CreditsModal';
import styles from './index.module.scss';
// Assets
import IconDone from './assets/done.svg';
import IconCheckmark from './assets/checkmark.svg';
import IconGift from './assets/gift.svg';
import { UserTypes } from 'app/graphql/types';
import ShareLinkedin from 'app/components/ShareLinkedin';

export default function Contribute(props: ContributeProps): JSX.Element {
  const { className, label, campaign, onContributionCompleted } = props;
  const [amount, setAmount] = useState<number>(10);
  const [customAmount, setCustomAmount] = useState('');
  
  const [isInfoRead, setIsInfoRead] = useState<boolean>(false);
  const [contributeDone, setContributeDone] = useState<boolean>(false);
  const [annonymous, setAnnonymous] = useState<boolean>(false);
  const [giftVisible, setGiftVisible] = useState<boolean>(false);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [shareLinkedinVisible, setShareLinkedinVisible] = useState<boolean>(false);
  const [selectedGift, setSelectedGift] = useState<UserTypes.Gift | null>(null);
  const refModalVisible = useRef(modalVisible);
  const history = useHistory();
  let campaignEnded = false;

  if (campaign?.endDate) {
    campaignEnded = new Date() > campaign?.endDate;
  }

  const {
    authenticated,
  } = useAuthentication();

  const {
    user,
  } = useUser();

  if (modalVisible !== refModalVisible.current) {
    refModalVisible.current = modalVisible;

    if (modalVisible) {
      if (user?.gifts?.length) {
        for (let i = 0; i < user.gifts.length; i++) {
          if (user.gifts[i].wallet) {
            setAmount(user.gifts[i].wallet);
            setSelectedGift(user.gifts[i]);
            setGiftVisible(true);
            break;
          }
        }
      }
    }
  }

  const wallet = (
    selectedGift?.wallet ||
    user?.wallet ||
    0
  );

  useEffect((): void => {
    if (modalVisible === false) {
      setAmount(10);
      setSelectedGift(null);
      setGiftVisible(false);
    }
  }, [modalVisible]);

  const [createContribution, { loading: isProcessing }] = useMutation(CreateContribution, {
    variables: {
      campaignId: campaign.id,
      amount,
      isAnonymousContribution: annonymous,
      giftId: selectedGift?.id,
    },
    onCompleted() {
      userModule().fetch();
      setContributeDone(true);

      if (selectedGift) {
        setShareLinkedinVisible(true)
      }

      if (onContributionCompleted) {
        onContributionCompleted();
      }
    },
  });

  const onShowContribute = (): void => {
    setModalVisible(true);
  };

  const onHideContribute = (): void => {
    setModalVisible(false);
    setShareLinkedinVisible(false);
    setContributeDone(false);
  };

  const onHideInfo = (): void => {
    setIsInfoRead(true);
  };

  const onHideGift = (): void => {
    setGiftVisible(false);
  };

  const hideShareLinkedin = (): void => {
    setShareLinkedinVisible(false);
  };

  const onChangeAmount = (event: ChangeEvent<{}>, nextAmount: number | number[]): void => {
    setAmount(nextAmount as number);
    setCustomAmount('');
  };

  const onChangeCustomAmount = (value: string): void => {
    if (value === '') {
      setCustomAmount('');
      return;
    }

    let nextAmount = parseInt(value, 10);

    if (!isNaN(nextAmount)) {
      nextAmount = nextAmount <= 0 ? 1 : nextAmount;

      if (selectedGift) {
        nextAmount = (
          nextAmount > selectedGift.wallet
            ? selectedGift.wallet
            : nextAmount
        );
      }

      setCustomAmount(nextAmount.toString());
      setAmount(nextAmount);
    }
  };

  const onSubmit = (): void => {
    if (selectedGift) {
      createContribution();
      return;
    }

    if (wallet >= amount) {
      createContribution();
      return;
    }

    addContribution({
      campaign,
      annonymous,
      amount: Math.abs(wallet - amount),
      contributeAmount: amount,
    });

    if (authenticated) {
      history.push(ROUTES.CHECKOUT.URL);
    } else {
      history.push(ROUTES.AUTH.URL, {
        nextRoute: ROUTES.CHECKOUT.URL,
      });
    }
  };

  const renderBody = (): JSX.Element => {
    if (giftVisible) {
      return (
        <>
          <Credits
            className={styles.Credits}
            onClick={() => null}
            amount={user?.gifts[0].amount} />

          <div className={styles.yourGift}>
            Vous avez un cadeau à utiliser !
          </div>

          <img src={IconGift} alt="" />

          <Button
            className={styles.submit}
            label="J’utilise"
            onClick={onHideGift} />
        </>
      );
    }

    if (contributeDone) {
      return (
        <>
          <img
            className={styles.doneOk}
            src={IconDone}
            alt="" />

          <div
            className={styles.yourDonationDone}>
            Votre donation de <span className={styles.wallletDone}>{renderPrice(amount)}</span>{' '}
            a bien été prise en compte.
          </div>

          <Button
            className={styles.submit}
            isProcessing={isProcessing}
            label="Ok !"
            onClick={onHideContribute} />
        </>
      );
    }

    if (!isInfoRead) {
      return (
        <>
          <div className={styles.infoTitle}>
            Chaque don sur Kunbis crée de l’impact  🌍
          </div>

          <div className={styles.infoText}>
            En soutenant une action sur Kunbis, vous :
            <br /><br />
            <div className={styles.infoTextRow}>
              <img src={IconCheckmark} alt='' />

              <div>
                <span>agissez</span> et contribuez à rendre notre société plus durable et plus équitable
              </div>
            </div>
            <div className={styles.infoTextRow}>
              <img src={IconCheckmark} alt='' />

              <div>
                <span>rejoignez</span> un mouvement qui lutte pour plus de justice sociale ou environnementale
              </div>
            </div>
            <div className={styles.infoTextRow}>
              <img src={IconCheckmark} alt='' />

              <div>
                <span>encouragez</span> la transparence des projets
              </div>
            </div>
            <div className={styles.infoTextRow}>
              <img src={IconCheckmark} alt='' />

              <div>
                <span>confirmez</span> que vous êtes une personne extra ;)
              </div>
            </div>
          </div>

          <Button
            className={styles.submit}
            isProcessing={isProcessing}
            label="Ok !"
            onClick={onHideInfo} />
        </>
      );
    }

    return (
      <>
        {authenticated === true && (
          <Credits
            className={styles.Credits}
            amount={wallet} />
        )}

        <div className={styles.yourDonation}>
          Votre don
          {!!selectedGift && (
            <span>
              de {selectedGift.user.name}
            </span>
          )}
        </div>

        <div className={styles.amount}>
          {amount} €
        </div>

        <Slider
          value={amount}
          max={selectedGift?.wallet || 500}
          min={1}
          onChange={onChangeAmount}
          aria-labelledby="continuous-slider" />

        <InputText
          className={styles.customInput}
          placeholder="Un autre montant ?"
          value={customAmount}
          onChangeNumber={onChangeCustomAmount} />
        
        <Checkbox
          isChecked={annonymous}
          onChange={setAnnonymous}
          text='Je préfère que mon nom reste masqué sur la liste publique des contributeurs.' />

        <Button
          className={styles.submit}
          isProcessing={isProcessing}
          label="Ok !"
          onClick={onSubmit} />
      </>
    );
  };

  return (
    <>
      <Button
        className={className}
        label={label}
        isDisabled={campaignEnded}
        onClick={onShowContribute} />

      {modalVisible && (
        <>
          <Modal onClose={onHideContribute}>
            {renderBody()}
          </Modal>

          {shareLinkedinVisible && (
            <ShareLinkedin
              shareName={selectedGift?.user.name || ''}
              shareAmount={amount}
              projectUuid={campaign.project?.projectUuid || ''}
              onClose={hideShareLinkedin} />
          )}
        </>
      )}
    </>
  );
}
