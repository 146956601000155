import { useEffect, useState } from 'react';
import { SingleCheckoutInput } from 'app/graphql/types';
import { renderPrice, getUniqueId } from 'app/utils';
import { wordingMultiple } from 'app/utils/wording';
import { addMonths } from 'date-fns';
import { SUBSCRIPTION_CONFIG } from 'app/config';
import { ROUTES } from 'app/config/routes';
import { getRouterHistory } from 'app/utils/router';

const __storageKeyCart = '@sk-cart';
const __listeners = {};

let __subscriptionCartItem = null;

function getCart() {
  const cart = localStorage.getItem(__storageKeyCart);

  return cart ? JSON.parse(cart) : [];
}

function getSubscriptionCartItem() {
  return __subscriptionCartItem;
}

function clearCart() {
  dispatchListeners([]);
}

function clearSubscriptionCartItem() {
  __subscriptionCartItem = null;
}

function removeCartItemByIndex(index) {
  const filterItem = (item, _index) => {
    return _index !== index;
  };

  dispatchListeners(getCart().filter(filterItem));
}

function addToCart(item) {
  // Subscriptions are separated from the cart
  // And will be checked out as single entity
  if (item.isSubscription) {
    __subscriptionCartItem = item;

    getRouterHistory().push(ROUTES.CHECKOUT.URL);

    return;
  }

  const nextCart = getCart();

  nextCart.push(item);

  clearSubscriptionCartItem();
  dispatchListeners(nextCart);
}

function dispatchListeners(nextCart) {
  localStorage.setItem(__storageKeyCart, JSON.stringify(nextCart));

  for (const key in __listeners) {
    __listeners[key](nextCart);
  }
}

function constructPlanTitle(props) {
  const {
    isGift,
    isSubscription,
    intervalType,
    intervalCount,
    durationType,
    durationCount,
    project,
    amount,
  } = props;

  const styleSpan = {
    color: '#5A42EF',
    fontWeight: 'bold',
  };

  let textEnd = ' pour changer le cours des choses.';
  let text = '';
  let htmlEnd = ' pour changer le cours des choses.';
  let html = '';
  let textInterval = '';
  let textDuration = '';

  if (project?.name) {
    text = `${project.name} recevra une donation de`;
    textEnd = '.';
    html = (
      <>
        <span style={styleSpan}>{project.name}</span> recevra une donation de
      </>
    );
    htmlEnd = '.';
  } else if (isGift) {
    text = 'Votre ami vous a offert';
    html = (
      <>
        <span style={styleSpan}>Votre ami</span> vous a offert
      </>
    );
  } else {
    text = 'Vous disposez de';
    html = (
      <>
        <span style={styleSpan}>Vous</span> disposez de
      </>
    );
  }

  text = `${text} ${renderPrice(amount)}`;
  html = (
    <>
      {html} <span style={styleSpan}>{renderPrice(amount)}</span>{' '}
    </>
  );

  if (isSubscription) {
    const textIntervalCount = intervalCount > 1 ? intervalCount : '';
    let textIntervalTypeA;
    let textIntervalTypeB;

    if (intervalType === SUBSCRIPTION_CONFIG.INTERVAL_TYPES.WEEK) {
      textIntervalTypeA = 'toutes';
      textIntervalTypeB = 'semaines';
    } else {
      textIntervalTypeA = 'tous';
      textIntervalTypeB = 'mois';
    }

    textInterval = `${textIntervalTypeA} les ${textIntervalCount} ${textIntervalTypeB}`;
    text = `${text} ${textInterval}`;
    html = (
      <>
        {html}
        {`${textIntervalTypeA} les `}
        <span style={styleSpan}>{`${textIntervalCount} ${textIntervalTypeB}`}</span>{' '}
      </>
    );

    if (durationCount) {
      let textDurationType;

      if (durationType === SUBSCRIPTION_CONFIG.DURATION_TYPES.MONTH) {
        textDurationType = 'mois';
      } else {
        textDurationType = wordingMultiple('an', durationCount > 1);
      }

      textDuration = `pendant ${durationCount} ${textDurationType}`;
      text = `${text} ${textDuration}`;
      html = (
        <>
          {html}
          pendant <span style={styleSpan}>{`${durationCount} ${textDurationType}`}</span>
        </>
      );
    }
  }

  return {
    text: `${text}${textEnd}`,
    textInterval,
    textDuration,
    html: (
      <>
        {html}
        {htmlEnd}
      </>
    ),
  };
}

function generateCustomPlan(data) {
  const {
    isGift,
    isSubscription,
    amount,
    intervalType,
    intervalCount,
    durationType,
    durationCount,
    project,
    giftProps,
  } = data;
  let stopAt = null;

  if (isSubscription) {
    stopAt = addMonths(
      new Date(),
      durationType === SUBSCRIPTION_CONFIG.DURATION_TYPES.YEAR ? durationCount * 12 : durationCount
    );
  }

  return new SingleCheckoutInput({
    ...(giftProps || {}),
    name: constructPlanTitle(data).text,
    projectId: project?.id,
    interval: intervalType,
    quantity: 1,
    stopAt,
    amount,
    isSubscription,
    isGift,
    intervalCount,
  });
}

function addCustomPlan(data) {
  addToCart(generateCustomPlan(data));
}

function addStripePlan(plan, props) {
  const {
    type,
    recurring,
    amount,
    product: { metadata },
  } = plan;
  const { isGift, giftProps, project } = props;

  let isSubscription = false;
  let intervalType = null;
  let intervalCount = null;

  if (type === 'recurring') {
    isSubscription = true;
    intervalType = recurring.interval;
    intervalCount = recurring.interval_count;
  }

  addToCart(
    new SingleCheckoutInput({
      ...(giftProps || {}),
      name: constructPlanTitle({
        ...props,
        isSubscription,
        intervalType,
        intervalCount,
        amount,
      }).text,
      projectId: project?.id,
      interval: intervalType,
      quantity: 1,
      amount,
      isSubscription,
      isGift,
      intervalCount,
      stopAt: metadata?.durationType
        ? addMonths(
            new Date(),
            metadata?.durationType === SUBSCRIPTION_CONFIG.DURATION_TYPES.YEAR
              ? parseInt(metadata?.durationCount) * 12
              : parseInt(metadata?.durationCount)
          )
        : null,
    })
  );
}

function addContribution(data) {
  const { campaign, amount, contributeAmount, annonymous } = data;
  const { id: campaignId, name: campaignName } = campaign;

  addToCart(
    new SingleCheckoutInput({
      campaignId,
      isAnonymousContribution: annonymous,
      name: `Soutenir ${campaignName}`,
      amount,
      contributeAmount,
    })
  );
}

function addCredit(data) {
  const { amount } = data;

  addToCart(
    new SingleCheckoutInput({
      name: `Vous disposez d’un budget de ${renderPrice(amount)} pour changer le cours des choses.`,
      amount,
      contributeAmount: amount,
    })
  );
}

function useCart() {
  const [cart, setCart] = useState(() => {
    return getCart();
  });

  const clear = () => {
    clearCart();
  };

  const removeItem = (index) => {
    removeCartItemByIndex(index);
  };

  useEffect(() => {
    const key = getUniqueId();

    __listeners[key] = setCart;

    return () => {
      delete __listeners[key];
    };
  }, []);

  return {
    cart,
    clear,
    removeItem,
  };
}

export {
  getSubscriptionCartItem,
  clearSubscriptionCartItem,
  constructPlanTitle,
  generateCustomPlan,
  addContribution,
  addCustomPlan,
  addStripePlan,
  addCredit,
  useCart,
};
