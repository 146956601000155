import {
  Event,
  EventEmbedCampaign,
  EventMedia,
} from 'app/graphql/types';
import CampaignFactory from './Campaign';

class EventFactory {

  public static createEvent(data: any): Event {
    return {
      id: data?.id || 0,
      title: data?.title || '',
      startAt: data?.startAt || 0,
      endAt: data?.endAt || 0,
      userId: data?.userId || 0,
      createdAt: data?.createdAt || 0,
      updatedAt: data?.updatedAt || 0,
      deletedAt: data?.deletedAt || 0,
      isReplied: data?.isReplied || false,
      votesCount: data?.votesCount || 0,
      campaigns: EventFactory.createEventEmbedCampaigns(data.campaigns),
    };
  }

  public static createEvents(data: any[]): Event[] {
    if (Array.isArray(data)) {
      return data.map(EventFactory.createEvent);
    }

    return [];
  }

  public static createEventEmbedCampaign(data: any): EventEmbedCampaign {
    return {
      id: data?.id || 0,
      description: data?.description || '',
      logo: data?.logo || '',
      media: EventFactory.createMedia(data?.media),
      votesCount: data?.votesCount || 0,
      campaign: data?.campaign === null
        ? null
        : CampaignFactory.createCampaign(data?.campaign),
      campaignData: JSON.parse(data.campaignData),
    };
  }

  public static createEventEmbedCampaigns(data: any): EventEmbedCampaign[] {
    if (Array.isArray(data)) {
      return data.map(EventFactory.createEventEmbedCampaign);
    }

    return [];
  }

  private static createMedia(media?: any): EventMedia[] {
    if (Array.isArray(media)) {
      const nextMedia: EventMedia[] = [];

      for (let i = 0, ix = 0; i < media.length; i++) {
        if (typeof media[i] === 'string') {
          try {
            const eventMedia: EventMedia = JSON.parse(media[i]);

            nextMedia[ix++] = eventMedia;
          } catch (err) {
            console.log(err);
          }
        }
      }

      return nextMedia;
    }

    return [];
  }

}

export default EventFactory;
