import Button from 'app/components/common/Button';
import styles from './index.module.scss';
import { WhatNextProps } from './types';
import Image from './background.svg';
import { useState } from 'react';
import InternalContribution from './InternalContribution';
import { useGiftCodeResponse } from '../useGiftCodeResponse';
import { GiftCodeResponse } from 'app/graphql/types';
import director from '../director';
import { Page } from '../config';
import { ROUTES } from 'app/config/routes';

function WhatNext(props: WhatNextProps): JSX.Element {
  const [internalContributionVisible, setInternalContributionVisible] = useState<boolean>(false);

  const giftCodeResponse: GiftCodeResponse | null = useGiftCodeResponse();

  const showInternalContribution = (): void => {
    setInternalContributionVisible(true);
  };

  const hideInternalContribution = (): void => {
    setInternalContributionVisible(false);
  };

  const goToHowTo = (): void => {
    director().setPage(Page.HowTo);
  };

  const goToKunbis = (): void => {
    // @ts-ignore
    window.location = ROUTES.HOME.URL;
  };

  return (
    <div className={`${styles.root} ${props.className}`}>
      <div className={styles.title}>
        Je veux donner mon don de {giftCodeResponse?.amount} euros
      </div>

      <div className={styles.buttons}>
        <Button
          className={styles.button}
          onClick={showInternalContribution}>
          <img src={Image} alt='' />
          <span>
            A la Fondation Rennes School of Business
          </span>
          <p>
            Voir le détail
          </p>
        </Button>

        <Button
          className={styles.button}
          onClick={goToHowTo}>
          <img src={Image} alt='' />
          <span>
            En découvrant et aidant les associations à impact sur KUNBIS
          </span>
          <p>
            Accéder aux associations
          </p>
        </Button>
      </div>

      {internalContributionVisible && (
        <InternalContribution
          onDone={goToKunbis}
          onClose={hideInternalContribution} />
      )}
    </div>
  );
}

export default WhatNext;
