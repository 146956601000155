import CircularProgress from '@material-ui/core/CircularProgress';
import styles from './index.module.scss';

export default function Loading() {
  return (
    <div className={styles.root}>
      <CircularProgress />
    </div>
  );
}
