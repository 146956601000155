import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useAuthModal } from 'app/components/common/Auth/useAuthModal';
import Button from 'app/components/common/Button';
import styles from './index.module.scss';
// Assets
import ImageBackground from './assets/illustration.svg';

export default function AuthRequired() {
  const { showAuthModal } = useAuthModal();

  return (
    <>
      <Helmet>
        <title>Connectez-vous pour continuer</title>
        <meta name="og:title" content="Connectez-vous pour continuer" />
      </Helmet>

      <div className={styles.background} style={{ backgroundImage: `url(${ImageBackground})` }} />

      <div className={styles.Root}>
        <div className={styles.title}>Connectez-vous pour continuer</div>

        <Button className={styles.button} onClick={showAuthModal} label="Connexion" />
      </div>
    </>
  );
}
