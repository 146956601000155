import {
  useState,
  useEffect,
} from 'react';
import {
  user,
  UserData,
} from 'app/module';

function useUser(): UserData {
  const [data, setData] = useState<UserData>(() => {
    return user().getObservableData();
  })
  
  useEffect(() => {
    return user().observer.observe(setData);
  }, []);

  return data;
}

export default useUser;
