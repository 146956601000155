import { gql } from '@apollo/client';

export const getNotifications = gql`
  query getNotifications($campaignId: Int, $notificationType: Int) {
    notifications(campaignId: $campaignId, notificationType: $notificationType) {
      id
      content
      notifiableType
      eventType
      createdAt
      user {
        name
        avatar
      }
      campaign {
        name
        project {
          name
          logo
        }
      }
      notifiable {
        id
        notifiableData
      }
      notificationUsers {
        user {
          id
          name
          avatar
          firstName
          lastName
        }
      }
    }
  }
`;
