import { useRef } from 'react';
import { SkemaColor } from './config';

export default function useSetBackground(
  setBackground: (color: SkemaColor) => void,
  color: SkemaColor,
): void {
  const refBackgroundSet = useRef<boolean>(false);

  if (refBackgroundSet.current === false) {
    refBackgroundSet.current = true;
    setBackground(color);
  }
}
