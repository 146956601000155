import {
  gql,
} from '@apollo/client';

export const VoteEvent = gql`
  mutation voteEvent($id: Int!, $campaignId: Int!, $voteCampus: String) {
    voteEvent(id: $id, campaignId: $campaignId, voteCampus: $voteCampus) {
      id
    }
  }
`;
