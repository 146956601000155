import PropTypes from 'prop-types';
import React, { useState, useRef } from 'react';
import { useMutation } from '@apollo/client';
import { TogglePostLike, ToggleProjectFavoriteFromPost } from 'app/graphql/mutations';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from 'app/components/common/Button';
import Share from 'app/components/common/Share';
import { ROUTES } from 'app/config/routes';
import { ROOT_DOMAIN } from 'app/config';
import { useOutsideClick } from 'app/use/useOutsideClick';
import styles from './index.module.scss';
// Assets
import IconFistInactive from './assets/fist-inactive.svg';
import IconFistActive from './assets/fist-active.svg';
import IconMessageInactive from './assets/message-inactive.svg';
import IconMessageActive from './assets/message-active.svg';
import IconStarActive from './assets/star-active.svg';
import IconStarInactive from './assets/star-inactive.svg';
import IconShare from './assets/share.svg';
import ImageShare from './assets/project-share.png';

CommentsMenu.propTypes = {
  className: PropTypes.string,
  postId: PropTypes.number.isRequired,
  project: PropTypes.object.isRequired,
  postUuid: PropTypes.string.isRequired,
  projectId: PropTypes.number.isRequired,
  isPostLiked: PropTypes.bool.isRequired,
  isPostFavorite: PropTypes.bool.isRequired,
  isCommentsVisible: PropTypes.bool.isRequired,
  postLikes: PropTypes.number.isRequired,
  postCommentCount: PropTypes.number.isRequired,
  onToggleComments: PropTypes.func.isRequired,
  onChangeIsPostFavorite: PropTypes.func.isRequired,
  isLight: PropTypes.bool.isRequired,
};

export default function CommentsMenu(props) {
  const {
    className,
    project,
    postId,
    postUuid,
    projectId,
    isPostLiked,
    isPostFavorite,
    isCommentsVisible,
    postLikes,
    postCommentCount,
    onToggleComments,
    onChangeIsPostFavorite,
    isLight,
  } = props;
  const refContainer = useRef();
  const [isShareImageVisible, setIsShareImageVisible] = useState(false);
  const [isShareVisible, setIsShareVisible] = useState(false);
  const [isLiked, setIsLiked] = useState(isPostLiked);
  const [likes, setLikes] = useState(postLikes);
  const [onLike, { loading: isProcessingLike }] = useMutation(TogglePostLike, {
    variables: {
      postId,
    },
    onCompleted(data) {
      setIsLiked(data.togglePostLike.isLiked);
      setLikes(data.togglePostLike.likes);
    },
  });
  const [toggleProjectFavorite, { loading: isProcessingFavorite }] = useMutation(
    ToggleProjectFavoriteFromPost,
    {
      variables: {
        projectId,
        postUuid,
      },
      onCompleted(data) {
        onChangeIsPostFavorite(data.toggleProjectFavoriteFromPost.isInterested);
      },
    }
  );
  const onMouseEnter = () => setIsShareImageVisible(true);
  const onMouseLeave = () => setIsShareImageVisible(false);
  const onShowShare = () => setIsShareVisible(true);
  const onCloseShare = (e) => {
    e.stopPropagation();
    setIsShareVisible(false);
  };

  useOutsideClick(refContainer, onCloseShare);

  return (
    <div className={`${styles.RootContainer} ${className}`}>
      <div className={styles.buttons}>
        <Button
          className={styles.like}
          isDisabled={isProcessingLike}
          isAuthRequired={true}
          onClick={onLike}
        >
          {isProcessingLike ? (
            <CircularProgress size={20} color="primary" />
          ) : (
            <img src={isLiked ? IconFistActive : IconFistInactive} alt="" />
          )}

          <div className={styles.like_text}>{likes}</div>
        </Button>

        <Button className={styles.comment} onClick={onToggleComments}>
          <img src={isCommentsVisible ? IconMessageActive : IconMessageInactive} alt="" />

          {!isLight && <div className={styles.comment_text}>Commenter</div>}
        </Button>

        <Button
          ref={refContainer}
          className={styles.share}
          isAuthRequired={false}
          onClick={onShowShare}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <img src={IconShare} alt="" />

          {!isLight && <div className={styles.share_text}>Partager</div>}

          {isShareVisible ? (
            <Share
              project={project}
              shareUrl={`${ROOT_DOMAIN}${ROUTES.POST.URL(postUuid)}`} />
          ) : (
            isShareImageVisible && <img className={styles.share_image} src={ImageShare} alt="" />
          )}
        </Button>

        <Button
          className={styles.interest}
          isDisabled={isProcessingFavorite}
          isAuthRequired={true}
          onClick={toggleProjectFavorite}
        >
          {isProcessingFavorite ? (
            <CircularProgress className={styles.interest_loader} size={27} color="primary" />
          ) : (
            <img src={isPostFavorite ? IconStarActive : IconStarInactive} alt="" />
          )}

          {!isLight && <div className={styles.interest_text}>S’intéresser</div>}
        </Button>
      </div>

      {postCommentCount > 0 && (
        <Button className={styles.commentCount} onClick={onToggleComments}>
          {postCommentCount} {`commentaire${postCommentCount > 1 ? 's' : ''}`}
        </Button>
      )}
    </div>
  );
}
