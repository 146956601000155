import { authentication } from 'app/module';
import { v4 as uuidv4 } from 'uuid';
import { EventCampaign } from './config';

type UrlParams = Record<string, string>;

export function getUrlParams(): UrlParams {
  const searchParams = new URLSearchParams(window.location.search);
  const params: UrlParams = {};

  searchParams.forEach((value: string, key: string): void => {
    params[key] = value;
  });

  return params;
}

export function register(): void {
  const uuid: string = uuidv4();

  authentication().signUp({
    name: '',
    firstName: '',
    lastName: '',
    email: `${uuid}@kunbis.com`,
    password: uuid,
  });
}

let __preloadStarted: boolean = false;
export function preLoadVideos(campaigns: EventCampaign[]): void {
  if (campaigns.length > 0) {
    if (__preloadStarted === false) {
      __preloadStarted = true;

      for (let i = 0; i < campaigns.length; i++) {
        const { media } = campaigns[i];
        const video: HTMLVideoElement = document.createElement('video');

        video.style.width = '0px';
        video.style.height = '0px';
        video.style.position = 'fixed';
        video.style.pointerEvents = 'nono';
        video.style.opacity = '0';
        video.muted = true;
        video.src = media;
        document.body.appendChild(video);
        video.play();
      }
    }
  }
}
