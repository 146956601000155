import { Category, CategoryData } from './category';
import { City } from './city';
import { Address, AddressData } from './address';
import { GoalData, Goal } from './goal';
import { User } from './user';

const getNewUser = (role: string, position: string, user: User | null = null, id: number) => {
  if (user)
    return {
      id: 0,
      userId: user.id,
      projectId: id,
      role,
      name: user.name,
      email: user.email,
      avatar: user.avatar,
      firstName: user.firstName,
      lastName: user.lastName,
      linkedinLink: user.linkedinLink,
      position,
    };

  return {
    id: 0,
    userId: 0,
    projectId: 0,
    role,
    name: null,
    email: null,
    avatar: null,
    firstName: null,
    lastName: null,
    linkedinLink: null,
    position,
  };
};

export class Project {
  id: number;
  projectUuid: string;
  name: string;
  description: string | null;
  subtitle: string | null;
  email: string | null;
  phone: string | null;
  country: string | null;
  logo: string | null;
  descriptionFounder: string | null;
  city: City | null;
  user: SlimUser;
  address: Array<Address>;
  projectType: number;
  isDraft: number;
  status: number;
  images: Array<any>;
  videos: Array<any>;
  website: string | null;
  businessType: number;
  businessName: string;
  recusFiscauxSignature: string | null;
  businessRegistrationNumber: string;
  accountNumber: string | null;
  accountBic: string | null;
  accountIban: string | null;
  interestCount: number;
  projectUsers: Array<ProjectUsers>;
  projectFounder: Array<ProjectUsers>;
  projectOwner: ProjectUsers;
  categoryProject: Array<CategoryProject>;
  goalProject: Array<GoalProject>;
  isInterested: boolean;
  isRecusFiscaux: boolean;
  activeCampaignCount: number | null;
  createdAt: number | null;
  stripeAccountStatus: number;
  stripeDocumentFront: string | null;
  stripeDocumentFrontId: string | null;
  stripeDocumentBack: string | null;
  stripeDocumentBackId: string | null;
  stripeDocumentType: number;
  visibilityDemandCount: number;

  constructor(data: any) {
    const mapAddress = (item: any) => new Address(item);

    this.id = data.id;
    this.projectUuid = data.projectUuid;
    this.name = data.name;
    this.city = data.city;
    this.email = data.email;
    this.user = data.user;
    this.phone = data.phone;
    this.address = data.address ? data.address.map(mapAddress) : null;
    this.country = data.country;
    this.website = data.website;
    this.videos = data.videos;
    this.images = data.images;
    this.projectType = data.projectType;
    this.businessType = data.businessType;
    this.businessName = data.businessName;
    this.businessRegistrationNumber = data.businessRegistrationNumber;
    this.accountNumber = data.accountNumber;
    this.accountBic = data.accountBic;
    this.accountIban = data.accountIban;
    this.interestCount = data.interestCount;
    this.descriptionFounder = data.descriptionFounder;
    this.logo = data.logo;
    this.isDraft = data.isDraft;
    this.isRecusFiscaux = !!data.isRecusFiscaux;
    this.recusFiscauxSignature = data.recusFiscauxSignature || null;
    this.status = data.status || 0;
    this.subtitle = data.subtitle;
    this.description = data.description;
    this.goalProject = data.goalProject;
    this.categoryProject = data.categoryProject;
    this.projectUsers = data.projectUsers;
    this.isInterested = data.isInterested || false;
    this.activeCampaignCount = data.activeCampaignCount || null;
    this.stripeAccountStatus = data.stripeAccountStatus;
    this.stripeDocumentFront = data.stripeDocumentFront;
    this.stripeDocumentFrontId = data.stripeDocumentFrontId;
    this.stripeDocumentBack = data.stripeDocumentBack;
    this.stripeDocumentBackId = data.stripeDocumentBackId;
    this.stripeDocumentType = data.stripeDocumentType;
    this.projectFounder =
      data.projectUsers && data.projectUsers.length
        ? data.projectUsers.filter((f: ProjectUsers) => f.role === 'founder')
        : [getNewUser('founder', 'Porteur du projet', null, data.id)];
    this.projectOwner =
      data.projectUsers && data.projectUsers.length
        ? getOwnerData(data.projectUsers, data.user, data.id)
        : getNewUser('owner', 'Porteur du projet', data.user, data.id);
    this.createdAt = data.createdAt || null;
    this.visibilityDemandCount = data.visibilityDemandCount || 0;
  }
}

const getOwnerData = (users: Array<ProjectUsers>, user: User, id: number) => {
  const filteredUsers = users.filter((f: ProjectUsers) => f.role === 'owner');
  if (filteredUsers.length)
    return {
      id: filteredUsers[0].id,
      userId: user?.id,
      projectId: id,
      role: filteredUsers[0].role,
      name: filteredUsers[0].name,
      email: user?.email,
      avatar: filteredUsers[0].avatar,
      firstName: filteredUsers[0].firstName,
      lastName: filteredUsers[0].lastName,
      linkedinLink: filteredUsers[0].linkedinLink,
      position: filteredUsers[0].position,
    };

  return getNewUser('owner', 'Porteur du projet', user, id);
};

export class ProjectData {
  name: string;
  description: string | null;
  subtitle: string | null;
  email: string | null;
  phone: string | null;
  logo: string | null;
  images: Array<any> | null;
  videos: Array<any> | null;
  descriptionFounder: string | null;
  cityId: number | undefined;
  businessType: number;
  isDraft: number;
  projectType: number;
  businessName: string;
  businessRegistrationNumber: string;
  accountNumber: string | null;
  accountBic: string | null;
  accountIban: string | null;
  userId: number;
  website: string | null;
  projectUsers: Array<ProjectUsers>;
  goalProject: Array<GoalProjectData>;
  categoryProject: Array<CategoryProjectData>;
  stripeDocumentFront: string | null;
  stripeDocumentBack: string | null;
  stripeDocumentType: number;

  constructor(data: Project) {
    this.userId = data.user.id;
    this.name = data.name;
    this.email = data.email;
    this.phone = data.phone;
    this.videos = data.videos;
    this.images = data.images;
    this.cityId = data.city?.id;
    this.website = data.website;
    this.projectType = data.projectType;
    this.businessType = data.businessType;
    this.businessName = data.businessName;
    this.businessRegistrationNumber = data.businessRegistrationNumber;
    this.accountNumber = data.accountNumber;
    this.accountBic = data.accountBic;
    this.accountIban = data.accountIban;
    this.descriptionFounder = data.descriptionFounder;
    this.logo = data.logo;
    this.isDraft = data.isDraft;
    this.subtitle = data.subtitle;
    this.description = data.description;
    this.stripeDocumentFront = data.stripeDocumentFront;
    this.stripeDocumentBack = data.stripeDocumentBack;
    this.stripeDocumentType = data.stripeDocumentType;
    this.goalProject = data.goalProject ? data.goalProject.map((f) => new GoalProjectData(f)) : [];
    this.categoryProject = data.categoryProject
      ? data.categoryProject.map((f) => new CategoryProjectData(f))
      : [];
    this.projectUsers = (data.projectOwner ? [data.projectOwner] : [])
      .concat(data.projectFounder || [])
      .filter((f) => f.userId || f.email);
  }
}

export class ProjectUpdateData {
  name: string;
  email: string | null;
  phone: string | null;
  description: string | null;
  subtitle: string | null;
  logo: string | null;
  descriptionFounder: string | null;
  cityId: number | undefined;
  businessType: number;
  isDraft: number;
  projectType: number;
  images: Array<any> | null;
  videos: Array<any> | null;
  isRecusFiscaux: boolean;
  recusFiscauxSignature: string | null;
  businessName: string;
  businessRegistrationNumber: string;
  accountNumber: string | null;
  accountBic: string | null;
  accountIban: string | null;
  website: string | null;
  projectUsers: Array<ProjectUsers>;
  goalProject: Array<GoalProjectData> | null;
  categoryProject: Array<CategoryProjectData> | null;
  address: Array<AddressData> | null;
  stripeDocumentFront: string | null;
  stripeDocumentBack: string | null;
  stripeDocumentType: number;

  constructor(data: Project) {
    const mapProjectUser = (item: any) => new ProjectUsers(item);
    const mapAddress = (item: any) => new AddressData(item);

    this.name = data.name;
    this.email = data.email;
    this.phone = data.phone;
    this.cityId = data.city?.id;
    this.website = data.website;
    this.videos = data.videos;
    this.images = data.images;
    this.projectType = data.projectType;
    this.businessType = data.businessType;
    this.businessName = data.businessName;
    this.businessRegistrationNumber = data.businessRegistrationNumber;
    this.accountNumber = data.accountNumber;
    this.accountBic = data.accountBic;
    this.accountIban = data.accountIban;
    this.stripeDocumentFront = data.stripeDocumentFront;
    this.stripeDocumentBack = data.stripeDocumentBack;
    this.stripeDocumentType = data.stripeDocumentType;
    this.descriptionFounder = data.descriptionFounder;
    this.logo = data.logo;
    this.isRecusFiscaux = !!data.isRecusFiscaux;
    this.recusFiscauxSignature = data.recusFiscauxSignature || null;
    this.isDraft = data.isDraft;
    this.subtitle = data.subtitle;
    this.address = data.address ? data.address.map(mapAddress) : null;
    this.description = data.description;
    this.goalProject = data.goalProject
      ? data.goalProject.map((f) => new GoalProjectData(f))
      : null;
    this.categoryProject = data.categoryProject
      ? data.categoryProject.map((f) => new CategoryProjectData(f))
      : null;

    this.projectUsers = (data.projectOwner ? [data.projectOwner].map(mapProjectUser) : [])
      .concat(data.projectFounder ? data.projectFounder.map(mapProjectUser) : [])
      .filter((f) => f.userId || f.email);
  }
}

export interface SlimUser {
  id: number;
  email: string;
  userUuid: string;
  name: string;
  role: string;
}

export class ProjectUsers {
  id: number;
  projectId: number;
  userId: number;
  role: string;
  email: string | null;
  name: string | null;
  avatar: string | null;
  firstName: string | null;
  lastName: string | null;
  linkedinLink: string | null;
  position: string | null;

  constructor(data: ProjectUsers) {
    this.id = data.id;
    this.projectId = data.projectId;
    this.userId = data.userId;
    this.role = data.role;
    this.name = data.name;
    this.email = data.email;
    this.avatar = data.avatar;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.linkedinLink = data.linkedinLink;
    this.position = data.position;
  }
}

export class ProjectUsersData {
  projectId: number;
  userId: number;
  role: string;
  name: string | null;
  email: string | null;
  avatar: string | null;
  firstName: string | null;
  lastName: string | null;
  linkedinLink: string | null;
  position: string | null;

  constructor(data: ProjectUsers) {
    this.projectId = data.projectId;
    this.userId = data.userId;
    this.role = data.role;
    this.name = data.name;
    this.email = data.email;
    this.avatar = data.avatar;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.linkedinLink = data.linkedinLink;
    this.position = data.position;
  }
}

export interface CategoryProject {
  id: number;
  projectId: number;
  categoryId: number;
  category: Category;
}

export class CategoryProjectData {
  projectId: number;
  categoryId: number;
  category: Category;

  constructor(data: CategoryProject) {
    this.projectId = data.projectId;
    this.categoryId = data.categoryId;
    this.category = new CategoryData(data.category);
  }
}

export interface GoalProject {
  id: number;
  projectId: number;
  goalId: number;
  goal: Goal;
}

export class GoalProjectData {
  projectId: number;
  goalId: number;
  goal: Goal;

  constructor(data: GoalProject) {
    this.projectId = data.projectId;
    this.goalId = data.goalId;
    this.goal = new GoalData(data.goal);
  }
}

export class CreateProjectData {
  projectId: number;
  categoryId: number;
  category: Category;

  constructor(data: CategoryProject) {
    this.projectId = data.projectId;
    this.categoryId = data.categoryId;
    this.category = new CategoryData(data.category);
  }
}

export type GqlProjectCreateResponse = {
  createProject: {
    id: number;
    projectUuid: String;
  };
};

export type GqlProjectUpdateResponse = {
  updateProject: {
    id: number;
    projectUuid: String;
  };
};

export type GqlUserProjectResponse = {
  projects: Array<Project>;
};

export type GqlSingleProjectResponse = {
  project: Project;
};

export type GqlAdminProjectsResponse = {
  adminProjects: Array<Project>;
};
