import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Header from './Header';
import Content from './Content';
import Details from './Details';
import Comments from './Comments';
import {
  KUNBIS_PROJECT_UUID,
} from 'app/config';
import {
  ROUTES
} from 'app/config/routes';
import {
  useHistory
} from 'react-router';
import styles from './index.module.scss';

PostCard.propTypes = {
  className: PropTypes.string,
  postData: PropTypes.object.isRequired,
  extraMenuItems: PropTypes.array,
  isLight: PropTypes.bool,
};

export default function PostCard(props) {
  const {
    className,
    postData,
    extraMenuItems = [],
    isLight = false,
  } = props;
  const {
    id: postId,
    postUuid,
    campaign,
    isInterested: postIsFavorite,
    isLiked: postIsLiked,
    likes: postLikes,
    commentsCount: postCommentCount,
  } = postData;
  const {
    id: campaignId,
    name: campaignName,
    project,
  } = campaign;
  const {
    id: projectId,
    name: projectName,
    projectUuid,
  } = project;

  const isKunbis = projectUuid === KUNBIS_PROJECT_UUID;
  const [isPostFavorite, setIsPostFavorite] = useState(postIsFavorite);
  const history = useHistory();

  const onRouteToProject = () => {
    history.push(
      ROUTES.PROJECT_BY_NAME.URL(projectId, {
        projectName,
        campaignName,
        campaignId,
      })
    );
  };

  return (
    <div className={`${styles.Root} ${className}`}>
      <Header
        post={postData}
        onChangeIsPostFavorite={setIsPostFavorite}
        onRouteToProject={onRouteToProject}
        isPostFavorite={isPostFavorite}
        extraMenuItems={extraMenuItems}
        isLight={isLight} />

      <Content data={postData} />

      {(!isLight && !isKunbis) && (
        <>
          <Details
            campaign={campaign}
            onRouteToProject={onRouteToProject} />

          <div className={styles.line} />
        </>
      )}

      <Comments
        project={project}
        projectId={projectId}
        postUuid={postUuid}
        postId={postId}
        onChangeIsPostFavorite={setIsPostFavorite}
        isPostFavorite={isPostFavorite}
        isPostLiked={postIsLiked}
        postLikes={postLikes}
        postCommentCount={postCommentCount}
        isLight={isLight}
      />
    </div>
  );
}
