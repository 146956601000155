import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { getRandomNumber } from 'app/utils';
import styles from './index.module.scss';

Avatar.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string,
  alt: PropTypes.string,
  size: PropTypes.number,
  isProject: PropTypes.bool,
  onClick: PropTypes.func,
};

export default function Avatar(props) {
  const { className, src, alt, size, onClick, isProject } = props;
  const randomNumber = useMemo(() => getRandomNumber(0, 32), []);
  const IconAvatar = useMemo(() => {
    if (!src) {
      if (isProject) {
        return require(`./assets/project.png`);
      }

      return require(`./assets/avatar-${randomNumber}.svg`);
    }

    return null;
  }, [src, isProject, randomNumber]);

  return (
    <div
      className={`${styles.RootContainer} ${className}`}
      style={{
        backgroundImage: `url(${src})`,
        minWidth: size,
        width: size,
        height: size,
        cursor: onClick && 'pointer',
      }}
      onClick={onClick}
    >
      {!src && <img src={IconAvatar} alt={alt || ''} />}
    </div>
  );
}
