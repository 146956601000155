import React from 'react';
import Button from 'app/components/common/Button';
import styles from './index.module.scss';
// Assets
import ImageBackgroundA from './assets/background-a.svg';
import ImageBackgroundB from './assets/background-b.svg';

export default function AuthBanner(props) {
  const { className, isLogin, setIsLogin } = props;
  let background;
  let title;
  let text;
  let buttonLabel;
  let onClick;

  if (isLogin) {
    background = ImageBackgroundA;
    title = 'Vous voulez nous rejoindre ?';
    text = 'Enregistrez-vous et commencez l’aventure !';
    buttonLabel = 'S’enregistrer';
    onClick = () => setIsLogin(false);
  } else {
    background = ImageBackgroundB;
    title = 'On se connaît, non ?';
    text = 'Si vous avez déjà un compte, connectez-vous, on est content de vous revoir !';
    buttonLabel = 'Connexion';
    onClick = () => setIsLogin(true);
  }

  return (
    <div
      className={`${styles.RootContainer} ${className}`}
      style={{ backgroundImage: `url(${background})` }}
    >
      <div className={styles.body}>
        <h2 className={styles.title}>{title}</h2>

        <div className={styles.text}>{text}</div>

        <Button className={styles.button} label={buttonLabel} onClick={onClick} />
      </div>
    </div>
  );
}
