export const NAVIGATION_TYPES = {
  ACCOUNT: 'account',
  PROFILE: 'profile',
  NOTIFICATIONS: 'notifications',
  PAYMENT: 'payment',
};

export const NAVIGATION_ITEMS = [
  {
    id: NAVIGATION_TYPES.ACCOUNT,
    label: 'Compte',
  },
  {
    id: NAVIGATION_TYPES.PROFILE,
    label: 'Éditer le profil',
  },
  {
    id: NAVIGATION_TYPES.NOTIFICATIONS,
    label: 'Notifications',
  },
  {
    id: NAVIGATION_TYPES.PAYMENT,
    label: 'Paiements',
  },
];
