import {
  AddressTypes,
} from 'app/graphql/types/address';
import {
  CityTypes,
} from 'app/graphql/types/city';

class LocationFactory {

  public static createCity(city: any): CityTypes.City {
    return {
      id: city?.id || 0,
      city: city?.city || '',
      lat: city?.lat || 0,
      lng: city?.lng || 0,
      country: city?.country || '',
      region: city?.region || '',
      createdAt: city?.createdAt || 0,
      updatedAt: city?.updatedAt || 0,
      deletedAt: city?.deletedAt || undefined,
      status: city?.status || 0,
      userId: city?.userId || undefined,
    };
  }

  public static createAddress(address: any[]): AddressTypes.Address[] {
    if (Array.isArray(address)) {
      return address.map(LocationFactory.createAddressItem);
    }

    return [];
  }

  public static createAddressItem(address: any): AddressTypes.Address {
    const city: CityTypes.City = LocationFactory.createCity(address?.city);

    return {
      id: address?.id || 0,
      addressUuid: address?.addressUuid || '',
      address1: address?.address1 || '',
      address2: address?.address2 || undefined,
      cityStr: address?.cityStr || undefined,
      country: address?.country || undefined,
      city: city.id ? city : undefined,
      state: address?.state || '',
      zipCode: address?.zipCode || '',
      phone: address?.phone || undefined,
      lat: address?.lat || city.lat || undefined,
      lng: address?.lng || city.lng || undefined,
      name: address?.name || undefined,
      firstName: address?.firstName || undefined,
      lastName: address?.lastName || undefined,
      addressType: address?.addressType || 0,
      userId: address?.userId || 0,
      projectId: address?.projectId || undefined,
    };
  }

  public static createAddressUpdate(address: any[]): AddressTypes.AddressUpdate[] {
    if (Array.isArray(address)) {
      return address.map(LocationFactory.createAddressUpdateItem);
    }

    return [];
  }

  public static createAddressUpdateItem(address: any): AddressTypes.AddressUpdate {
    const city: CityTypes.City = LocationFactory.createCity(address?.city);

    return {
      id: address?.id || 0,
      address1: address?.address1 || '',
      address2: address?.address2 || undefined,
      cityStr: address?.cityStr || undefined,
      country: address?.country || undefined,
      cityId: city.id || undefined,
      state: address?.state || '',
      zipCode: address?.zipCode || '',
      phone: address?.phone || undefined,
      lat: address?.lat || city.lat || undefined,
      lng: address?.lng || city.lng || undefined,
      name: address?.name || undefined,
      firstName: address?.firstName || undefined,
      lastName: address?.lastName || undefined,
      addressType: address?.addressType || 0,
      userId: address?.userId || 0,
      projectId: address?.projectId || undefined,
    };
  }

}

export default LocationFactory;