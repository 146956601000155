import { Tip } from './tip';
import { Requirement } from './requirement';
import { Post } from './post';

export class Strategy {
  id: number;
  strategyUuid: string;
  name: string | null;
  startAt: Date | null;
  isScenario: boolean;
  strategyDays: Array<StrategyDay>;

  constructor(data: any) {
    this.id = data.id;
    this.name = data.name;
    this.strategyUuid = data.strategyUuid;
    this.startAt = data.startAt
      ? data.startAt instanceof Date && data.startAt.getTime()
        ? data.startAt
        : new Date(data.startAt * 1000)
      : null;
    this.isScenario = data.isScenario;
    this.strategyDays = data.strategyDays
      .map((s: any) => new StrategyDay(s))
      .sort((a: StrategyDay, b: StrategyDay) => {
        if (a.whichDay === b.whichDay) return a.whichTime - b.whichTime;
        else return a.whichDay - b.whichDay;
      });
  }
}

export class NewStrategyData {
  name: string | null;
  startAt: number | null;
  isScenario: boolean;
  strategyDays: Array<StrategyDayData>;

  constructor(data: Strategy) {
    this.name = data.name;
    this.startAt = data.startAt ? data.startAt.getTime() / 1000 : null;
    this.isScenario = data.isScenario;
    this.strategyDays = data.strategyDays.map((s) => new StrategyDayData({ ...s, id: 0 }));
  }
}

export class StrategyData {
  id: number;
  name: string | null;
  startAt: number | null;
  isScenario: boolean;
  strategyDays: Array<StrategyDayData>;

  constructor(data: Strategy) {
    this.id = data.id;
    this.name = data.name;
    this.startAt = data.startAt ? data.startAt.getTime() / 1000 : null;
    this.isScenario = data.isScenario;
    this.strategyDays = data.strategyDays.map((s) => new StrategyDayData(s));
  }
}

export class StrategyDay {
  id: number;
  whichDay: number;
  whichTime: number;
  itemType: number;
  itemTypeId: number;
  item: Tip | Requirement | null;
  itemPost: Post | null;

  constructor(data: any) {
    this.id = data.id;
    this.whichDay = data.whichDay;
    this.whichTime = data.whichTime;
    this.itemType = data.itemType;
    this.item = typeof data.item === 'string' ? JSON.parse(data.item) : data.item;
    this.itemTypeId = data.itemTypeId;
    this.itemPost = data.itemPost ? new Post(data.itemPost) : null;
  }
}

export class NewStrategyDayData {
  whichDay: number;
  whichTime: number;
  itemType: number;
  itemTypeId: number;
  item: string;

  constructor(data: StrategyDay) {
    this.whichDay = data.whichDay;
    this.whichTime = data.whichTime;
    this.itemType = data.itemType;
    this.itemTypeId = data.itemTypeId;
    this.item = JSON.stringify(data.item);
  }
}

export class StrategyDayData {
  id: number;
  whichDay: number;
  whichTime: number;
  itemType: number;
  itemTypeId: number;
  item: string;

  constructor(data: StrategyDay) {
    this.id = data.id;
    this.whichDay = data.whichDay;
    this.whichTime = data.whichTime;
    this.itemType = data.itemType;
    this.itemTypeId = data.itemTypeId;
    this.item = JSON.stringify(data.item);
  }
}

export type GqlStrategyResponse = {
  strategies: Array<Strategy>;
};

export type GqlStrategyCreateResponse = {
  createStrategy: Strategy;
};

export type GqlStrategyUpdateResponse = {
  updateStrategy: Strategy;
};
