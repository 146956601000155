import {
  toastError,
} from 'app/utils/toast';

class AuthenticationUtil {

  public static handleGenericError(error: any): void {
    if (typeof error?.data?.message === 'string') {
      AuthenticationUtil.notify(error.data.message);
    }
  }

  public static notify(message: string): void {
    if (message) {
      toastError('Email ou mot de passe invalide');
    }
  }

}

export default AuthenticationUtil;
