import PropTypes from 'prop-types';
import {
  useState,
  useRef
} from 'react';
import Button from 'app/components/common/Button';
import Avatar from 'app/components/common/Avatar';
import Credits from 'app/components/common/CreditsModal';
import {
  useAuthModal
} from 'app/components/common/Auth/useAuthModal';
import {
  ROUTES
} from 'app/config/routes';
import {
  authentication,
  useUser,
} from 'app/module';
import styles from './index.module.scss';
// Assets
import IconBack from './assets/back.svg';
import IconMegaphone from './assets/megaphone.svg';
import IconMegaphoneActive from './assets/megaphone-colored.svg';
import IconManifesto from './assets/manifesto.svg';
import IconManifestoActive from './assets/manifesto-colored.svg';
import IconNotificationA from './assets/notification.svg';
import IconNotificationAActive from './assets/notification-colored.svg';
import IconMoney from './assets/money.svg';
import IconMoneyActive from './assets/money-colored.svg';
import IconCamera from './assets/camera.svg';
import IconCameraActive from './assets/camera-colored.svg';
import IconGift from './assets/gift.svg';
import IconGiftActive from './assets/gift-colored.svg';
import IconHeart from './assets/heart.svg';
import IconHeartActive from './assets/heart-colored.svg';
import IconSupport from './assets/support.svg';
import IconSupportActive from './assets/support-colored.svg';
import IconSettings from './assets/settings.svg';
import IconSettingsActive from './assets/settings-colored.svg';
import IconContact from './assets/contact.svg';
import IconContactActive from './assets/contact-colored.svg';
import IconAccount from './assets/account.svg';
import IconAccountActive from './assets/account-colored.svg';
import IconNotificationB from './assets/notification-2.svg';
import IconNotificationBActive from './assets/notification-2-colored.svg';
import IconEdit from './assets/edit.svg';
import IconEditActive from './assets/edit-colored.svg';
import IconWallet from './assets/wallet.svg';
import IconWalletActive from './assets/wallet-colored.svg';
import IconUmbrella from './assets/umbrella.svg';
import IconUmbrellaActive from './assets/umbrella-colored.svg';
import IconFlag from './assets/flag.svg';
import IconFlagActive from './assets/flag-colored.svg';
// import IconQuestion from './assets/question.svg';
// import IconQuestionActive from './assets/question-colored.svg';
import IconInfo from './assets/info.svg';
import IconInfoActive from './assets/info-colored.svg';
import IconProjects from './assets/projects.svg';
import IconProjectsActive from './assets/projects-colored.svg';

const __accountItems = [
  {
    icon: IconMegaphone,
    iconActive: IconMegaphoneActive,
    title: 'Fil de transparence',
    route: ROUTES.USER.URL(),
  },
  {
    icon: IconNotificationA,
    iconActive: IconNotificationAActive,
    title: 'Notifications',
    route: ROUTES.USER.URL(ROUTES.USER.URL_PROPS.NAVIGATION_IDS.NOTIFICATIONS),
  },
  {
    icon: IconMoney,
    iconActive: IconMoneyActive,
    title: 'Mes contributions',
    route: ROUTES.USER.URL(ROUTES.USER.URL_PROPS.NAVIGATION_IDS.CONTRIBUTIONS),
  },
  {
    icon: IconCamera,
    iconActive: IconCameraActive,
    title: 'Mon job de reporter',
    route: ROUTES.USER.URL(ROUTES.USER.URL_PROPS.NAVIGATION_IDS.HELPER),
  },
  {
    icon: IconGift,
    iconActive: IconGiftActive,
    title: 'Offrir un cadeau',
    route: ROUTES.GIFT.URL,
  },
  {
    icon: IconHeart,
    iconActive: IconHeartActive,
    title: 'Souscription personnelle',
    route: ROUTES.DONATION.URL(),
  },
  {
    icon: IconSupport,
    iconActive: IconSupportActive,
    title: 'Devenir helper',
    route: ROUTES.REPORTER.URL(),
  },
  {
    icon: IconInfo,
    iconActive: IconInfoActive,
    title: 'À propos',
    route: ROUTES.LANDING_MAIN.URL,
  },
  {
    icon: IconProjects,
    iconActive: IconProjectsActive,
    title: 'Tous les projets',
    route: ROUTES.PROJECTS.URL,
  },
];

const __settingsItems = [
  {
    icon: IconAccount,
    iconActive: IconAccountActive,
    title: 'Compte',
    route: ROUTES.SETTINGS.URL(ROUTES.SETTINGS.URL_PROPS.NAVIGATION_IDS.ACCOUNT),
  },
  {
    icon: IconNotificationB,
    iconActive: IconNotificationBActive,
    title: 'Edit profile',
    route: ROUTES.SETTINGS.URL(ROUTES.SETTINGS.URL_PROPS.NAVIGATION_IDS.PROFILE),
  },
  {
    icon: IconEdit,
    iconActive: IconEditActive,
    title: 'Notifications',
    route: ROUTES.SETTINGS.URL(ROUTES.SETTINGS.URL_PROPS.NAVIGATION_IDS.NOTIFICATIONS),
  },
  {
    icon: IconWallet,
    iconActive: IconWalletActive,
    title: 'Payment Method',
    route: ROUTES.SETTINGS.URL(ROUTES.SETTINGS.URL_PROPS.NAVIGATION_IDS.PAYMENT),
  },
  {
    icon: IconUmbrella,
    iconActive: IconUmbrellaActive,
    title: 'Protection de la vie privée',
    route: ROUTES.PRIVACY.URL,
  },
  {
    icon: IconFlag,
    iconActive: IconFlagActive,
    title: 'Conditions générales',
    route: ROUTES.TERMS.URL,
  },
  // {
  //   icon: IconQuestion,
  //   iconActive: IconQuestionActive,
  //   title: 'FAQ',
  //   route: ROUTES.FAQ.URL,
  // },
];
const __unauthedItems = [
  {
    icon: IconProjects,
    iconActive: IconProjectsActive,
    title: 'Tous les projets',
    route: ROUTES.PROJECTS.URL,
  },
  {
    icon: IconManifesto,
    iconActive: IconManifestoActive,
    title: 'Manifesto',
    route: ROUTES.LANDING_MANIFESTO.URL,
  },
  {
    icon: IconGift,
    iconActive: IconGiftActive,
    title: 'Offrez un cadeau',
    route: ROUTES.GIFT.URL,
  },
  {
    icon: IconHeart,
    iconActive: IconHeartActive,
    title: 'Souscription personnelle',
    route: ROUTES.DONATION.URL(),
  },
  {
    icon: IconInfo,
    iconActive: IconInfoActive,
    title: 'À propos',
    route: ROUTES.LANDING_MAIN.URL,
  },
  {
    icon: IconContact,
    iconActive: IconContactActive,
    title: 'Contact',
    route: ROUTES.CONTACT.URL,
  },
  {
    icon: IconUmbrella,
    iconActive: IconUmbrellaActive,
    title: 'Protection de la vie privée',
    route: ROUTES.PRIVACY.URL,
  },
  {
    icon: IconFlag,
    iconActive: IconFlagActive,
    title: 'Conditions générales',
    route: ROUTES.TERMS.URL,
  },
  // {
  //   icon: IconQuestion,
  //   iconActive: IconQuestionActive,
  //   title: 'FAQ',
  //   route: ROUTES.FAQ.URL,
  // },
];

Drawer.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default function Drawer(props) {
  const { history, isVisible, onClose, user } = props;
  const { id, avatar, name, wallet, gifts } = user || {};
  const isAuthed = !!id;
  const refTouchStart = useRef();
  const refTouchEnd = useRef();
  const { showAuthModal } = useAuthModal();
  const [isSettings, setIsSettings] = useState(false);

  const {
    projects,
  } = useUser();

  const __footerItems = [
    {
      icon: IconSettings,
      iconActive: IconSettingsActive,
      title: 'Settings',
      onClick: () => setIsSettings(true),
    },
    {
      icon: IconContact,
      iconActive: IconContactActive,
      title: 'Contact',
      route: ROUTES.CONTACT.URL,
    },
  ];

  const onRouteToDistribute = () => {
    onClose();
    history.push(ROUTES.DONATION.URL());
  };

  const onClickLogin = () => {
    onClose();
    showAuthModal();
  };

  const onClickLogout = () => {
    authentication().signOut();
  };

  const onTouchStart = (e) => {
    refTouchStart.current = e.targetTouches[0].clientX;
    refTouchEnd.current = 0;
  };
  const onTouchMove = (e) => {
    refTouchEnd.current = e.targetTouches[0].clientX;
  };
  const onTouchEnd = (e) => {
    if (refTouchStart.current - refTouchEnd.current >= 100) {
      onClose();
    }
  };

  const renderTitle = (title, props) => {
    const { isBackButton = false } = props || {};

    return (
      <div className={styles.title}>
        {isBackButton && (
          <Button className={styles.title_back} onClick={() => setIsSettings(false)}>
            <img src={IconBack} alt="" />
          </Button>
        )}

        {title}

        <div
          className={styles.title_line}
          style={{
            marginLeft: !title && 0,
          }}
        />
      </div>
    );
  };

  const renderItem = (item, index) => {
    const { icon, iconActive, title, route, onClick: _onClick } = item;
    const onClick = () => {
      if (route) {
        onClose();
        history.push(route);
      } else if (_onClick) {
        _onClick();
      }
    };

    return (
      <div key={index} className={styles.item}>
        <div className={styles.item_back}>
          <img src={icon} alt="" />

          {title}
        </div>

        <Button className={styles.item_button} onClick={onClick}>
          <img src={iconActive} alt="" />

          {title}
        </Button>
      </div>
    );
  };
  const renderItems = (items) => <div className={styles.items}>{items.map(renderItem)}</div>;
  const renderProject = (project) => {
    const { id, projectUuid, logo, name, status, activeCampaignCount } = project;
    const onClick = () => {
      if (status === 1 && activeCampaignCount > 0) {
        history.push(ROUTES.CAMPAIGN_MANAGE.URL(projectUuid));
      } else {
        history.push(ROUTES.PROJECT_MANAGE.URL(projectUuid));
      }

      onClose();
    };

    return (
      <Button key={id} onClick={onClick} className={styles.project}>
        <Avatar className={styles.project_logo} isProject={true} src={logo} alt={name} size={25} />

        {name}
      </Button>
    );
  };

  const renderGift = (gift, index) => {
    return (
      <div
        key={index}
        className={styles.gift}>
        <Credits
          className={styles.giftCredits}
          onClick={() => {}}
          amount={gift.amount} />
        
        <div>
          de
          <strong>
            {gift.user.name}
          </strong>
        </div>
      </div>
    );
  };

  return (
    <>
      <div
        className={`${styles.background} ${isVisible && styles.background__isVisible}`}
        onClick={onClose}
      />

      <div
        className={`${styles.RootContainer} ${isVisible && styles.RootContainer__isVisible}`}
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
      >
        {isAuthed ? (
          <>
            <div className={styles.header}>
              <Avatar src={avatar} alt={name} size={58} />

              <div className={styles.user} onClick={onRouteToDistribute}>
                <div className={styles.user_name}>{name}</div>

                <Credits isWhite={true} amount={wallet} />
              </div>
            </div>

            {isSettings ? (
              <>
                {renderTitle('PARAMÈTRES', {
                  isBackButton: true,
                })}
                {renderItems(__settingsItems)}
              </>
            ) : (
              <>
                {gifts.length > 0 && (
                  <>
                    {renderTitle('MES CADEAUX !')}
                    {gifts.map(renderGift)}
                  </>
                )}

                {renderTitle('VOTRE COMPTE')}
                {renderItems(__accountItems)}

                {projects.length > 0 && (
                  <>
                    {renderTitle('MES PROJETS')}
                    {projects.map(renderProject)}
                  </>
                )}

                {renderTitle()}
                {renderItems(__footerItems)}

                {renderTitle()}
                <Button
                  className={styles.login}
                  label="Déconnexion"
                  onClick={onClickLogout} />
              </>
            )}
          </>
        ) : (
          <>
            <Button
              className={styles.login}
              label="Connexion"
              onClick={onClickLogin} />

            {renderItems(__unauthedItems)}
          </>
        )}
      </div>
    </>
  );
}
