import React, { useState } from 'react';
import InputError, { useInputError } from 'app/components/common/InputError';
import styles from './index.module.scss';

function InputText(props: any) {
  const {
    className,
    onChangeText,
    onChangeNumber,
    onChange: _onChange,
    prefix,
    placeholder,
    value,
    info,
    error,
    isError,
    onFocus: __onFocus,
    onBlur: __onBlur,
    ..._props
  } = props;
  const [isFocus, setIsFocus] = useState(false);
  const {
    isErrorVisible,
    onFocus: _onFocus,
    onBlur: _onBlur,
  } = useInputError({
    isError,
    value
  });
  const isPlaceholderUp = isFocus || !!value;
  const isPrefix = !!prefix;
  let classNameInput = styles.input;
  let classNamePlaceholder = styles.placeholder;

  if (isPlaceholderUp) {
    classNamePlaceholder = `${classNamePlaceholder} ${styles.placeholder__isUp}`;
  }

  if (isPrefix) {
    classNameInput = `${classNameInput} ${styles.input__isPrefix}`;
    classNamePlaceholder = `${classNamePlaceholder} ${styles.placeholder__isPrefix}`;
  }

  const onFocus = () => {
    setIsFocus(true);
    _onFocus();
    __onFocus && __onFocus();
  };
  const onBlur = () => {
    setIsFocus(false);
    _onBlur();
    __onBlur && __onBlur();
  };
  const onChange = (e: any) => {
    _onChange && _onChange(e);
    onChangeText && onChangeText(e.target.value);
    onChangeNumber && onChangeNumber(e.target.value.replace(/\D/g, ''));
  };

  return (
    <div className={`${styles.Root} ${isFocus && styles.Root__isFocus} ${className}`}>
      {!!placeholder && (
        <div className={classNamePlaceholder}>
          {placeholder}
        </div>
      )}

      {!!info && (
        <div className={styles.info}>{info}</div>
      )}

      {!!prefix && (
        <div className={styles.prefix}>
          {prefix}
        </div>
      )}

      <input
        type="text"
        {..._props}
        onFocus={onFocus}
        onBlur={onBlur}
        className={classNameInput}
        onChange={onChange}
        value={value} />

      {isErrorVisible && (
        <InputError
          className={styles.InputError}
          error={error} />
      )}
    </div>
  );
}

export default InputText;
