import { useEffect, useState } from 'react';

const StorageKey: string = '@SK-Alumni-isEnglish';

export default function useLanguage(): {
  toggleEnglish: (next: boolean) => void;
  english: boolean;
} {
  const [english, toggleEnglish] = useState<boolean>((): boolean => {
    let parsed: string | null = window.localStorage.getItem(StorageKey);

    if (parsed) {
      parsed = JSON.parse(parsed);

      if (typeof parsed === 'boolean') {
        return parsed;
      }
    }

    return false;
  });

  useEffect((): void => {
    window.localStorage.setItem(StorageKey, JSON.stringify(english));
  }, [english]);
 
  return {
    toggleEnglish,
    english,
  };
}
