import {
  useState,
  useEffect,
} from 'react';
import {
  getCategories,
} from 'app/graphql/queries';
import {
  CreateCategory,
} from 'app/graphql/mutations';
import {
  apolloClient,
} from 'app/api';
import {
  getUniqueId,
} from 'app/utils';

const __listeners = {};

let __categories = [];
let __filterWildcardCategoryIds = [];

function useCategories(props) {
  const {
    type: filterType,
    categories: filterCategories,
  } = props || {};

  const [categories, setCategories] = useState(__categories);

  let categoriesFiltered = categories;

  if (filterType) {
    categoriesFiltered = [];

    if (filterCategories?.length) {
      for (let i = 0, len = filterCategories.length; i < len; i++) {
        const item = filterCategories[i];
        const {
          id,
        } = item;

        if (__filterWildcardCategoryIds.indexOf(id) === -1) {
          __filterWildcardCategoryIds.push(id);
        }
      }
    }

    for (let i = 0, ix = 0, len = categories.length; i < len; i++) {
      const item = categories[i];
      const {
        id,
        categoryType,
      } = item;

      if (categoryType === filterType || __filterWildcardCategoryIds.indexOf(id) > -1) {
        categoriesFiltered[ix++] = item;
      }
    }
  }

  useEffect(() => {
    const key = getUniqueId();
    
    __listeners[key] = setCategories;

    return () => {
      delete __listeners[key];
    };
  }, []);

  return {
    categories: categoriesFiltered,
  };
}

async function createCategory(nextCategoryName) {
  try {
    const {
      data: {
        createCategory: data
      }
    } = await apolloClient.query({
      query: CreateCategory,
      variables: {
        data: {
          name: nextCategoryName,
          categoryType: 5,
        },
      },
    });

    dispatchListeners([
      data
    ]);

    return data;
  } catch (err) {
    //
  }
};

function dispatchListeners(data) {
  for (let i = 0, lenI = data.length; i < lenI; i++) {
    const item = data[i];

    let isExisting = false;

    for (let x = 0, lenX = __categories.length; x < lenX; x++) {
      if (__categories[x].id === item.id) {
        isExisting = true;
        break;
      }
    }

    if (isExisting === false) {
      __categories.push(item);
    }
  }

  for (const key in __listeners) {
    __listeners[key]([
      ...__categories,
    ]);
  }
}

async function initializeCategories() {
  try {
    const {
      data: {
        categories: data
      }
    } = await apolloClient.query({
      query: getCategories,
    });
  
    dispatchListeners(data);
  } catch (err) {
    // 
  }
}

export {
  useCategories,
  createCategory,
  initializeCategories,
};
