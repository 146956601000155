import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { getContributionsForCampaign } from 'app/graphql/queries';
import CircularProgress from '@material-ui/core/CircularProgress';
import Contributors from 'app/components/common/Contributors';
import styles from './index.module.scss';
// Assets
import IconAvatar from './assets/avatar.svg';

const __colors = ['#4AE0CE', '#4A6BE0', '#E0DA4A'];
const __widthText = 58;
const __widthAvatar = 24;
const __widthOverlap = 14;
const __marginText = 6;

ContributorAvatars.propTypes = {
  className: PropTypes.string,
  campaignId: PropTypes.number.isRequired,
};

export default function ContributorAvatars(props) {
  const { className, campaignId } = props;
  const [contributors, setContributors] = useState([]);
  const [isContributorsVisible, setIsContributorsVisible] = useState(false);
  const countRenderedContributors = Math.min(contributors.length, 3);
  const widthRenderedContributors = countRenderedContributors * __widthOverlap;
  const styleRoot = {
    width: `${widthRenderedContributors + __widthText + __widthAvatar + __marginText}px`
  };
  const styleAmount = {
    transform: `translateX(-${widthRenderedContributors}px)`,
  };
  const styleText = {
    transform: `translateX(-${widthRenderedContributors}px)`,
    width: __widthText,
    marginLeft: __marginText,
  };
  const styleAvatar = {
    minWidth: __widthAvatar,
    height: __widthAvatar,
  };

  const { loading: isLoadingContributors } = useQuery(getContributionsForCampaign, {
    variables: {
      campaignId,
    },
    onCompleted: ({ contributions }) => {
      setContributors(contributions);
    },
  });
  const onShowContributors = () => {
    if (contributors.length > 0) {
      setIsContributorsVisible(true);
    }
  };
  const onHideContributors = () => setIsContributorsVisible(false);

  const renderContributor = (item, index) => {
    const { id, user } = item;
    const { avatar } = user;
    const style = {
      transform: `translateX(-${index * __widthOverlap}px)`,
      backgroundImage: `url(${avatar})`,
      backgroundColor: __colors[index],
    };

    return (
      <div
        key={id}
        className={styles.contributor}
        style={{
          ...styleAvatar,
          ...style
        }}>
        {!avatar && <img src={IconAvatar} alt="" />}
      </div>
    );
  };

  return (
    <>
      <div
        className={`${styles.Root} ${className}`}
        style={styleRoot}
        onClick={onShowContributors}>
        {isLoadingContributors
          ? <CircularProgress className={styles.loader} size={20} color="primary" />
          : contributors.slice(0, 3).map(renderContributor)
        }

        <div
          className={styles.amount}
          style={{
            ...styleAvatar,
            ...styleAmount
          }}>
          {`+${contributors.length}`}
        </div>

        <div
          className={styles.text}
          style={styleText}>
          Donateurs
        </div>
      </div>

      {isContributorsVisible && <Contributors onClose={onHideContributors} data={contributors} />}
    </>
  );
}
