import {
  useState,
  useEffect,
} from 'react';
import Background from 'app/components/Background';
import BackgroundImage from './background.svg';
import { Page } from './config';
import director from './director';
import { Unsubscribe } from 'app/global';
import Code from './Code';
import Login from './Login';
import WhatNext from './WhatNext';
import styles from './index.module.scss';
import Registration from './Registration';
import HowTo from './HowTo';

function RedeemGift(): JSX.Element {
  const [page, setPage] = useState<Page>(Page.EnterCode);
  
  useEffect((): Unsubscribe => {
    return director().observe((): void => {
      setPage(director().page);
    });
  }, []);

  const renderPage = (): JSX.Element | null => {
    switch(page) {
      case Page.EnterCode:
        return (
          <Code className={styles.container} />
        );

      case Page.Login:
        return (
          <Login className={styles.container} />
        );

      case Page.Registration:
        return (
          <Registration className={styles.container} />
        );

      case Page.WhatNext:
        return (
          <WhatNext className={styles.container} />
        );

      case Page.HowTo:
        return (
          <HowTo className={styles.container} />
        );

      default:
        return null;
    }
  }

  return (
    <>
      <Background
        image={BackgroundImage} />

      {renderPage()}
    </>
  );
}

export default RedeemGift;
