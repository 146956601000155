import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import Button from 'app/components/common/Button';
import { TwitterShareButton, FacebookShareButton, LinkedinShareButton } from 'react-share';
import styles from './index.module.scss';
// Assets
import IconTwitter from './assets/twitter.svg';
import IconFacebook from './assets/facebook.svg';
import IconLinkedin from './assets/linkedin.svg';

// function LinkedinShareButton(props) {
//   const {
//     url,
//     className,
//     children,
//     onClick: _onClick,
//   } = props;
//
//   const onClick = (e) => {
//     _onClick && _onClick(e);
//     window.open(
//       `https://www.linkedin.com/sharing/share-offsite/?url=${url}`,
//       '_blank',
//       'location=yes,height=600,width=730,scrollbars=yes,status=yes'
//     );
//   };
//
//   return (
//     <button
//       className={`react-share__ShareButton ${className}`}
//       style={{
//         backgroundColor: 'transparent',
//         border: 'medium none',
//         padding: 0,
//         font: 'inherit',
//         color: 'inherit',
//         cursor: 'pointer',
//       }}
//       aria-label='linkedin'
//       onClick={onClick}>
//       {children}
//     </button>
//   );
// }

Share.propTypes = {
  className: PropTypes.string,
  project: PropTypes.object.isRequired,
  shareUrl: PropTypes.string.isRequired,
};

export default function Share(props) {
  const {
    className,
    project,
    shareUrl,
  } = props;
  const { name, subtitle } = project;
  const [isCopied, setIsCopied] = useState(false);
  const items = [
    {
      Component: TwitterShareButton,
      icon: IconTwitter,
      alt: 'twitter',
      props: {
        title: `Le projet ${name} met en place une solution, venez découvrir ses actions`,
        via: 'Kunbis_fr',
        hashtags: ['Kunbis', name],
        // related: []
      },
    },
    {
      Component: FacebookShareButton,
      icon: IconFacebook,
      alt: 'facebook',
      props: {
        quote: `Le projet ${name} met en place une solution, venez découvrir ses actions`,
        hashtag: '#Kunbis',
      },
    },
    {
      Component: LinkedinShareButton,
      icon: IconLinkedin,
      alt: 'linkedin',
      props: {
        title: `Le projet ${name} met en place une solution, venez découvrir ses actions`,
        summary: subtitle,
        source: 'www.Kunbis.com',
      },
    },
  ];

  const onClickItem = (e) => {
    e.stopPropagation();
  };
  const onCopy = (e) => {
    e.stopPropagation();
    navigator.clipboard.writeText(shareUrl);
    setIsCopied(true);
  };

  useEffect(() => {
    if (isCopied) {
      const onTimeout = () => setIsCopied(false);
      const timeout = setTimeout(onTimeout, 3500);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [isCopied]);

  const renderItem = (item, index) => {
    const { Component, icon, alt, props: _props } = item;

    return (
      <Component
        key={index}
        className={styles.item}
        onClick={onClickItem}
        url={shareUrl}
        {..._props}
      >
        <img src={icon} alt={alt} />
      </Component>
    );
  };

  return (
    <div className={`${styles.Root} ${className}`}>
      <div className={styles.arrow} />

      <div className={styles.items}>{items.map(renderItem)}</div>

      <Button className={styles.copy} onClick={onCopy}>
        {isCopied ? 'Copié !' : 'Copier le lien'}
      </Button>
    </div>
  );
}
