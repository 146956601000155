import {
  Unsubscribe,
} from 'app/global';
import {
  EventName,
} from './config';
import {
  EventData,
} from './types';

class Events {
  private static instance?: Events;
  public static getInstance(): Events {
    if (!Events.instance) {
      Events.instance = new Events();
    }

    return Events.instance;
  }

  private latestEventsData: Record<string, any> = {};
  private eventsListeners: Record<string, any> = {};

  public readonly Name = EventName;

  public trigger<T extends EventName>(
    eventName: T,
    data: EventData<T>,
  ): void {
    this.latestEventsData[eventName] = data;

    if (this.eventsListeners[eventName]) {
      for (let i = 0; i < this.eventsListeners[eventName].length; i++) {
        this.eventsListeners[eventName][i](data);
      }
    }
  }

  public listenTo<T extends EventName> (
    eventName: T,
    callback: (data: EventData<T>) => void,
  ): Unsubscribe {
    if (!this.eventsListeners[eventName]) {
      this.eventsListeners[eventName] = [];
    }

    this.eventsListeners[eventName].push(callback);

    if (this.latestEventsData[eventName] !== undefined) {
      callback(this.latestEventsData[eventName]);
    }

    return (): void => {
      for (let i = 0; i < this.eventsListeners[eventName].length; i++) {
        if (this.eventsListeners[eventName][i] === callback) {
          this.eventsListeners[eventName].splice(i, 1);
          break;
        }
      }
    };
  }

  public getLatestData<T extends EventName>(
    eventName: T,
  ): EventData<T> | undefined {
    return this.latestEventsData[eventName];
  }

}

export default (): Events => {
  return Events.getInstance();
};
