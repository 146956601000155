import { gql } from "@apollo/client";

export const UpdateUser = gql`
  mutation updateUser($userId: Int!, $data: IgnoreNoneUpdateUserData!) {
    updateUser(userId: $userId, data: $data) {
      id
    }
  }
`;

export const ProcessCheckout = gql`
  mutation processCheckout(
    $paymentIntentId: String
    $singleCheckoutInput: [SingleCheckoutInput!]!
    $invoiceEmail: String!
  ) {
    processCheckout(
      paymentIntentId: $paymentIntentId
      singleCheckoutInput: $singleCheckoutInput
      invoiceEmail: $invoiceEmail
    ) {
      id
      clientSecret
      status
    }
  }
`;

export const SendTestEmail = gql`
  mutation sendTestEmail($userEmails: [String!]!, $templateData: String!, $templateName: String!) {
    sendTestEmail(userEmails: $userEmails, templateData: $templateData, templateName: $templateName)
  }
`;

export const SendInviteEmail = gql`
  mutation sendInviteEmail($email: String!) {
    sendInviteEmail(email: $email)
  }
`;

export const AcceptGift = gql`
  mutation acceptGift($code: String!) {
    acceptGift(code: $code) {
      id
    }
  }
`;

export const UpdateSubscription = gql`
  mutation updateSubscription($subscriptionId: Int!, $singleCheckoutInput: SingleCheckoutInput!) {
    updateSubscription(subscriptionId: $subscriptionId, singleCheckoutInput: $singleCheckoutInput)
  }
`;

export const DeleteSubscription = gql`
  mutation deleteSubscription($subscriptionId: Int!) {
    deleteSubscription(subscriptionId: $subscriptionId)
  }
`;
