import {
  gql,
} from '@apollo/client';

export const GetEvents = gql`
  query getEvents($limit: Int, $offset: Int, $campaignId: Int, $eventType: Int, $q: String) {
    events(limit: $limit, offset: $offset, campaignId: $campaignId, eventType: $eventType, q: $q) {
      id
      title
      startAt
      endAt
      userId
    }
  }
`;

export const GetEvent = gql`
  query getEvent($id: Int!) {
    event(id: $id) {
      id
      title
      startAt
      endAt
      userId
      createdAt
      updatedAt
      deletedAt
      isReplied
      votesCount
      campaigns {
        id
        description
        logo
        media
        votesCount
        campaign {
          id
          name
          description
          project {
            subtitle
          }
        }
        campaignData
      }
    }
  }
`;
