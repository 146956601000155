import { ReactElement } from 'react';
import Button from '../../Button';
import { ModuleProps } from '../config';
import styles from './index.module.scss';
import Toggle from 'app/components/common/Toggle';
import useLanguage from 'app/alumni/useLanguage';

export default function Language(
  props: ModuleProps,
): ReactElement {
  const { onSubmit } = props;

  const { toggleEnglish, english } = useLanguage();

  return (
    <div className={styles.root}>
      <div className={styles.body}>
        <Toggle
          leftChildren={(
            <span className={styles.language}>
              FRANÇAIS
            </span>
          )}

          rightChildren={(
            <span className={styles.language}>
              ENGLISH
            </span>
          )}
          value={english}
          onChange={toggleEnglish}
          sliderColor='#EA357F' />
      </div>

      <Button
        className={styles.submit}
        onClick={onSubmit}>
        {english
          ? 'CONTINUE'
          : 'CONTINUER'
        }
      </Button>
    </div>
  );
}
