import { useState, useEffect } from 'react';

const DEFAULT_INITIAL_IS_LOGIN = true;
let __showModal;

export function useAuthModalCore() {
  const [isVisible, setIsVisible] = useState(false);
  const [initialIsLogin, setInitialIsLogin] = useState(DEFAULT_INITIAL_IS_LOGIN);

  useEffect(() => {
    __showModal = (props) => {
      const {
        initialIsLogin = DEFAULT_INITIAL_IS_LOGIN
      } = props || {};

      setInitialIsLogin(initialIsLogin);
      setIsVisible(true);
    }
  }, []);

  return {
    isVisible,
    setIsVisible,
    initialIsLogin,
  };
}

export function useAuthModal() {
  const showAuthModal = (props) => __showModal && __showModal(props);

  return {
    showAuthModal,
  };
}
