import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Progress from 'app/components/common/Progress/Campaign';
import Contribute from 'app/components/common/Contribute';
import ContributorAvatars from 'app/components/common/ContributorAvatars';
import styles from './index.module.scss';

Details.propTypes = {
  className: PropTypes.string,
  campaign: PropTypes.object.isRequired,
  onRouteToProject: PropTypes.func.isRequired,
};

export default function Details(props) {
  const {
    className,
    campaign,
    onRouteToProject,
  } = props;
  const {
    id: campaignId,
    name: campaignName,
  } = campaign;
  const [isRefreshProgress, setIsRefreshProgress] = useState(false);

  const onContributionCompleted = () => {
    setIsRefreshProgress(true);

    setTimeout(() => {
      setIsRefreshProgress(false);
    }, 500);
  };

  return (
    <div className={`${styles.RootContainer} ${className}`}>
      <div className={styles.header}>
        <div
          className={styles.title}
          onClick={onRouteToProject}
          style={{ fontSize: campaignName.length <= 30 ? 22 : 18 }}>
          {campaignName}
        </div>


        <Contribute
          className={styles.Contribute}
          label="Contribuer"
          campaign={campaign}
          onContributionCompleted={onContributionCompleted} />
      </div>

      <div className={styles.footer}>
        <ContributorAvatars className={styles.ContributorAvatars} campaignId={campaignId} />

        {!isRefreshProgress && (
          <Progress className={styles.Progress} campaign={campaign} />
        )}
      </div>
    </div>
  );
}
