import {
  CheckboxProps
} from './types';
import styles from './index.module.scss';
// Assets
import IconChecked from './assets/checked.svg';
import IconUnchecked from './assets/unchecked.svg';

function Checkbox(props: CheckboxProps): JSX.Element {

  const onToggle = (): void => {
    props.onChange(!props.isChecked);
  };

  return (
    <div
      className={`${styles.container} ${props.className}`}
      onClick={onToggle}>
      <img
        src={props.isChecked ? IconChecked : IconUnchecked}
        alt="" />

      {!!props.text && (
        <div className={styles.text}>
          {props.text}
        </div>
      )}
    </div>
  );
}

export default Checkbox;
