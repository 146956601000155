import { Project } from './project';
import { Strategy } from './strategy';
import { User } from './user';
import { Category } from './category';

export interface Breakdown {
  key: number;
  name: string;
  value: number;
}

export class Campaign {
  id: number;
  campaignUuid: string | undefined;
  name: string;
  status: number;
  description: string | null;
  fundingGoal: number | null;
  fundingBreakdown: Array<Breakdown>;
  milestoneBreakdown: Array<Breakdown>;
  startDate: Date | null;
  endDate: Date | null;
  isDraft: number;
  userId: number;
  projectId: number;
  financedAmount: number;
  financedText: string;
  strategyId: number | null;
  postsCount: number | null;
  notificationCount: number | null;
  strategy: Strategy | null;
  project: Project | null;
  expertHelp: Array<number>;
  expertHelpCategory: Array<Category> | null;
  explorerHelp: Array<number>;
  explorerHelpCategory: Array<Category> | null;
  campaignUsers: Array<CampaignUsers>;
  knowReporterSystem: boolean;
  receivePhysicalHelp: boolean;

  constructor(data: any) {
    this.id = data.id;
    this.campaignUuid = data.campaignUuid;
    this.userId = data.userId;
    this.name = data.name;
    this.status = data.status || 0;
    this.fundingGoal = data.fundingGoal;
    this.fundingBreakdown =
      typeof data.fundingBreakdown === 'object'
        ? data.fundingBreakdown
        : JSON.parse(data.fundingBreakdown || '{}');
    this.milestoneBreakdown =
      typeof data.milestoneBreakdown === 'object'
        ? data.milestoneBreakdown
        : JSON.parse(data.milestoneBreakdown || '{}');
    this.startDate = data.startDate ? new Date(data.startDate * 1000) : null;
    this.endDate = data.endDate ? new Date(data.endDate * 1000) : null;
    this.isDraft = data.isDraft;
    this.description = data.description;
    this.projectId = data.projectId;
    this.financedAmount = data.financedAmount;
    this.financedText = data.financedText;
    this.postsCount = data.postsCount;
    this.notificationCount = data.notificationCount;
    this.expertHelp = data.expertHelp ? data.expertHelp.map((s: string) => parseInt(s)) : [];
    this.expertHelpCategory = data.expertHelpCategory;
    this.explorerHelp = data.explorerHelp ? data.explorerHelp.map((s: string) => parseInt(s)) : [];
    this.explorerHelpCategory = data.explorerHelpCategory;
    this.strategyId = data.strategyId;
    this.strategy = data.strategy ? new Strategy(data.strategy) : null;
    this.project = data.project ? new Project(data.project) : null;
    this.campaignUsers = data.campaignUsers;
    this.knowReporterSystem = data.knowReporterSystem;
    this.receivePhysicalHelp = data.receivePhysicalHelp;
  }
}

export class CampaignData {
  name: string;
  description: string | null;
  fundingGoal: number | null;
  fundingBreakdown: string | null;
  milestoneBreakdown: string | null;
  startDate: number | null;
  endDate: number | null;
  isDraft: number;
  userId: number;
  projectId: number;
  expertHelp: Array<string>;
  explorerHelp: Array<string>;
  knowReporterSystem: boolean | null;
  receivePhysicalHelp: boolean | null;
  campaignUsers: Array<CampaignUsers>;

  constructor(data: Campaign) {
    this.userId = data.userId;
    this.name = data.name;
    this.fundingGoal = data.fundingGoal || 0;
    this.fundingBreakdown = JSON.stringify(data.fundingBreakdown);
    this.milestoneBreakdown = JSON.stringify(data.milestoneBreakdown);
    this.startDate = data.startDate ? data.startDate.getTime() / 1000 : null;
    this.endDate = data.endDate ? data.endDate.getTime() / 1000 : null;
    this.isDraft = data.isDraft;
    this.expertHelp = data.expertHelp ? data.expertHelp.map((s: number) => `${s}`) : [];
    this.explorerHelp = data.explorerHelp ? data.explorerHelp.map((s: number) => `${s}`) : [];
    this.description = data.description;
    this.projectId = data.projectId;
    this.campaignUsers = data.campaignUsers
      ? data.campaignUsers.filter((f: CampaignUsers) => f.email || f.userId)
      : [];
    this.knowReporterSystem = data.knowReporterSystem || false;
    this.receivePhysicalHelp = data.receivePhysicalHelp || false;
  }
}

export class CampaignUpdateData {
  name: string | null;
  description: string | null;
  fundingGoal: number | null;
  fundingBreakdown: string | null;
  milestoneBreakdown: string | null;
  startDate: number | null;
  endDate: number | null;
  isDraft: number | null;
  financedAmount: number | null;
  financedText: string | null;
  expertHelp: Array<string> | null;
  explorerHelp: Array<string> | null;
  knowReporterSystem: boolean | null;
  receivePhysicalHelp: boolean | null;
  campaignUsers: Array<CampaignUsers> | null;
  deletedAt: number | null;

  constructor(data: any) {
    this.name = data.name;
    this.fundingGoal = data.fundingGoal;
    this.fundingBreakdown = JSON.stringify(data.fundingBreakdown);
    this.milestoneBreakdown = JSON.stringify(data.milestoneBreakdown);
    this.startDate = data.startDate ? data.startDate.getTime() / 1000 : null;
    this.endDate = data.endDate ? data.endDate.getTime() / 1000 : null;
    this.isDraft = data.isDraft;
    this.expertHelp = data.expertHelp ? data.expertHelp.map((s: number) => `${s}`) : [];
    this.explorerHelp = data.explorerHelp ? data.explorerHelp.map((s: number) => `${s}`) : [];
    this.financedAmount = data.financedAmount ? parseFloat(data.financedAmount) : null;
    this.financedText = data.financedText;
    this.description = data.description;
    this.campaignUsers = data.campaignUsers
      ? data.campaignUsers.filter((f: CampaignUsers) => f.email || f.userId)
      : null;
    this.knowReporterSystem = data.knowReporterSystem;
    this.receivePhysicalHelp = data.receivePhysicalHelp;
    this.deletedAt = data.deletedAt ? data.deletedAt.getTime() / 1000 : null;
  }
}

export class CampaignUsers {
  id: number;
  campaignId: number;
  userId: number;
  role: string;
  email: string | null;
  name: string | null;
  user: User | null;

  constructor(data: CampaignUsers) {
    this.id = data.id;
    this.campaignId = data.campaignId;
    this.userId = data.userId;
    this.role = data.role;
    this.name = data.name;
    this.email = data.email;
    this.user = data.user;
  }
}

export class CampaignUsersData {
  campaignId: number;
  userId: number;
  role: string;
  name: string | null;
  email: string | null;

  constructor(data: CampaignUsers) {
    this.campaignId = data.campaignId;
    this.userId = data.userId;
    this.role = data.role;
    this.name = data.name;
    this.email = data.email;
  }
}

export interface UserRequest {
  id: number;
  campaignId: number;
  userId: number;
  role: string;
  user: User;
  project: Project;
}

export interface UserRequestData {
  id: number;
  campaignId: number;
  userId: number;
  role: string;
}

export interface Contribution {
  id: number;
  contributionUuid: string;
  campaignId: number;
  userId: number;
  amount: number;
  transactionId: number;
  user: User;
  campaign: Campaign;
}

export class Contribution {
  id: number;
  contributionUuid: string;
  campaignId: number;
  userId: number;
  giftId: number | null;
  amount: number;
  transactionId: number;
  user: User;
  campaign: Campaign;
  createdAt: Date;

  constructor(data: any) {
    this.id = data.id;
    this.campaignId = data.campaignId;
    this.userId = data.userId;
    this.giftId = data.giftId || null;
    this.amount = data.amount;
    this.transactionId = data.transactionId;
    this.user = data.user as User;
    this.campaign = new Campaign(data.campaign);
    this.contributionUuid = data.contributionUuid;
    this.createdAt = new Date(data.createdAt * 1000);
  }
}

export type GqlCampaignCreateResponse = {
  createCampaign: {
    id: number;
    campaignUuid: string;
  };
};

export type GqlCampaignUpdateResponse = {
  updateCampaign: Campaign;
};

export type GqlCampaignsResponse = {
  campaigns: Array<Campaign>;
};

export type GqlAdminCampaignsResponse = {
  adminCampaigns: Array<Campaign>;
};

export type GqlCreateUserRequestResponse = {
  userRequest: UserRequest;
};

export type GqlGetUserRequestsResponse = {
  userRequests: Array<UserRequest>;
};

export type GqlContributionResponse = {
  contribution: Contribution;
};

export type GqlGetContributionResponse = {
  contributions: Array<Contribution>;
};

export type GqlSingleCampaignResponse = {
  campaign: Campaign;
};
