import {
  useState,
} from 'react';
import {
  authentication,
  SignInResult,
} from 'app/module';
import {
  ROUTES,
} from 'app/config/routes';
import Background from 'app/components/Background';
import Text from 'app/components/common/Input/Text';
import Checkbox from 'app/components/common/Input/Checkbox';
import styles from './index.module.scss';
import BackgroundImage from './background.svg';
import Button from 'app/components/common/Button';
import isEmail from 'validator/lib/isEmail';

function CompanyLogin(): JSX.Element {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [accepted, setAccepted] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const emailError: boolean = isEmail(email) === false;
  const submitDisabled: boolean = (
    accepted === false ||
    emailError === true ||
    password === ''
  );

  const login = async (): Promise<void> => {
    setLoading(true);
  
    const result: SignInResult = await authentication().signIn({
      email,
      password,
    });

    if (result === SignInResult.Failed) {
      setLoading(false);
    }
  };

  return (
    <>
      <Background
        image={BackgroundImage} />

      <div className={styles.root}>
        <div className={styles.header}>
          Connexion à Kunbis
        </div>

        <div className={styles.body}>
          <div className={styles.content}>
            <div className={styles.titleConnect}>
              Connectez-vous à votre panel entreprise et changez le cours de choses
            </div>

            <Text
              className={styles.input}
              type="email"
              autoComplete="email"
              placeholder="Identifiant"
              error="Merci de renseigner une adresse e-mail valide"
              isError={emailError}
              onChangeText={setEmail}
              value={email} />

            <Text
              className={styles.input}
              type="password"
              placeholder="Mot de passe"
              onChangeText={setPassword}
              value={password} />

            <Checkbox
              className={styles.checkbox}
              text={
                <div>
                  Mon projet accepte les
                  <a href={ROUTES.TERMS.URL} target="_blank" rel="noreferrer">
                    conditions générales de service.
                  </a>
                </div>
              }
              onChange={setAccepted}
              isChecked={accepted} />

            <Button
              className={styles.login}
              isDisabled={submitDisabled}
              isProcessing={loading}
              onClick={login}>
              Connexion
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default CompanyLogin;
