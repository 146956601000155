import {
  BackgroundProps,
} from './types';
import director from './director';
import { useEffect } from 'react';
import { Unsubscribe } from 'app/global';

function Background(props: BackgroundProps): null {

  useEffect((): Unsubscribe => {
    director().update(props);

    return (): void => {
      director().reset();
    };
  }, [props]);
  
  return null;
}

export default Background;
