// import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Modal from 'app/components/common/Modal';
import Post from 'app/components/post';
import { usePostModal } from './usePostModal';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useLazyQuery } from '@apollo/client';
import { Post as PostType } from 'app/graphql/types';
import { getSinglePost } from 'app/graphql/queries';
import styles from './index.module.scss';

export default function PostModalProvider() {
  const { isVisible, onClose, postUuid } = usePostModal();
  const [data, setData] = useState(null);

  const [getPost, { loading: isProcessing }] = useLazyQuery(getSinglePost, {
    variables: {
      uuid: postUuid,
    },
    onCompleted(data) {
      if (data?.post) {
        setData(new PostType(data.post));
      }
    }
  });

  useEffect(() => {
    if (postUuid) {
      getPost();
    }
  }, [getPost, postUuid]);

  if (!isVisible) {
    return null;
  }

  return (
    <Modal className={styles.Modal} onClose={onClose}>
      {isProcessing || !data
        ? <CircularProgress
            className={styles.CircularProgress}
            size={27}
            color="primary" />
        : <Post
            postData={data} />
      }
    </Modal>
  );
}
