import {
  ObserverConfig,
  Observer,
  Unsubscribe,
} from './types';

abstract class Observable {
  private observers: Observer[] = [];

  public observe(observer: Observer, config?: ObserverConfig): Unsubscribe {
    this.observers.push(observer);

    if (config?.triggerOnMount !== false) {
      observer();
    }

    return (): void => {
      this.detach(observer);
    };
  }

  private detach(observer: Observer): void {
    for (let i = 0; i < this.observers.length; i++) {
      if (this.observers[i] === observer) {
        this.observers.splice(i, 1);
        break;
      }
    }
  }

  protected detachAll(): void {
    while (this.observers.length > 0) {
      this.detach(this.observers[0]);
    }
  }

  protected notify(): void {
    for (const listener of this.observers) {
      listener();
    }
  }

}

export default Observable;
