import { City } from './city';

// REFACTOR BEGINS HERE //
import {
  CityTypes,
} from 'app/graphql/types/city';

export namespace AddressTypes {

  export interface Address {
    id: number;
    addressUuid: string;
    address1: string;
    address2?: string;
    cityStr?: string;
    country?: string;
    city?: CityTypes.City;
    state: string;
    zipCode: string;
    phone?: string;
    lat?: number;
    lng?: number;
    name?: string;
    firstName?: string;
    lastName?: string;
    addressType: number;
    userId: number;
    projectId?: number;
  }

  export interface AddressUpdate {
    id: number;
    address1: string;
    address2?: string;
    cityStr?: string;
    country?: string;
    cityId?: number;
    state: string;
    zipCode: string;
    phone?: string;
    firstName?: string;
    lastName?: string;
    lat?: number;
    lng?: number;
    name?: string;
    addressType: number;
    userId: number;
    projectId?: number;
  }

}

// REFACTOR BEGINS HERE //

export class Address {
  id: number;
  addressUuid: string;
  address1: string;
  address2: string | null;
  city: City;
  state: string;
  zipCode: string;
  phone: string | null;
  lat: number;
  lng: number;
  name: string | null;
  firstName: string | null;
  lastName: string | null;
  dob: Date | null;
  addressType: number;
  userId: number;
  projectId: number | null;

  constructor(data: any) {
    this.id = data.id;
    this.addressUuid = data.addressUuid;
    this.address1 = data.address1;
    this.address2 = data.address2;
    this.city = data.city;
    this.state = data.state;
    this.zipCode = data.zipCode;
    this.phone = data.phone;
    this.lat = data.lat ? data.lat : data.city ? data.city.lat : 0;
    this.lng = data.lng ? data.lng : data.city ? data.city.lng : 0;
    this.name = data.name;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.phone = data.phone;
    this.dob = data.dob
      ? typeof data.dob === 'object'
        ? data.dob
        : new Date(data.dob * 1000)
      : null;
    this.addressType = data.addressType;
    this.userId = data.userId;
    this.projectId = data.projectId;
  }
}

export class AddressData {
  id: number;
  address1: string;
  address2: string | null;
  cityId: number | null;
  state: string;
  zipCode: string;
  phone: string | null;
  lat: number;
  lng: number;
  name: string | null;
  firstName: string | null;
  lastName: string | null;
  dob: number | null;
  addressType: number;
  userId: number;
  projectId: number | null;

  constructor(data: Address) {
    this.id = data.id;
    this.address1 = data.address1;
    this.address2 = data.address2;
    this.cityId = data.city ? data.city.id : null;
    this.state = data.state;
    this.zipCode = data.zipCode;
    this.phone = data.phone;
    this.lat = data.lat ? data.lat : data.city ? data.city.lat : 0;
    this.lng = data.lng ? data.lng : data.city ? data.city.lng : 0;
    this.name = data.name;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.phone = data.phone;
    this.dob = data.dob ? data.dob.getTime() / 1000 : null;
    this.addressType = data.addressType;
    this.userId = data.userId;
    this.projectId = data.projectId;
  }
}
