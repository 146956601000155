import PropTypes from 'prop-types';
import Button from 'app/components/common/Button';
import Avatar from 'app/components/common/Avatar';
import Credits from 'app/components/common/CreditsModal';
import ImpactNav from '../ImpactNav';
import {
  ROUTES,
} from 'app/config/routes';
import {
  wordingMultiple,
} from 'app/utils/wording';
import {
  useCart,
} from 'app/use/useCart';
import {
  authentication,
  useUser,
} from 'app/module';
import styles from './index.module.scss';
// Assets
import IconHeart from './assets/heart.svg';
import IconIncognito from './assets/incognito.svg';
import IconLamp from './assets/lamp.svg';
import IconHot from './assets/hot.svg';
import IconArrowRight from './assets/arrow-right.svg';
import IconLogout from './assets/logout.svg';

UserMenu.propTypes = {
  history: PropTypes.object.isRequired,
};

export default function UserMenu(props) {
  const {
    history,
  } = props;

  const {
    cart
  } = useCart();

  const {
    projects,
    user,
  } = useUser();
  const {
    avatar,
    name,
    notificationCount,
    wallet,
    role,
    campaignRoles,
    contributionCount,
    gifts,
  } = user;

  const isAdmin = role === 'admin';  
  const countProjectsPending = cart.length;
  const countProjectsSupported = contributionCount;
  const countProjectsHelped = campaignRoles?.length || 0;
  const isAnyActivity = (
    countProjectsPending > 0 ||
    countProjectsSupported > 0 ||
    countProjectsHelped > 0
  );

  const onRouteToProfile = () => history.push(ROUTES.USER.URL());
  const onRouteToMyContributions = () => history.push(ROUTES.USER.URL(ROUTES.USER.URL_PROPS.NAVIGATION_IDS.CONTRIBUTIONS));
  const onRouteToSettings = () => history.push(ROUTES.SETTINGS.URL());
  const onRouteToAdmin = () => history.push(ROUTES.ADMIN.URL);
  const onRouteToDiscord = () => window.open('https://discord.gg/S57QNXSaPg');

  const renderProjectDetails = (item, index) => {
    const {
      icon,
      count,
      title,
      route,
      isDisabled
    } = item;
  
    const onClick = () => history.push(route);

    return (
      <Button
        key={index}
        className={styles.projectDetail}
        onClick={onClick}
        isDisabled={isDisabled}>
        <img src={icon} alt="" />

        <span>{count}</span>

        {title}

        <img className={styles.projectDetail_arrow} src={IconArrowRight} alt="" />
      </Button>
    );
  };

  const renderProject = (project) => {
    const { id, projectUuid, logo, name, status, activeCampaignCount } = project;

    const onClick = () => {
      if (status === 1 && activeCampaignCount > 0) {
        history.push(ROUTES.CAMPAIGN_MANAGE.URL(projectUuid));
      } else {
        history.push(ROUTES.PROJECT_MANAGE.URL(projectUuid));
      }
    };

    return (
      <Button key={id} onClick={onClick} className={styles.project}>
        <Avatar className={styles.project_logo} isProject={true} src={logo} alt={name} size={34} />

        {name}
      </Button>
    );
  };

  const renderGift = (gift, index) => {
    return (
      <div
        key={index}
        className={styles.gift}>
        <Credits
          onClick={() => {}}
          amount={gift.amount} />
        
        <div>
          de
          <strong>
            {gift.user.name}
          </strong>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.RootContainer}>
      <div className={styles.body}>
        <ImpactNav
          className={styles.Navigation}
          history={history} />

        <div className={styles.userContainer}>
          <div className={styles.user}>
            <div className={styles.user_left}>
              <div className={styles.title}>Mon compte</div>

              <Button className={styles.myProfile} onClick={onRouteToProfile}>
                Mon profil
                {!!notificationCount && (
                  <div className={styles.myProfile_notifications}>{notificationCount}</div>
                )}
              </Button>

              <Button className={styles.settings} label="Mes reçus fiscaux" onClick={onRouteToMyContributions} />

              <Button className={styles.settings} label="Réglages" onClick={onRouteToSettings} />

              <Button className={styles.community} label="La communauté" onClick={onRouteToDiscord} />

              <Button className={styles.myTransparency} onClick={onRouteToProfile}>
                <img src={IconHeart} alt="" />
                Mon fil de transparence
              </Button>
            </div>

            <div className={styles.user_right}>
              <Avatar src={avatar} alt={name} size={76} onClick={onRouteToProfile} />

              <div className={styles.username}>{name}</div>

              <Credits amount={wallet} />
            </div>
          </div>

          {gifts.length > 0 && (
            <div className={styles.gifts}>
              <div className={styles.title}>
                Mes cadeaux !
              </div>

              {gifts.map(renderGift)}
            </div>
          )}

          <div className={styles.line} />

          <div className={styles.titleSmall}>
            {isAnyActivity
              ? `${name} est occupé à changer le cours des choses`
              : `${name} n’a pas encore changé le cours des choses`}
          </div>

          {renderProjectDetails({
            icon: IconIncognito,
            count: countProjectsPending,
            title: `${wordingMultiple('projet', countProjectsPending > 0)} en attente`,
            isDisabled: countProjectsPending === 0,
            route: ROUTES.CHECKOUT.URL,
          })}
          {renderProjectDetails({
            icon: IconLamp,
            count: countProjectsSupported,
            title: `${wordingMultiple('projet', countProjectsSupported > 0)} ${wordingMultiple(
              'soutenu',
              countProjectsSupported > 0
            )}`,
            route: ROUTES.USER.URL(ROUTES.USER.URL_PROPS.NAVIGATION_IDS.CONTRIBUTIONS),
          })}
          {renderProjectDetails({
            icon: IconHot,
            count: countProjectsHelped,
            title: `${wordingMultiple('projet', countProjectsHelped > 0)} ${wordingMultiple(
              'aidé',
              countProjectsHelped > 0
            )}`,
            route: ROUTES.USER.URL(ROUTES.USER.URL_PROPS.NAVIGATION_IDS.HELPER),
          })}

          {projects.length > 0 && (
            <>
              <div className={styles.titleMyProjects}>
                Mes projets
              </div>

              <div className={styles.projects}>
                {projects.map(renderProject)}
              </div>
            </>
          )}

          {isAdmin && (
            <Button
              className={styles.admin}
              onClick={onRouteToAdmin}
              label="Admin access" />
          )}
        </div>
      </div>

      <div
        className={styles.logout}
        onClick={authentication().signOut}>
        <img src={IconLogout} alt="" />
        Déconnexion
      </div>
    </div>
  );
}
