import {
  ProcessCheckout,
} from 'app/graphql/mutations';
import {
  Payload,
  Endpoint,
  RequestFabric,
  GraphQL,
} from 'app/api';

class PaymentApi {

  public static getMethods(): Promise<unknown> {
    return RequestFabric.get(Endpoint.Payment.GetMethods);
  }

  public static deleteMethod(payload: Payload.Payment.DeleteMethod): Promise<unknown> {
    return RequestFabric.post(Endpoint.Payment.DeleteMethod, payload);
  }

  public static getIntent(payload: Payload.Payment.GetNonSubscriptionIntent): Promise<unknown> {
    return RequestFabric.post(Endpoint.Payment.GetIntent, payload);
  }

  public static getSubscriptionIntent(payload: Payload.Payment.GetSubscriptionIntent): Promise<unknown> {
    return RequestFabric.graphQL(
      ProcessCheckout,
      payload,
      GraphQL.ResponseKey.Payment.GetIntent,
      (data: any[]): unknown => {
        if (Array.isArray(data)) {
          return data[0];
        }

        return data;
      }
    );
  }

  public static processCheckout(payload: Payload.Payment.ProcessCheckout): Promise<void> {
    return RequestFabric.graphQL(ProcessCheckout, payload);
  }

}

export default PaymentApi;