import { useState, useEffect } from 'react';

let __listener = null;

export function showPostModal(postUuid) {
  __listener && __listener(postUuid);
}

export function usePostModal() {
  const [isVisible, setIsVisible] = useState(false);
  const [postUuid, setPostUuid] = useState(null)

  const onClose = () => setIsVisible(false);

  useEffect(() => {
    __listener = (uuid) => {
      setPostUuid(uuid);
      setIsVisible(true);
    };

    return () => {
      __listener = null;
    };
  }, []);

  return {
    isVisible,
    onClose,
    postUuid,
  };
}
