import { DetailedHTMLProps, HTMLAttributes, ReactElement } from 'react';
import styles from './index.module.scss';

export default function Button(
  props: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
): ReactElement {
  const { children, className = '', ..._props } = props;

  return (
    <div
      className={`${styles.root} ${className}`}
      role='button'
      tabIndex={0}
      {..._props}>
      {children}
    </div>
  );
}
