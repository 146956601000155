// assets
import IconMegaphone from './assets/megaphone.svg';
import IconController from './assets/controller.svg';
import IconBoard from './assets/board.svg';
import IconCamera from './assets/camera.svg';
import IconShare from './assets/share.svg';

export const NAVIGATION_TYPES = {
  MAIN: 'strategie',
  NOTIFICATIONS: 'piloter-la-campagne',
  STATS: 'suivre-mes-paiements',
  HELPERS: 'mes-helpers',
  URLS: 'diffusion',
};

export const TABS = [
  {
    type: NAVIGATION_TYPES.MAIN,
    label: 'Stratégie',
    icon: IconMegaphone,
    isAdminOnly: true,
  },
  {
    type: NAVIGATION_TYPES.NOTIFICATIONS,
    label: 'Piloter la campagne',
    icon: IconController,
  },
  {
    type: NAVIGATION_TYPES.STATS,
    label: 'Suivre mes paiements',
    icon: IconBoard,
  },
  {
    type: NAVIGATION_TYPES.HELPERS,
    label: 'Mes helpers',
    icon: IconCamera,
    isAdminOnly: true,
  },
  {
    type: NAVIGATION_TYPES.URLS,
    label: 'Diffusion',
    icon: IconShare,
  },
];
