// Assets
import IconUfo from './assets/navigation/ufo.svg';
import IconUfoActive from './assets/navigation/ufo-colored.svg';
import IconBaloon from './assets/navigation/baloon.svg';
import IconBaloonActive from './assets/navigation/baloon-colored.svg';
import IconPictures from './assets/navigation/pictures.svg';
import IconPicturesActive from './assets/navigation/pictures-colored.svg';
import IconCamera from './assets/navigation/camera.svg';
import IconCameraActive from './assets/navigation/camera-colored.svg';

export const NAVIGATION_TYPES = {
  ABOUT: 'a-propos',
  PUBLICATIONS: 'les-actions',
  PHOTOS: 'photos',
  HELPERS: 'helpers',
};

export const NAVIGATION_ITEMS = [
  {
    id: NAVIGATION_TYPES.ABOUT,
    icon: IconUfo,
    iconActive: IconUfoActive,
    label: 'À propos',
  },
  {
    id: NAVIGATION_TYPES.PUBLICATIONS,
    icon: IconBaloon,
    iconActive: IconBaloonActive,
    label: 'Les actions',
  },
  {
    id: NAVIGATION_TYPES.PHOTOS,
    icon: IconPictures,
    iconActive: IconPicturesActive,
    label: 'Photos',
  },
  {
    id: NAVIGATION_TYPES.HELPERS,
    icon: IconCamera,
    iconActive: IconCameraActive,
    label: 'Helpers',
  },
];
