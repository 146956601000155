import { gql } from '@apollo/client';

export const CreateCity = gql`
  mutation createCity($data: NewCityData!) {
    createCity(data: $data) {
      id
      city
      country
      lat
      lng
      iso2
    }
  }
`;
