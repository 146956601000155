import * as Sentry from '@sentry/react';
import {
  ApolloClient,
  HttpLink,
} from '@apollo/client';
import {
  setContext,
} from '@apollo/client/link/context';
import {
  onError,
} from '@apollo/client/link/error';
import {
  InMemoryCache,
} from '@apollo/client/cache';
import {
  toastError,
} from 'app/utils/toast';
import {
  GRAPHQL_ENDPOINT,
} from 'app/graphql/variables';

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      if (err?.message !== 'Unauthorized') {
        if (process.env.REACT_APP_ENV !== 'production') {
          toastError(err?.message);
        }

        Sentry.captureException(err);
      }

      switch (err?.extensions?.code) {
        case 'TOKEN_EXPIRED':
          return;

        default:
          return;
      }
    }
  }

  if (networkError) {
    Sentry.captureException(networkError);
  }

  // if you would also like to retry automatically on
  // network errors, we recommend that you use
  // @apollo/client/link/retry
});

const authLink = setContext((_, { headers }) => ({
  headers: {
    ...headers,
    authorization: '',
  },
}));

const uploadLink = new HttpLink({
  uri: GRAPHQL_ENDPOINT,
  credentials: 'include',
});

const apolloClient = new ApolloClient({
  link: authLink.concat(errorLink).concat(uploadLink),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache',
    },
    query: {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache',
    },
  },
});

export default apolloClient;