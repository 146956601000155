import Toastify from 'toastify-js';
import styles from './index.module.scss';

// docs: https://github.com/apvarun/toastify-js#documentation

const defaultProps = {
  duration: 5000,
  close: false,
  gravity: 'bottom',
  position: 'left',
  stopOnFocus: true,
};

export const toastSuccess = (text, props = {}) => {
  if (text) {
    const toast = Toastify({
      ...defaultProps,
      onClick: () => toast.hideToast(),
      className: `${styles.toast} ${styles.success}`,
      text,
      ...props,
    });

    toast.showToast();
  }
};

export const toastInfo = (text, props = {}) => {
  if (text) {
    const toast = Toastify({
      ...defaultProps,
      onClick: () => toast.hideToast(),
      className: `${styles.toast} ${styles.info}`,
      text,
      ...props,
    });

    toast.showToast();
  }
};

export const toastError = (text, props = {}) => {
  if (text) {
    const toast = Toastify({
      ...defaultProps,
      onClick: () => toast.hideToast(),
      className: `${styles.toast} ${styles.error}`,
      text: text,
      ...props,
    });

    toast.showToast();
  }
};
