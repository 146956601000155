import IconBubble from './assets/bubble.svg';
import IconClipboard from './assets/clipboard.svg';
import IconCheck from './assets/check.svg';

export const POST_TYPES = {
  CAMPAIGN: 'campaign',
  USER: 'user',
};

export const POST_CONTENT_TYPES = {
  VIDEO: 1,
  IMAGE: 2,
  TEXT: 3,
  ILLUSTRATION: 4,
  LINKS: 5,
};

export const CATEGORY_TYPES = {
  DEFAULT: 1,
  EXPLORER: 2,
  EXPERT: 3,
};

export const ROLE_TYPES = {
  EXPERT: 'expert',
  EXPLORER: 'explorer',
  REPORTER: 'reporter',
};

export const ROLE_CONFIG = {
  [ROLE_TYPES.REPORTER]: {
    title: 'Reporter',
    color: '#EFD33E',
  },
  [ROLE_TYPES.EXPERT]: {
    title: 'Expert',
    color: '#4AE0C6',
  },
  [ROLE_TYPES.EXPLORER]: {
    title: 'Explorateur',
    color: '#E840A1',
  },
};

// export const PERMISSIONS = {
//   PUBLISH_ARTICLE: 'PUBLISH_ARTICLE',
//   REPLY_COMMENT: 'REPLY_COMMENT',
//   SHARE_PROJECT: 'SHARE_PROJECT',
//   FOLLOW_INDICATORS: 'FOLLOW_INDICATORS',
// };

export const REPORTER_REQUEST_TYPES = {
  MANUALLY: 1,
  REQUESTED: 2,
};

export const REPORTER_REQUEST_STATUS = {
  PENDING: 0,
  ACCEPTED: 1,
  DECLINED: 2,
};

export const REPORTER_REPLY_TYPES = {
  ACCEPT: 1,
  DECLINE: 2,
};

export const WITHDRAWAL_STATUS = {
  PENDING: 1,
  DONE: 2,
};

export const CATEGORY_COLORS = {
  [CATEGORY_TYPES.DEFAULT]: '#5a42EF',
  [CATEGORY_TYPES.EXPERT]: '#87BBBE',
  [CATEGORY_TYPES.EXPLORER]: '#A193B2',
};

export const PROJECT_TYPES = {
  INDIVIDUAL: 1,
  ASSOCIATION: 2,
  ENTERPRISE: 3,
};

export const PROJECT_TYPE_NAMES = {
  [PROJECT_TYPES.INDIVIDUAL]: 'Individu',
  [PROJECT_TYPES.ASSOCIATION]: 'Association',
  [PROJECT_TYPES.ENTERPRISE]: 'Entreprise',
};

export const CAMPAIGN_STATUS = {
  DRAFT: 0,
  VALIDATED: 1
};

export const PROJECT_ADDRESS_TYPES = {
  USER: 1,
  BUSINESS: 2,
  ASSOCIATION: 3,
};

export const PROJECT_STRIPE_STATUS = {
  DEFAULT: 0,
  PENDING: 1,
  VERIFIED: 2,
  ERROR: 3,
};

export const USER_ADDRESS_TYPES = {
  USER: 1,
  REPORTER: 2,
};

export const STRATEGY_TYPES = {
  REQUIREMENT: 1,
  TIP: 2,
};

export const SUBSCRIPTION_TYPES = {
  PERSONAL: 1,
  PERSONAL_PROJECT: 2,
  GIFT: 3
};

export const REQUIREMENT_TYPES = {
  VIDEO: 1,
  PICTURE: 2,
  TEXT: 3,
  ILLUSTRATION: 4,
  LINKS: 5,
};

export const NOTIFIABLE_TYPES = {
  POST: 'post',
  USERS: 'users'
};

export const SUBSCRIPTION_CONFIG = {
  INTERVAL_TYPES: {
    WEEK: 'week',
    MONTH: 'month',
  },
  DURATION_TYPES: {
    MONTH: 'month',
    YEAR: 'year',
  },
};

export const REQUIREMENT_TIP_ICON_MAP = {
  '1': IconBubble,
  '2': IconClipboard,
  '3': IconCheck,
};

export const KUNBIS_PROJECT_UUID = '4ac9fdff-cb2c-406d-80eb-7c01789f8a36';
export const KUNBIS_PROJECT_ID = 11;

export const IS_LOCALHOST = window.location.hostname === 'localhost';

export const ROOT_DOMAIN = `${window.location.protocol}//${window.location.hostname}${
  IS_LOCALHOST ? `:${window.location.port}` : ''
}`;

export const PROJECT_SHARE_IMAGE = `${ROOT_DOMAIN}/assets/project-share.png`;

export const CONTACT_PHONE = '+33 6.24.03.34.98';

export const SOCIAL_MEDIA_URLS = {
  LINKEDIN: 'https://www.linkedin.com/company/kunbis',
  INSTAGRAM: 'https://www.instagram.com/kunbis_fr',
  FACEBOOK: 'https://www.facebook.com/Kunbis.fr',
  TWITTER: 'https://twitter.com/Kunbis_fr',
  TIKTOK: 'https://www.tiktok.com/@kunbis_fr',
};
