import { ReactElement } from 'react';
import Button from '../Button';
import styles from './index.module.scss';
import useLanguage from 'app/alumni/useLanguage';

interface ConfirmProps {
  title: string;
  onAbort: () => void;
  onConfirm: () => void;
}

export default function Confirm(
  props: ConfirmProps,
): ReactElement {
  const { title, onAbort, onConfirm } = props;

  const { english } = useLanguage();

  return (
    <div className={styles.root}>
      <div className={styles.body}>
        <div className={styles.title}>
          {title}
        </div>

        <div className={styles.buttons}>
          <Button
            className={styles.abort}
            onClick={onAbort}>
            {english
              ? 'NO'
              : 'NON'
            }
          </Button>

          <Button
            className={styles.confirm}
            onClick={onConfirm}>
            {english
              ? 'YES'
              : 'OUI'
            }
          </Button>
        </div>
      </div>
    </div>
  );
}
