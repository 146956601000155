
export enum RootPath {
  Vote = '/vote',
  Projects = '/projets',
  Live = '/live',
}

export interface SetBackground {
  setBackground: (color: BrandColor) => void;
}

export interface RootModuleProps extends SetBackground {
  campaigns: EventCampaign[];
  vote: (campaignId: number) => void;
}

export interface EventCampaign {
  id: number;
  name: string;
  name_eng: string;
  projectTitle: string;
  projectTitle_eng: string;
  description: string;
  description_eng: string;
  logo: string;
  media: string;
  votesCount: number;
}

export enum BrandColor {
  White = '#fff',
  Blue = '#1E397B',
}
