import {
  MutableRefObject,
  useRef,
  useState,
} from 'react';
import Text from 'app/components/common/Input/Text';
import styles from './index.module.scss';
import Button from 'app/components/common/Button';
import { LoginProps } from './types';
import director from '../director';
import Header from '../Header';
import { useGiftCodeResponse } from '../useGiftCodeResponse';
import { GiftCodeResponse } from 'app/graphql/types';

function Registration(props: LoginProps): JSX.Element {
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [passwordA, setPasswordA] = useState<string>('');
  const [passwordB, setPasswordB] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const refGiftLoaded: MutableRefObject<boolean> = useRef<boolean>(false);
  const submitDisabled: boolean = (
    firstName.trim() === '' ||
    lastName.trim() === '' ||
    passwordA === '' ||
    passwordA !== passwordB
  );

  const giftCodeResponse: GiftCodeResponse | null = useGiftCodeResponse();

  if (refGiftLoaded.current === false &&
      giftCodeResponse !== null) {
    refGiftLoaded.current = true;
    setFirstName(giftCodeResponse.name.split(' ')[0] || '');
    setLastName(giftCodeResponse.name.split(' ')[1] || '');
  }

  const register = async (): Promise<void> => {
    setLoading(true);

    const success: boolean = await director().signUp({
      firstName,
      lastName,
      password: passwordA,
    });
  
    if (success === false) {
      setLoading(false);
    }
  };

  return (
    <div className={`${styles.root} ${props.className}`}>
      <Header />

      <Text
        className={styles.input}
        placeholder="Prénom"
        autoComplete="given-name"
        onChangeText={setFirstName}
        value={firstName} />

      <Text
        className={styles.input}
        placeholder="Nom"
        autoComplete="family-name"
        onChangeText={setLastName}
        value={lastName} />

      <Text
        className={styles.input}
        value={director().email} />

      <Text
        className={styles.input}
        type="password"
        placeholder="Entrez un mot de passe"
        onChangeText={setPasswordA}
        value={passwordA} />

      <Text
        className={styles.input}
        type="password"
        placeholder="Confirmez votre mot de passe"
        onChangeText={setPasswordB}
        value={passwordB} />

      <Button
        className={styles.login}
        isDisabled={submitDisabled}
        isProcessing={loading}
        onClick={register}>
        Commencer
      </Button>
    </div>
  );
}

export default Registration;
