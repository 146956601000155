import React, { useState, useEffect, useRef } from 'react';
import { useOutsideClick } from 'app/use/useOutsideClick';
import Error from './Error';
import styles from './index.module.scss';
// Assets
import IconQuestionMark from './assets/question-mark.svg';

export function useInputError(props) {
  const { isError, value } = props;
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const refIsInit = useRef(false);

  const onFocus = () => {
    setIsErrorVisible(false);
  };
  const onBlur = () => {
    setIsErrorVisible(isError && (value || typeof value === 'number'));
  };

  useEffect(() => {
    if (refIsInit.current === false) {
      refIsInit.current = true;

      if (isError) {
        if (value || typeof value === 'number') {
          setIsErrorVisible(true);
        }
      }
    }
  }, [isError, value]);

  return {
    isErrorVisible,
    onFocus,
    onBlur
  };
}

export default function InputError(props) {
  const { className, error } = props;
  const [isVisible, setIsVisible] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [errorStyle, setErrorStyle] = useState({});
  const refRoot = useRef();

  const onClose = () => {
    setIsVisible(false);
    setIsSticky(false);
  };
  const onMouseEnter = (e) => setIsVisible(true);
  const onMouseLeave = () => {
    if (!isSticky) {
      onClose();
    }
  };
  const onClick = (e) => {
    if (isVisible && isSticky) {
      onClose();
    } else {
      setIsVisible(true);
      setIsSticky(true);
    }
  };
  const onClickError = (e) => {
    if (isSticky) {
      e.stopPropagation();
    }
  };

  useOutsideClick(refRoot, onClose);

  useEffect(() => {
    if (isVisible) {
      const { x, y, width } = refRoot.current.getBoundingClientRect();

      setErrorStyle({
        top: y,
        left: x + Math.floor(width / 2)
      });
    }
  }, [isVisible]);

  return (
    <div
      ref={refRoot}
      className={`${styles.Root} ${className}`}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}>
      <img src={IconQuestionMark} alt="" />

      {isVisible && (
        <Error
          style={errorStyle}
          text={error}
          onClick={onClickError} />
      )}
    </div>
  );
}
