
const {
  localStorage
} = window;

const Cache = {
  get: (key, defaultValue) => {
    const data = localStorage.getItem(key);

    if (typeof data === 'string') {
      return JSON.parse(data);
    }

    return defaultValue;
  },

  set: (key, data) => {
    localStorage.setItem(key, JSON.stringify(data));
  },

  unset: (key) => {
    localStorage.removeItem(key);
  }
};

export {
  Cache,
};
