import {
  OperationTypeInfo,
} from './types';

export enum OperationType {
  Coupon = 'Coupon',
  Budget = 'Budget',
}

export const OperationTypeConfig: Record<OperationType, OperationTypeInfo> = {
  [OperationType.Coupon]: {
    title: 'Coupon',
  },
  [OperationType.Budget]: {
    title: 'Budget Impact',
  },
}