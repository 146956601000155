import { useState } from 'react';
import { useQuery } from '@apollo/client';
import {
  getContributionsForCampaign,
  getContributionsForCampaignFullUser,
} from 'app/graphql/queries';
import { isPast, differenceInCalendarDays, format, isSameWeek, isSameDay } from 'date-fns';

export function useCampaignProgress(campaign, props) {
  const { setContributors } = props || {};
  const { id: campaignId, fundingGoal, endDate } = campaign;
  const [amount, setAmount] = useState(0);
  const [amountAverage, setAmountAverage] = useState(0);
  const [amountToday, setAmountToday] = useState(0);
  const [amountWeek, setAmountWeek] = useState(0);
  const [chartData, setChartData] = useState([]);

  const percent = Math.ceil((amount / fundingGoal) * 100);
  const daysLeft = isPast(endDate) ? 0 : differenceInCalendarDays(endDate, new Date());

  const { refetch } = useQuery(
    props?.isLoadFullUser ? getContributionsForCampaignFullUser : getContributionsForCampaign,
    {
      variables: {
        campaignId,
      },
      onCompleted: ({ contributions }) => {
        const mapChartData = (item) => ({
          name: format(new Date(item.updatedAt * 1000), 'MMM d'), // ,
          value: item.amount,
        });

        let total = 0;
        let totalToday = 0;
        let totalWeek = 0;

        for (let i = 0, len = contributions.length; i < len; i++) {
          const { amount: a, updatedAt } = contributions[i];

          total += a;

          if (isSameDay(new Date(updatedAt * 1000), new Date())) {
            totalToday += a;
          }
          if (isSameWeek(new Date(updatedAt * 1000), new Date())) {
            totalWeek += a;
          }
        }

        setContributors && setContributors(contributions);

        setAmount(total);
        setAmountAverage(total > 0 ? Math.round(total / contributions.length) : 0);
        setAmountToday(totalWeek);
        setAmountWeek(totalToday);
        setChartData(contributions.map(mapChartData));
      },
    }
  );

  return {
    refetch,
    amount,
    amountAverage,
    amountToday,
    amountWeek,
    chartData,
    percent: percent || 0,
    daysLeft: daysLeft || 0,
    isFunded: percent >= 100,
  };
}
