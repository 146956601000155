// import PropTypes from 'prop-types';
import React from 'react';
import Modal from 'app/components/common/Modal';
import Button from 'app/components/common/Button';
import Input from './Input';
import { useUploadModal } from './useUploadModal';
import styles from './index.module.scss';

export default function UploadModalProvider() {
  const {
    isVisible,
    onClose,
    onSubmit,
    title,
    files,
    activeFileIndex,
    setActiveFileIndex,
    onSubmitInputFile,
    onRemoveInputFile,
    isInput,
    isProcessing,
    progress,
    fileInputProps
  } = useUploadModal();
  const isActiveFile = activeFileIndex > -1;

  const renderInput = (item, index) => {
    const isActive = index === activeFileIndex;
    const isHidden = isActiveFile && !isActive;

    return (
      <Input
        key={index}
        index={index}
        isActive={isActive}
        className={`${styles.Input} ${isHidden && styles.Input__isHidden}`}
        setActiveFileIndex={setActiveFileIndex}
        onSubmit={onSubmitInputFile}
        onRemove={onRemoveInputFile}
        data={item}
        fileInputProps={fileInputProps} />
    );
  };

  if (!isVisible) {
    return null;
  }

  return (
    <Modal
      className={styles.Modal}
      onClose={onClose}>
      {!!title && (
        <div className={styles.title}>
          {title}
        </div>
      )}

      <div className={`${styles.body} ${isActiveFile && styles.body__isActiveFile}`}>
        {files.map(renderInput)}
        {isInput && renderInput(null, files.length)}
      </div>

      {isProcessing && (
        <div className={styles.progress}>
          <div
            className={styles.progress_bar}
            style={{ width: `${progress}%` }}/>
        </div>
      )}

      {!isActiveFile && (
        <div className={styles.footer}>
          <Button
            className={styles.buttonCancel}
            isDisabled={isProcessing}
            onClick={onClose}
            label='Annuler' />

          <Button
            className={styles.buttonSubmit}
            isProcessing={isProcessing}
            isDisabled={files.length < 1}
            onClick={onSubmit}
            label='Valider' />
        </div>
      )}
    </Modal>
  );
}
