import { gql } from '@apollo/client';

export const CreateComment = gql`
  mutation createComment($data: CommentData!) {
    createComment(data: $data) {
      id
      commentUuid
      content
      parentId
      mentions
      likes
      createdAt
      user {
        id
        userUuid
        name
        firstName
        lastName
        avatar
      }
    }
  }
`;

export const ToggleCommentLike = gql`
  mutation toggleCommentLike($commentId: Int!) {
    toggleCommentLike(commentId: $commentId) {
      id
      commentUuid
      content
      parentId
      mentions
      likes
      createdAt
      user {
        id
        userUuid
        name
        firstName
        lastName
        avatar
      }
    }
  }
`;
