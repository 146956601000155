import React, { useState, useEffect } from 'react';
import Button from 'app/components/common/Button';
import Cropper from 'react-easy-crop';
import { createImage } from 'app/utils';
import styles from './index.module.scss';
// Assets
import IconRotateLeft from './assets/rotate-left.svg';
import IconRotateRight from './assets/rotate-right.svg';

const MIN_ZOOM = 1;
const MAX_ZOOM = 5;

function getBoundingBoxDimensions(width, height, radianAngle) {
  return {
    width: Math.abs(Math.cos(radianAngle) * width) + Math.abs(Math.sin(radianAngle) * height),
    height: Math.abs(Math.sin(radianAngle) * width) + Math.abs(Math.cos(radianAngle) * height),
  };
}

function getRotation(deg) {
  return ((deg % 360) + 360) % 360;
}

async function getCroppedImgDataURL(props) {
  const { data, croppedAreaPixels, rotation } = props;
  const image = await createImage(data);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  const radianAngle = (rotation * Math.PI) / 180;
  const { width: bbWidth, height: bbHeight } = getBoundingBoxDimensions(
    image.width,
    image.height,
    radianAngle
  );

  canvas.width = bbWidth;
  canvas.height = bbHeight;

  ctx.translate(bbWidth / 2, bbHeight / 2);
  ctx.rotate(radianAngle);
  ctx.translate(-image.width / 2, -image.height / 2);
  ctx.drawImage(image, 0, 0);

  const nextImageData = ctx.getImageData(
    croppedAreaPixels.x,
    croppedAreaPixels.y,
    croppedAreaPixels.width,
    croppedAreaPixels.height
  );

  canvas.width = croppedAreaPixels.width;
  canvas.height = croppedAreaPixels.height;
  ctx.putImageData(nextImageData, 0, 0);

  return canvas.toDataURL('image/jpeg');
}

export default function Modify(props) {
  const { className, data, onSubmit: _onSubmit } = props;
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [metadata, setMetadata] = useState(null);
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [aspect, setAspect] = useState(0);
  const [resolutionMeta, setResolutionMeta] = useState([]);

  const onChangeZoom = (e) => setZoom(e.target.value);
  const onCancel = () => _onSubmit();
  const onSubmit = async () => {
    _onSubmit({
      metadata,
      dataURL: await getCroppedImgDataURL({
        data,
        croppedAreaPixels,
        rotation,
      }),
    });
  };
  const onRotateLeft = () => setRotation((prevState) => getRotation(prevState - 90));
  const onRotateRight = () => setRotation((prevState) => getRotation(prevState + 90));
  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    const { width, height, x, y } = croppedAreaPixels;

    setCroppedAreaPixels(croppedAreaPixels);
    setMetadata([
      ...resolutionMeta,
      { key: 'rotate', value: rotation },
      { key: 'crop_width', value: width },
      { key: 'crop_height', value: height },
      { key: 'crop_x', value: x },
      { key: 'crop_y', value: y },
    ]);
  };

  useEffect(() => {
    (async () => {
      const { width, height } = await createImage(data);

      setResolutionMeta([
        { key: 'width', value: width },
        { key: 'height', value: height },
      ]);

      setAspect((rotation / 90) % 2 === 0 ? width / height : height / width);
    })();
  }, [data, rotation]);

  return (
    <>
      <div className={`${styles.Root} ${className}`}>
        <Cropper
          style={styles.Cropper}
          image={data}
          crop={crop}
          zoom={zoom}
          minZoom={MIN_ZOOM}
          maxZoom={MAX_ZOOM}
          rotation={rotation}
          aspect={aspect}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
      </div>

      <div className={styles.options}>
        <div className={styles.rotate} onClick={onRotateLeft}>
          <img src={IconRotateLeft} alt="" />
        </div>

        <input
          className={styles.zoom}
          type="range"
          min={MIN_ZOOM}
          max={MAX_ZOOM}
          step={1}
          aria-labelledby="Zoom"
          value={zoom}
          onChange={onChangeZoom}
        />

        <div className={styles.rotate} onClick={onRotateRight}>
          <img src={IconRotateRight} alt="" />
        </div>
      </div>

      <div className={styles.footer}>
        <Button className={styles.buttonCancel} onClick={onCancel} label="Annuler" />

        <Button
          className={styles.buttonSubmit}
          isDisabled={!metadata}
          onClick={onSubmit}
          label="Valider"
        />
      </div>
    </>
  );
}
