import {
  useState,
  useEffect,
} from 'react';
import {
  Project,
  ProjectUpdateData,
} from 'app/graphql/types';
import {
  toastSuccess,
} from 'app/utils/toast';
import {
  ProjectApi,
} from 'app/api';

export interface UseProjectPublic {
  loading: boolean;
  project: Project | null;
}

export function useProjectPublic(projectUuid: string): UseProjectPublic {
  const [project, setProject] = useState<Project | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect((): void => {
    setProject(null);
    setLoading(true);
    
    ProjectApi
      .getPublicByUuid(projectUuid)
      .then(setProject)
      .finally(() => {
        setLoading(false);
      });
  }, [projectUuid]);

  return {
    loading,
    project,
  };
}

export interface UseProjectPrivate {
  loading: boolean;
  processing: boolean;
  project: Project | null;
  update: (data: any) => Promise<Project | void>;
}

export function useProjectPrivate(projectUuid: string): UseProjectPrivate {
  const [project, setProject] = useState<Project | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [processing, setProcessing] = useState<boolean>(false);

  const update = async (data: any): Promise<Project | void> => {
    if (project) {
      setProcessing(true);

      try {
        const payload: ProjectUpdateData = new ProjectUpdateData({
          ...project,
          ...data,
        });

        await ProjectApi.updateProject(project.id, payload);

        const updatedProject: Project = new Project({
          ...project,
          ...data,
        });

        setProject(updatedProject);
        toastSuccess('Modifications enregistrées');
  
        return updatedProject;
      } catch (error) {
        console.error(error);
      } finally {
        setProcessing(false);
      }
    }
  };

  useEffect((): void => {
    setProject(null);
    setLoading(true);
    
    ProjectApi
      .getPrivateByUuid(projectUuid)
      .then(setProject)
      .finally(() => {
        setLoading(false);
      });
  }, [projectUuid]);

  return {
    loading,
    processing,
    project,
    update,
  };
}
