import { gql } from "@apollo/client";

export const GetMe = gql`
  query getMe {
    getMe {
      id
      userUuid
      email
      role
      title
      name
      firstName
      lastName
      phone
      instagramHandle
      linkedinLink
      avatar
      position
      description
      helperDescription
      expertTitle
      expertHowLong
      expertMoreTimes
      expertHowLongType
      wallet
      companyId
      gifts {
        id
        amount
        wallet
        user {
          name
        }
      }
      campaignRoles
      contributionCount
      notificationCount
      lastNotificationReadAt
      notificationContribCount {
        campaignId
        notifCount
      }
      explorerCity {
        id
        city
      }
      explorerCanCityOut
      expertCategory {
        id
        categoryId
        userId
        category {
          id
          name
          categoryType
        }
      }
      explorerHowLong
      explorerHowLongType
      explorerStartAt
      explorerCategory {
        id
        categoryId
        userId
        category {
          id
          name
          categoryType
        }
      }
      isNewRepliesNotiff
      isProjectUpdatesNotiff
      isNewsletterNotiff
      isFollowedNotiff
      isNewSupportNotiff
      isNewCommentNotiff
      isCancelSupportNotiff
      isNewQuestionNotiff
      isPasswordSet
      createdAt
      updatedAt
      helperFilled
      city {
        id
        country
        city
        lat
        lng
      }
      address {
        id
        address1
        address2
        name
        state
        zipCode
        addressType
        userId
        projectId
        lat
        lng
        city {
          id
          city
          country
        }
      }
      company {
        id
        logo
        name
      }
    }
  }
`;

export const GetMeSmall = gql`
  query getMeSmall {
    getMeSmall {
      id
      userUuid
      email
      role
      title
      name
      firstName
      lastName
      linkedinLink
      avatar
      campaignRoles
      notificationCount
      contributionCount
      wallet
      expertCategory {
        id
        categoryId
        userId
        category {
          id
          name
          categoryType
        }
      }
      explorerCategory {
        id
        categoryId
        userId
        category {
          id
          name
          categoryType
        }
      }
      city {
        id
        country
        city
        lat
        lng
      }
    }
  }
`;

export const getSubscription = gql`
  query subscription($giftUserId: Int, $userId: Int, $subscriptionType: Int, $projectId: Int) {
    subscription(
      giftUserId: $giftUserId
      userId: $userId
      subscriptionType: $subscriptionType
      projectId: $projectId
    ) {
      id
      subscriptionType
      stripeId
      stripeStatus
      stripePlan
      quantity
      endsAt
      giftId
      giftUserId
      projectId
      description
      stripeData
    }
  }
`;

export const getSingleSubscription = gql`
  query singleSubscription($subscriptionId: Int!) {
    singleSubscription(subscriptionId: $subscriptionId) {
      stripeData
      subscriptionType
      stripeId
      stripeStatus
      stripePlan
      quantity
      endsAt
      createdAt
      giftId
      giftUserId
      projectId
      description
      project {
        id
        name
      }
    }
  }
`;

export const getContributionsForUser = gql`
  query getContributionsForUser($campaignId: Int, $userId: Int) {
    contributions(campaignId: $campaignId, userId: $userId) {
      id
      contributionUuid
      userId
      campaignId
      amount
      transactionId
      giftId
      createdAt
      campaign {
        id
        name
        fundingGoal
        startDate
        endDate
        project {
          id
          projectUuid
          subtitle
          logo
          name
          isRecusFiscaux
          recusFiscauxSignature
          city {
            city
            country
          }
          address {
            address1
            address2
            firstName
            lastName
            name
            zipCode
            addressType
            city {
              city
              country
            }
          }
        }
      }
      user {
        id
        userUuid
        name
        firstName
        lastName
        avatar
        title
        expertTitle
        description
        address {
          address1
          address2
          name
          firstName
          lastName
          zipCode
          addressType
          city {
            city
            country
          }
        }
      }
    }
  }
`;

export const getMyCampaignJob = gql`
  query getMyCampaignJob {
    myCampaignJob {
      id
      userId
      campaignId
      role
      postsCountUser
      user {
        email
        phone
      }
      campaign {
        id
        name
        project {
          projectUuid
          name
          logo
          city {
            city
            country
          }
        }
      }
    }
  }
`;

export const getActivity = gql`
  query getActivity($userId: Int!, $limit: Int, $offset: Int) {
    activity(userId: $userId, limit: $limit, offset: $offset) {
      id
      activityValue
      activityType
      activityFor
      createdAt
      campaign {
        id
        name
        project {
          id
          name
          logo
          projectUuid
          description
        }
      }
    }
  }
`;

export const getUser = gql`
  query getUser($uuid: String!) {
    getUser(uuid: $uuid) {
      id
      userUuid
      name
      title
      avatar
      campaignRoles
      city {
        city
        country
      }
    }
  }
`;

export const GenerateToken = gql`
  query generateToken {
    generateToken {
      bearer
    }
  }
`;

export const getUsers = gql`
  query getUsers($limit: Int, $offset: Int, $search: String) {
    users(limit: $limit, offset: $offset, search: $search) {
      id
      userUuid
      email
      name
      firstName
      lastName
      title
      avatar
      campaignRoles
      city {
        city
        country
      }
    }
  }
`;

export const getCompanies = gql`
  query getCompanies($limit: Int, $offset: Int, $q: String) {
    companies(limit: $limit, offset: $offset, q: $q) {
      id
      name
      logo
      address
      phone
      email
      users {
        id
        role
      }
    }
  }
`;

export const getTransactions = gql`
  query getTransactions($startDate: String!, $endDate: String!) {
    getTransactions(startDate: $startDate, endDate: $endDate) {
      id
      transactionUuid
      userId
      externalId
      anotherUserId
      contributionId
      projectId
      amount
      currency
      transactionType
      chargeType
      currentAmount
      status
      refundTransactionId
      subscriptionId
      createdAt
      updatedAt
      deletedAt
      giftId
      user {
        id
        userUuid
        name
        email
        firstName
        lastName
        avatar
        title
        stripeId
      }
      campaignsName
      projectsName
      operationName
      companyName
    }
  }
`;
