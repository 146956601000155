import { Category, CategoryData } from "./category";
import { City } from "./city";
import { Address, AddressData } from "./address";
import { Project } from "./project";

// REFACTOR BEGINS HERE //

import { CityTypes } from "./city";
import { AddressTypes } from "./address";
import { CategoryType } from "./category";

export namespace UserTypes {
  export interface User {
    id: number;
    userUuid: string;
    email: string;
    phone?: string;
    role: string;
    title?: string;
    name?: string;
    firstName?: string;
    lastName?: string;
    description?: string;
    instagramHandle?: string;
    linkedinLink?: string;
    position?: string;
    avatar?: string;
    helperDescription?: string;
    explorerHowLong?: number;
    explorerCanCityOut?: number;
    explorerHowLongType?: number;
    explorerStartAt?: number;
    expertTitle?: string;
    expertHowLong?: number;
    expertHowLongType?: number;
    expertMoreTimes?: number;
    isNewRepliesNotiff: boolean;
    isProjectUpdatesNotiff: boolean;
    isNewsletterNotiff: boolean;
    isFollowedNotiff: boolean;
    isNewSupportNotiff: boolean;
    isNewCommentNotiff: boolean;
    isCancelSupportNotiff: boolean;
    isNewQuestionNotiff: boolean;
    isPasswordSet: boolean;
    helperFilled: boolean;
    createdAt: number;
    updatedAt: number;
    verifiedAt?: number;
    lastNotificationReadAt?: number;
    wallet?: number;
    city?: CityTypes.City;
    explorerCity?: CityTypes.City;
    address: AddressTypes.Address[];
    explorerCategory: CategoryType.Explorer[];
    expertCategory: CategoryType.Expert[];
    campaignRoles: string[];
    favoriteCount: number;
    contributionCount: number;
    notificationCount: number;
    gifts: Gift[];
    companyId: number;
    company: {
      id: number;
      logo: string | null;
      name: string;
    };
  }

  export interface UpdateUser extends UpdateNotification, UpdatePassword {
    phone?: string;
    firstName?: string;
    lastName?: string;
    name?: string;
    title?: string;
    instagramHandle?: string;
    linkedinLink?: string;
    avatar?: string;
    position?: string;
    description?: string;
    helperDescription?: string;
    cityId?: number;
    explorerCityId?: number;
    explorerCanCityOut?: number;
    explorerHowLong?: number;
    explorerHowLongType?: number;
    explorerStartAt?: string;
    expertTitle?: string;
    expertHowLong?: number;
    expertHowLongType?: number;
    expertMoreTimes?: number;
    helperFilled?: boolean;
    expertCategory?: CategoryType.ExpertUpdate[];
    explorerCategory?: CategoryType.ExplorerUpdate[];
    address?: AddressTypes.AddressUpdate[];
    deletedAt?: number;
    lastNotificationReadAt?: number;
    lastFavReadAt?: number;
  }

  export interface UpdateNotification {
    isNewRepliesNotiff: boolean;
    isProjectUpdatesNotiff: boolean;
    isNewsletterNotiff: boolean;
    isFollowedNotiff: boolean;
    isNewSupportNotiff: boolean;
    isNewCommentNotiff: boolean;
    isCancelSupportNotiff: boolean;
    isNewQuestionNotiff: boolean;
  }

  export interface UpdatePassword {
    oldPassword: string;
    password: string;
  }

  export interface Gift {
    id: number;
    amount: number;
    wallet: number;
    user: {
      name: string;
    };
  }

  export type Update = UpdateUser | UpdateNotification | UpdatePassword;
}

// REFACTOR ENDS HERE //

export interface AuthUser {
  id: number;
  email: string;
  userUuid: string;
  name: string;
  role: string;
}

export interface ExplorerCategory {
  id: number;
  userId: number;
  categoryId: number;
  category: Category;
}

export class ExplorerCategoryData {
  userId: number;
  categoryId: number;
  category: Category;

  constructor(data: ExplorerCategory) {
    this.userId = data.userId;
    this.categoryId = data.categoryId;
    this.category = new CategoryData(data.category);
  }
}

export interface ExpertCategory {
  id: number;
  userId: number;
  categoryId: number;
  category: Category;
}

export class ExpertCategoryData {
  userId: number;
  categoryId: number;
  category: Category;

  constructor(data: ExplorerCategory) {
    this.userId = data.userId;
    this.categoryId = data.categoryId;
    this.category = new CategoryData(data.category);
  }
}

export interface User {
  id: number;
  email: string;
  phone: string | null;
  name: string | null;
  firstName: string | null;
  lastName: string | null;
  userUuid: string;
  role: string;
  title: string | null;
  linkedinLink: string | null;
  instagramHandle: string | null;
  avatar: string | null;
  description: string | null;
  helperDescription: string | null;
  city: City | null;
  explorerCity: City | null;
  explorerCanCityOut: boolean | null;
  explorerHowLong: number | null;
  explorerHowLongType: number | null;
  explorerStartAt: string | number | null;
  explorerCategory: Array<ExplorerCategory>;
  expertTitle: string | null;
  expertHowLong: number | null;
  expertHowLongType: number | null;
  expertMoreTimes: boolean | null;
  expertCategory: Array<ExpertCategory>;
  address: Array<Address>;
  provider: string;
  providerId: string;
  isNewRepliesNotiff: boolean;
  isProjectUpdatesNotiff: boolean;
  isNewsletterNotiff: boolean;
  isFollowedNotiff: boolean;
  isNewSupportNotiff: boolean;
  isNewCommentNotiff: boolean;
  isCancelSupportNotiff: boolean;
  isNewQuestionNotiff: boolean;
  isPasswordSet: boolean;
  deletedAt: string;
  wallet: number;
  notificationCount: number;
  campaignRoles: Array<string>;
  favoriteCount: number | null;
  contributionCount: number;
  lastNotificationReadAt: number | null;
  helperFilled: boolean;
}

export class UpdateUserData {
  id: number;
  phone: string | null;
  name: string | null;
  firstName: string | null;
  lastName: string | null;
  title: string | null;
  instagramHandle: string | null;
  avatar: string | null;
  description: string | null;
  helperDescription: string | null;
  cityId: number | null | undefined;
  address: Array<AddressData> | null;
  explorerCityId: number | null | undefined;
  explorerCanCityOut: number | null;
  explorerHowLong: number | null;
  explorerHowLongType: number | null;
  explorerStartAt: string | number | null;
  explorerCategory: Array<ExplorerCategoryData>;
  expertTitle: string | null;
  expertHowLong: number | null;
  expertHowLongType: number | null;
  expertMoreTimes: number | null;
  expertCategory: Array<ExpertCategoryData>;
  isNewRepliesNotiff: boolean;
  isProjectUpdatesNotiff: boolean;
  isNewsletterNotiff: boolean;
  isFollowedNotiff: boolean;
  isNewSupportNotiff: boolean;
  isNewCommentNotiff: boolean;
  isCancelSupportNotiff: boolean;
  isNewQuestionNotiff: boolean;
  lastNotificationReadAt: number | null;
  deletedAt: string;

  constructor(data: User) {
    const mapAddress = (item: any) => new AddressData(item);

    this.id = data.id;
    this.name = data.name;
    this.phone = data.phone;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.title = data.title;
    this.instagramHandle = data.instagramHandle;
    this.avatar = data.avatar;
    this.description = data.description;
    this.helperDescription = data.helperDescription;
    this.cityId = data.city?.id;
    this.address = data.address ? data.address.map(mapAddress) : null;
    this.explorerCityId = data.explorerCity?.id;
    this.explorerCanCityOut = data.explorerCanCityOut ? 1 : 0 || 0;
    this.explorerHowLong = data.expertHowLong;
    this.explorerHowLongType = data.explorerHowLongType;
    this.explorerStartAt = data.explorerStartAt;
    this.explorerCategory = data.explorerCategory?.map((f) => new ExplorerCategoryData(f));
    this.expertTitle = data.expertTitle;
    this.expertHowLong = data.expertHowLong;
    this.expertHowLongType = data.expertHowLongType;
    this.expertMoreTimes = data.expertMoreTimes ? 1 : 0 || 0;
    this.expertCategory = data.expertCategory?.map((f) => new ExpertCategoryData(f));
    this.isNewRepliesNotiff = data.isNewRepliesNotiff;
    this.isProjectUpdatesNotiff = data.isProjectUpdatesNotiff;
    this.isNewsletterNotiff = data.isNewsletterNotiff;
    this.isFollowedNotiff = data.isFollowedNotiff;
    this.isNewSupportNotiff = data.isNewSupportNotiff;
    this.isNewCommentNotiff = data.isNewCommentNotiff;
    this.isCancelSupportNotiff = data.isCancelSupportNotiff;
    this.isNewQuestionNotiff = data.isNewQuestionNotiff;
    this.lastNotificationReadAt = data.lastNotificationReadAt;
    this.deletedAt = data.deletedAt;
  }
}

export class UpdateUserMainData {
  name: string | null;
  description: string | null;
  cityId: number | null | undefined;

  constructor(data: User) {
    this.name = data.name;
    this.description = data.description;
    this.cityId = data.city?.id;
  }
}

export class UpdateUserHelperData {
  phone: string | null;
  firstName: string | null;
  lastName: string | null;
  title: string | null;
  instagramHandle: string | null;
  avatar: string | null;
  helperDescription: string | null;
  address: AddressData;

  constructor(data: User) {
    this.phone = data.phone;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.title = data.title;
    this.instagramHandle = data.instagramHandle;
    this.avatar = data.avatar;
    this.helperDescription = data.helperDescription;
    this.address = new AddressData(data.address[0]);
  }
}

export class UpdateUserExplorerData {
  explorerCityId: number | null | undefined;
  explorerCanCityOut: number | null;
  explorerHowLong: number | null;
  explorerHowLongType: number | null;
  explorerStartAt: string | null;
  explorerCategory: Array<ExplorerCategoryData>;

  constructor(data: User) {
    this.explorerCityId = data.explorerCity?.id;
    this.explorerCanCityOut = data.explorerCanCityOut ? 1 : 0 || 0;
    this.explorerHowLong = data.expertHowLong;
    this.explorerHowLongType = data.explorerHowLongType;
    this.explorerStartAt = getDateFromSomething(data?.explorerStartAt);
    this.explorerCategory = data.explorerCategory?.map((f) => new ExplorerCategoryData(f));
  }
}

const getDateFromSomething = (something: any) => {
  return typeof something === "number"
    ? new Date(something).toISOString().substring(0, 10) + " 00:00:00"
    : something.substr(0, 10) + " 00:00:00";
};

export class UpdateUserExpertData {
  expertTitle: string | null;
  expertHowLong: number | null;
  expertHowLongType: number | null;
  expertMoreTimes: number | null;
  expertCategory: Array<ExpertCategoryData>;

  constructor(data: User) {
    this.expertTitle = data.expertTitle;
    this.expertHowLong = data.expertHowLong;
    this.expertHowLongType = data.expertHowLongType;
    this.expertMoreTimes = data.expertMoreTimes ? 1 : 0 || 0;
    this.expertCategory = data.expertCategory?.map((f) => new ExpertCategoryData(f));
  }
}

export class UpdateUserNotiffData {
  isNewRepliesNotiff: boolean;
  isProjectUpdatesNotiff: boolean;
  isNewsletterNotiff: boolean;
  isFollowedNotiff: boolean;
  isNewSupportNotiff: boolean;
  isNewCommentNotiff: boolean;
  isCancelSupportNotiff: boolean;
  isNewQuestionNotiff: boolean;

  constructor(data: User) {
    this.isNewRepliesNotiff = data.isNewRepliesNotiff;
    this.isProjectUpdatesNotiff = data.isProjectUpdatesNotiff;
    this.isNewsletterNotiff = data.isNewsletterNotiff;
    this.isFollowedNotiff = data.isFollowedNotiff;
    this.isNewSupportNotiff = data.isNewSupportNotiff;
    this.isNewCommentNotiff = data.isNewCommentNotiff;
    this.isCancelSupportNotiff = data.isCancelSupportNotiff;
    this.isNewQuestionNotiff = data.isNewQuestionNotiff;
  }
}

export interface Transaction {
  id: number;
  transactionUuid: string;
  userId: number;
  externalId: string | null;
  anotherUserId: number | null;
  contributionId: number | null;
  projectId: number | null;
  amount: number;
  currency: string;
  transactionType: string;
  chargeType: string;
  currentAmount: number;
  status: number;
  refundTransactionId: number | null;
  subscriptionId: number;
  createdAt: number | null;
  updatedAt: number | null;
  deletedAt: number | null;
  createdAtDate: Date;
  updatedAtDate: Date;
  giftId: number;
  user: {
    id: number;
    userUuid: string;
    email: string;
    name: string;
    firstName: string;
    lastName: string;
    title: string;
    avatar: string;
    stripeId: string;
  };
  campaignsName: String;
  projectsName: String;
  operationName: String;
  companyName: String;
}

export class SingleCheckoutInput {
  amount: number;
  quantity: number | null;
  email: string | null;
  giftUserName: string | null;
  giftUserDesc: string | null;
  giftUserSendMe: boolean | null;
  name: string;
  stripePlanId: string | null;
  projectId: number | null;
  campaignId: number | null;
  otherUserId: number | null;
  isSubscription: boolean | null;
  isGift: boolean | null;
  isAnonymousContribution: boolean;
  interval: string | null;
  intervalCount: number | null;
  stopAt: number | null;
  contributeAmount: number | null;

  constructor(data: any) {
    this.amount = data.amount ? parseFloat(data.amount) : 0;
    this.name = data.name ? data.name : "";
    this.quantity = data.quantity ? data.quantity : null;
    this.email = data.email ? data.email : null;
    this.giftUserName = data.giftUserName ? data.giftUserName : null;
    this.giftUserDesc = data.giftUserDesc ? data.giftUserDesc : null;
    this.giftUserSendMe = data.giftUserSendMe ? data.giftUserSendMe : null;
    this.stripePlanId = data.stripePlanId ? data.stripePlanId : null;
    this.otherUserId = data.otherUserId ? data.otherUserId : null;
    this.isSubscription = data.isSubscription ? data.isSubscription : null;
    this.isGift = data.isGift ? data.isGift : null;
    this.isAnonymousContribution = data.isAnonymousContribution || false;
    this.projectId = data.projectId ? data.projectId : null;
    this.campaignId = data.campaignId ? data.campaignId : null;
    this.interval = data.interval ? data.interval : null;
    this.intervalCount = data.intervalCount ? data.intervalCount : null;
    this.stopAt = data.stopAt
      ? Math.floor(
          (data.stopAt instanceof Date ? data.stopAt.getTime() : new Date(data.stopAt).getTime()) /
            1000
        )
      : null;
    this.contributeAmount = data.contributeAmount ? parseFloat(data.contributeAmount) : 0;
  }
}

export interface StripeProduct {
  active: boolean;
  created: number;
  deleted: boolean;
  description: string;
  id: string;
  metadata: object;
  images: Array<string>;
  livemode: boolean;
  name: string;
  updated: number;
}

export interface StripePlan {
  active: boolean;
  billing_scheme: string;
  created: number;
  currency: number;
  deleted: boolean;
  id: string;
  livemode: boolean;
  metadata: object;
  nickname: string;
  product: StripeProduct;
  recurring: {
    interval: string;
    interval_count: number;
    usage_type: string;
  };
  type: string;
  unit_amount: number;
  unit_amount_decimal: string;
}

export class UpdateUserPasswordData {
  oldPassword: string;
  password: string;
  constructor(data: any) {
    this.password = data.password;
    this.oldPassword = data.oldPassword;
  }
}

export class UpdateUserAvatarData {
  avatar: string;
  constructor(data: any) {
    this.avatar = data.avatar;
  }
}

export class Subscription {
  id: number;
  subscriptionUuid: string;
  subscriptionType: number;
  stripeId: String;
  stripeStatus: String;
  stripePlan: String;
  quantity: number;
  trialEndsAt: Date;
  endsAt: Date;
  giftId: number;
  giftUserId: number;
  userId: number;
  project: Project | null;
  projectId: number;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
  stripeData: String;

  constructor(data: any) {
    this.id = data.id;
    this.subscriptionUuid = data.subscriptionUuid;
    this.subscriptionType = data.subscriptionType;
    this.stripeId = data.stripeId;
    this.stripeStatus = data.stripeStatus;
    this.stripePlan = data.stripePlan;
    this.quantity = data.quantity;
    this.trialEndsAt = data.trialEndsAt;
    this.endsAt = data.endsAt;
    this.giftId = data.giftId;
    this.giftUserId = data.giftUserId;
    this.userId = data.userId;
    this.projectId = data.projectId;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
    this.deletedAt = data.deletedAt;
    this.project = data.project ? new Project(data.project) : null;
    this.stripeData =
      typeof data.stripeData === "object" ? data.stripeData : JSON.parse(data.stripeData || "{}");
  }
}

export interface UserData {
  email: string;
  password: string;
}

export interface UserPaymentIntent {
  id: String;
  amount: number;
  currency: String;
  status: String;
  clientSecret: String;
}

export type GqlGetMeResponse = {
  getMe: User;
};

export type GqlUpdateUserResponse = {
  updateUser: User;
};

export type GqlProcessCheckoutResponse = {
  userPaymentIntent: Array<UserPaymentIntent>;
};

export type GqlGetSubscriptionResponse = {
  subscriptions: Array<Subscription>;
};

export type GqlGetUsersResponse = {
  users: Array<User>;
};

export type GqlGetTransactionResponse = {
  getTransactions: Array<Transaction>;
};
