import PropTypes from 'prop-types';
import {
  Fragment,
  useState
} from 'react';
import { useMutation } from '@apollo/client';
import { ToggleProjectFavoriteFromPost } from 'app/graphql/mutations';
import Menu, { MenuItem } from 'app/components/common/Menu';
import Avatar from 'app/components/common/Avatar';
import { POST_CONTENT_TYPES } from 'app/config';
import styles from './index.module.scss';

Header.propTypes = {
  className: PropTypes.string,
  post: PropTypes.object.isRequired,
  onChangeIsPostFavorite: PropTypes.func.isRequired,
  onRouteToProject: PropTypes.func.isRequired,
  isPostFavorite: PropTypes.bool.isRequired,
  isLight: PropTypes.bool.isRequired,
  extraMenuItems: PropTypes.array.isRequired,
};

export default function Header(props) {
  const {
    className,
    post,
    onChangeIsPostFavorite,
    onRouteToProject,
    isPostFavorite,
    isLight,
    extraMenuItems,
  } = props;

  const {
    postUuid,
    postType,
    content,
    campaign,
  } = post;

  const {
    project,
  } = campaign;

  const {
    id: projectId,
    name: projectName,
    logo: projectLogo,
    city: projectCity,
  } = project;

  const {
    city: _cityName,
    country
  } = projectCity || {};
  
  const description = (
    postType === POST_CONTENT_TYPES.TEXT
      ? ''
      : content || ''
  ).split('\n');
  const [isReported, setIsReported] = useState(false);
  let cityName = _cityName || '';

  cityName = `${cityName.slice(0, 1).toUpperCase()}${cityName.slice(1).toLowerCase()}`;

  const [toggleProjectFavorite, { loading: isProcessingFavorite }] = useMutation(
    ToggleProjectFavoriteFromPost,
    {
      variables: {
        projectId,
        postUuid,
      },
      onCompleted(data) {
        onChangeIsPostFavorite(data.toggleProjectFavoriteFromPost.isInterested);
      },
    }
  );
  const onReport = () => setIsReported(true);

  const renderExtraMenuItem = (_props, index) => {
    const { onClick: _onClick } = _props;
    const onClick = () => _onClick(post);

    return (
        <MenuItem
          key={index}
          {..._props}
          onClick={onClick} />
    );
  }

  const renderLine = (text, index) => {
    return (
      <Fragment key={index}>
        {text}
        <br />
      </Fragment>
    );
  };

  return (
    <div className={`${styles.RootContainer} ${className}`}>
      <div className={`${styles.header} ${isLight && styles.header__isLight}`}>
        <div className={styles.header_left} onClick={onRouteToProject}>
          <Avatar
            className={styles.avatar}
            isProject={true}
            src={projectLogo}
            alt={projectName}
            size={34} />

          <div>
            <div className={styles.name}>{projectName}</div>

            <div className={styles.city}>{`${cityName}, ${country}`}</div>
          </div>
        </div>

        <div className={styles.header_right}>
          <Menu className={styles.menu}>
            <MenuItem
              label={`${isPostFavorite ? 'Ne plus suivre' : 'Suivre'} ${projectName}`}
              isProcessing={isProcessingFavorite}
              isAuthRequired={true}
              onClick={toggleProjectFavorite}
            />
            <MenuItem
              label={isReported ? 'Signalé avec succès' : 'Signaler la publication'}
              isDisabled={isReported}
              onClick={onReport}
            />
            {extraMenuItems.map(renderExtraMenuItem)}
          </Menu>
        </div>
      </div>

      {!!description?.length && (
        <div className={styles.description}>
          {description.map(renderLine)}
        </div>
      )}
    </div>
  );
}

// const postTime = post.publishedAt ? post.publishedAt : post.createdAt;
// <div className={styles.time}>
//   {formatDistance(postTime, new Date(), { addSuffix: true })}
// </div>