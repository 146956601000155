import {
  useRef,
  useState,
  useEffect
} from 'react';
import {
  useUpload,
} from 'app/use/useUpload';
import {
  dataURLtoFile,
} from 'app/utils';

let __listener = null;

export function showDrawModal(callback) {
  __listener && __listener(callback);
}

export function useDrawModal() {
  const [isVisible, setIsVisible] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const refCanvas = useRef();
  const refCallback = useRef();
  const {
    isProcessing: isUploading,
    upload,
  } = useUpload();

  if (!isVisible) {
    refCanvas.current = null;
  }

  const onClose = () => {
    if (!isUploading) {
      setIsVisible(false);
    }
  };
  const onDrawEnd = () => {
    if (refCanvas.current) {
      setIsValid(!refCanvas.current.isEmpty());
    }
  };
  const onClear = () => {
    if (refCanvas.current) {
      refCanvas.current.clear();
      setIsValid(false);
    }
  };
  const onSubmit = async () => {
    if (refCanvas.current) {
      const imageDataURL = refCanvas.current.toDataURL('image/png');

      try {
        const {
          uri,
        } = await upload(
          dataURLtoFile(
            imageDataURL,
            'drawing',
          ),
        );

        refCallback.current(uri);
        onClose();
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    __listener = (callback) => {
      refCallback.current = callback;
      setIsVisible(true);
    };

    return () => {
      __listener = null;
      refCanvas.current = null;
      refCallback.current = null;
    };
  }, []);

  return {
    isVisible,
    isValid,
    isUploading,
    onClose,
    onSubmit,
    onClear,
    onDrawEnd,
    refCanvas,
  };
}
