import { useLazyQuery } from '@apollo/client';
import { getActivity, getUser } from 'app/graphql/queries';
import { useState, useEffect } from 'react';

let __listener = null;

export function showUserModal(userId) {
  __listener && __listener(userId);
}

// what?
// const getActivityDescriotor = (activity) => {
//   switch (activity.activityType) {
//     case 'contributions':
//       return `Made contributions of EUR ${activity.activityValue}`;

//     case 'campaign_users':
//       return `Helping as a ${activity.activityValue}`;

//     default:
//       return 'unknown';
//   }
// };

export function useUserModalProvider() {
  const [isVisible, setIsVisible] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [data, setData] = useState(null);

  const [getUserActivity] = useLazyQuery(getActivity, {
    onCompleted({ activity }) {
      setData({
        ...data,
        activity,
      });
      setIsProcessing(false);
    },
  });

  const [getUserData] = useLazyQuery(getUser, {
    onCompleted({ getUser }) {
      setData({
        ...data,
        ...getUser,
        desctiption: getUser.title,
        location: getUser.city ? getUser.city.city : '',
        campaignRoles: getUser.campaignRoles,
      });
      getUserActivity({ variables: { userId: getUser.id, limit: 10 } });
    },
  });

  const onClose = () => setIsVisible(false);

  useEffect(() => {
    __listener = (userId) => {
      setIsVisible(true);
      setIsProcessing(true);
      getUserData({ variables: { uuid: userId } });
    };

    return () => {
      __listener = null;
    };
  }, [getUserData]);

  return {
    isVisible,
    isProcessing,
    onClose,
    data,
  };
}
