import {
  Post,
} from "app/graphql/types";
import {
  getCampaignPosts as GetCampaignPosts,
} from 'app/graphql/queries';
import {
  PostType,
} from 'app/global';
import {
  RequestFabric,
  GraphQL,
} from 'app/api';
import {
  PostFactory,
} from 'app/factory';

class CampaignApi {

  public static getPosts(postType: PostType, campaignIds: number[]): Promise<Post[]> {
    return RequestFabric.graphQL<Post[]>(
      GetCampaignPosts,
      {
        offset: 0,
        limit: 100,
        campaignId: campaignIds,
        postBy: postType,
      },
      GraphQL.ResponseKey.Campaign.Posts,
      PostFactory.createPosts,
    );
  }

}

export default CampaignApi;

