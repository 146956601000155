// import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { Link } from 'react-router-dom';
import Button from 'app/components/common/Button';
import { ROUTES } from 'app/config/routes';
import styles from './index.module.scss';

const STORAGE_KEY = '@gdpr';

// Gdpr.propTypes = {};

export default function Gdpr() {
  const [isVisible, setIsVisible] = useState(false);

  const onRefuse = () => {
    localStorage.setItem(STORAGE_KEY, 'false');
    setIsVisible(false);
  };
  const onAccept = () => {
    localStorage.setItem(STORAGE_KEY, 'true');
    setIsVisible(false);
  };

  useEffect(() => {
    if (!localStorage.getItem(STORAGE_KEY)) {
      setIsVisible(true);
    }
  }, []);

  if (!isVisible) {
    return null;
  }

  return createPortal(
    <div className={styles.Root}>
      <div className={styles.body}>
        <div className={styles.title}>
          Le respect de votre vie privée est essentiel !
        </div>

        <div className={styles.text}>
          Nous utilisons des cookies pour améliorer votre expérience sur Kunbis,
          et vous montrer un contenu personnalisé.
          <br /><br />
          Consulter notre
          <Link to={ROUTES.PRIVACY.URL}>
            politique de confidentialité
          </Link>
        </div>
      </div>

      <div className={styles.footer}>
        <Button
          className={styles.buttonRefuse}
          label='Refuser'
          onClick={onRefuse} />

        <Button
          className={styles.buttonAccept}
          label='Accepter'
          onClick={onAccept} />
      </div>
    </div>,
    window.document.body
  );
}
