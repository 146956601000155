export interface Goal {
  id: number;
  name: string;
  color: string;
  icon: string;
  goalType: number;
}

export class GoalData {
  id: number;
  name: string;
  color: string;
  icon: string;
  goalType: number;

  constructor(data: Goal) {
    this.id = data.id;
    this.name = data.name;
    this.color = data.color;
    this.icon = data.icon;
    this.goalType = data.goalType;
  }
}

export type GqlGoalResponse = {
  goals: [Goal];
};
