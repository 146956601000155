import {
  useState,
  useRef,
  MutableRefObject
} from 'react';
import {
  Event, EventEmbedCampaign,
} from 'app/graphql/types';
import {
  EventApi,
} from 'app/api';
import {
  Amplify,
  PubSub,
} from 'aws-amplify';
import {
  AWSIoTProvider,
} from '@aws-amplify/pubsub/lib/Providers';

export interface UseEvent {
  loadingEvent: boolean;
  processingVote: boolean;
  event: Event | null;
  vote: (campaignId: number, campusId?: string) => Promise<Event | null>;
}

function useEvent(eventId: number): UseEvent {
  const refInitialized: MutableRefObject<boolean> = useRef<boolean>(false);
  const [loadingEvent, setLoadingEvent] = useState<boolean>(true);
  const [processingVote, setProcessingVote] = useState<boolean>(false);
  const [event, setEvent] = useState<Event | null>(null);
  const refEvent: MutableRefObject<Event | null> = useRef<Event | null>(null);

  refEvent.current = event;

  if (refInitialized.current === false) {
    refInitialized.current = true;

    EventApi
      .getEvent(eventId)
      .then(setEvent)
      .finally((): void => {
        setLoadingEvent(false);
      });

    Amplify.configure({
      Auth: {
        identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
        region: process.env.REACT_APP_REGION,
        userPoolId: process.env.REACT_APP_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
      },
    });

    Amplify.addPluggable(
      new AWSIoTProvider({
        aws_pubsub_region: process.env.REACT_APP_REGION,
        aws_pubsub_endpoint: `wss://${process.env.REACT_APP_MQTT_ID}.iot.${process.env.REACT_APP_REGION}.amazonaws.com/mqtt`,
      })
    );

    PubSub
      .subscribe(`$aws/things/kunbis-dev/shadow/${process.env.REACT_APP_MQTT_PREFIX}/event/updates`)
      .subscribe({
        next: ({ value: data }: any): void => {
          if (data.event_id === eventId) {
            if (refEvent.current) {
              try {
                const dataCampaigns = JSON.parse(data.campaigns);
                const findCampaign = (campaign: any): boolean => {
                  return campaign.campaign_id === data.campaign_id;
                };
                const dataCampaign: any | null = dataCampaigns.find(findCampaign);

                const mapCampaign = (eventEmbedCampaign: EventEmbedCampaign): EventEmbedCampaign => {
                  if (eventEmbedCampaign.campaign?.id === data.campaign_id || (eventEmbedCampaign.id === data.campaign_id && !eventEmbedCampaign.campaign)) {
                    if (dataCampaign) {
                      return {
                        ...eventEmbedCampaign,
                        votesCount: dataCampaign.votes_count || eventEmbedCampaign.votesCount,
                      };
                    }
                  }
  
                  return eventEmbedCampaign;
                };
  
                setEvent({
                  ...refEvent.current,
                  votesCount: data.votes_count || refEvent.current.votesCount,
                  campaigns: refEvent.current.campaigns.map(mapCampaign),
                });
              } catch (err) {
                console.log(err);
              }
            }
          }
        },
        error: (error) => {
          console.error(error);
        },
        // close: () => console.log('Done'),
      });
  }

  const vote = async (
    campaignId: number,
    campusId?: string,
  ): Promise<Event | null> => {
    let nextEvent: Event | null = null;

    setProcessingVote(true);
    
    try {
      await EventApi.vote(eventId, campaignId, campusId);

      nextEvent = await EventApi.getEvent(eventId);
      
      setEvent(nextEvent);
    } catch (err) {
      console.log(err);
    }

    setProcessingVote(false);

    return nextEvent;
  };

  return {
    loadingEvent,
    processingVote,
    event,
    vote,
  };
}

export default useEvent;