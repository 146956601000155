import { gql } from '@apollo/client';

export const getCategories = gql`
  query getCategories($categoryType: Int) {
    categories(categoryType: $categoryType) {
      id
      name
      categoryType
    }
  }
`;
