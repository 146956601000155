import {
  GetMe,
} from 'app/graphql/queries';
import {
  UpdateUser,
} from 'app/graphql/mutations';
import {
  UserTypes,
} from 'app/graphql/types/user';
import {
  UserFactory,
} from 'app/factory';
import {
  Payload,
  Endpoint,
  RequestFabric,
  GraphQL,
} from 'app/api';

class UserApi {

  public static login(payload: Payload.User.Login): Promise<unknown> {
    return RequestFabric.post(Endpoint.User.Login, payload);
  }

  public static loginAdmin(payload: Payload.User.LoginAdmin): Promise<unknown> {
    return RequestFabric.post(Endpoint.User.LoginAdmin, payload);
  }

  public static loginSocial(payload: Payload.User.LoginSocial): Promise<unknown> {
    return RequestFabric.post(Endpoint.User.LoginSocial, payload);
  }

  public static register(payload: Payload.User.Register): Promise<unknown> {
    return RequestFabric.post(Endpoint.User.Register, payload);
  }

  public static forgotPassword(payload: Payload.User.ForgotPassword): Promise<unknown> {
    return RequestFabric.post(Endpoint.User.ForgotPassword, payload);
  }

  public static resetPassword(payload: Payload.User.ResetPassword): Promise<unknown> {
    return RequestFabric.post(Endpoint.User.ResetPassword, payload);
  }

  public static logout(): Promise<unknown> {
    return RequestFabric.get(Endpoint.User.Logout);
  }

  public static getUser(): Promise<UserTypes.User> {
    return RequestFabric.graphQL<UserTypes.User>(
      GetMe,
      {},
      GraphQL.ResponseKey.User.GetMe,
      UserFactory.createUser,
    );
  }

  public static updateUser(userId: number, payload: UserTypes.Update): Promise<void> {
    return RequestFabric.graphQL(
      UpdateUser,
      {
        userId,
        data: payload,
      },
    );
  }

}

export default UserApi;