import Modal from 'app/components/common/Modal';
import { ShareLinkedinProps } from './types';
import styles from './index.module.scss';
import IconLinkedin from './linkedin.svg';
import { ChangeEvent, MutableRefObject, useRef, useState } from 'react';
import { UseProjectPublic, useProjectPublic } from 'app/use/useProject';
import { CircularProgress } from '@material-ui/core';
import { toastSuccess } from 'app/utils/toast';
import Button from '../common/Button';
import IconArrow from './arrow.svg';
import IconCopy from './copy.svg';

function ShareLinkedin(props: ShareLinkedinProps): JSX.Element {
  const [value, setValue] = useState<string>('');
  const refInitialized: MutableRefObject<boolean> = useRef<boolean>(false);

  const {
    project,
    loading,
  }: UseProjectPublic = useProjectPublic(props.projectUuid);

  if (refInitialized.current === false && project) {
    refInitialized.current = true;
    setValue(
`Grâce à @${props.shareName}, j'ai fait don de ${props.shareAmount}€ au projet @${project.name} sur la plateforme Kunbis.com. 

Cette année, ${props.shareName} a choisi de remplacer les goodies par des dons à des associations.
C'est une initiative responsable et engagée pour la durabilité et la solidarité. 
Je suis fier de contribuer à cette cause et j'encourage les entreprises et les individus à agir à leur échelle pour un monde meilleur. @Kunbis

#ImpactSocial #Solidarité #Kunbis #Responsabilité #ChangementPositif #Nogoodies #RSE
    `);
  }

  const onChangeValue = (event: ChangeEvent<HTMLTextAreaElement>): void => {
    setValue(event.target.value);
  };

  const copyText = (): void => {
    navigator.clipboard.writeText(value);
    toastSuccess('Copié avec succès');
  };
  
  return (
    <Modal
      className={styles.modal}
      onClose={props.onClose}>
      <div className={styles.root}>
        <img src={IconLinkedin} alt="LinkedIn" />

        <div className={styles.title}>
          Dévoilez votre impact en un clic sur Linkedin !
        </div>

        <div className={styles.subtitle}>
          <strong>Un petit geste, un grand impact !</strong> Montrez votre soutien sur LinkedIn en <strong>copiant et collant ce message</strong>
        </div>

        <div
          className={styles.copytext}
          onClick={copyText}>
          Copiez le message
          <img src={IconArrow} alt="" />
          <img src={IconCopy} alt="" />
        </div>

        {loading
          ? <CircularProgress />
          :  <textarea
              className={styles.textarea}
              value={value}
              onChange={onChangeValue} />
          }
        
        <a
          className={styles.button}
          href="https://www.linkedin.com"
          target="_blank"
          rel="noreferrer">
          Publier sur Linkedin
        </a>

        <Button onClick={props.onClose}>
          Plus tard
        </Button>
      </div>
    </Modal>
  );
}

export default ShareLinkedin;
