import { gql } from '@apollo/client';

export const CreateCategory = gql`
  mutation createCategory($data: NewCategoryData!) {
    createCategory(data: $data) {
      id
      name
      categoryType
    }
  }
`;
