import { ReactElement } from 'react';
import styles from './index.module.scss';
import { ModuleProps } from '../config';
import { SkemaColor } from 'app/skema/config';
import useSetBackground from 'app/skema/useSetBackground';

export default function Success(
  props: ModuleProps,
): ReactElement {
  const { setBackground } = props;

  useSetBackground(setBackground, SkemaColor.RedGradient);

  return (
    <div className={styles.root}>
      <span>
        Bonne chance pour tes challenges ! 
      </span>
    </div>
  );
}
