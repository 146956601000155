import { Payload } from 'app/api';
import OperationApi from 'app/api/module/Operation';
import { GiftCodeResponse } from 'app/graphql/types';
import Observable from 'app/util/Observable';
import { Page } from './config';
import { AuthenticationData, SignInResult, SignUpResult, authentication } from 'app/module';
import { events } from 'app/global';
import { EventName } from 'app/global/config';

class RedeemGiftDirector extends Observable {
  private static instance?: RedeemGiftDirector;
  public static getInstance(): RedeemGiftDirector {
    if (!RedeemGiftDirector.instance) {
      RedeemGiftDirector.instance = new RedeemGiftDirector();
    }

    return RedeemGiftDirector.instance;
  }

  private _giftCodeResponse: GiftCodeResponse | null = null;
  private _page: Page = Page.EnterCode;
  private _email: string = '';
  private acceptingGiftCode: boolean = false;

  public get giftCodeResponse(): GiftCodeResponse | null {
    return this._giftCodeResponse;
  }

  private set giftCodeResponse(giftCodeResponse: GiftCodeResponse | null) {
    this._giftCodeResponse = giftCodeResponse;
    this.acceptGiftCode();
    super.notify();
  }

  public get page(): Page {
    return this._page;
  }

  private set page(page: Page) {
    this._page = page;
    super.notify();
  }

  public get email(): string {
    return this._email;
  }

  private set email(email: string) {
    this._email = email;
    super.notify();
  }

  private constructor() {
    super();

    events().listenTo(
      EventName.Authenticated,
      (data: AuthenticationData): void => {
        if (data.authenticated) {
          this.acceptGiftCode();
        }
      }
    );
  }

  private async acceptGiftCode(): Promise<void> {
    if (this.isReadyToAcceptGiftCode() &&
        this.acceptingGiftCode === false) {
      this.acceptingGiftCode = true;

      try {
        await OperationApi.acceptGiftCode(this.giftCodeResponse?.code || '');

        this.page = Page.WhatNext;
      } catch (error) {
        console.log(error);
      } finally {
        this.acceptingGiftCode = false;
      }
    }
  }

  private isReadyToAcceptGiftCode(): boolean {
    if (this.giftCodeResponse?.code &&
        authentication().authenticated) {
      if (this.page !== Page.WhatNext) {
        return true;
      }
    }

    return false;
  }

  public async submitCode(
    payload: Payload.Operation.CheckGiftCodePayload,
  ): Promise<boolean> {
    try {
      const data: GiftCodeResponse = await OperationApi.checkGiftCode(payload);

      if (data) {
        this.email = payload.email;
        this.giftCodeResponse = data;

        if (authentication().authenticated !== true) {
          this.page = data.userExists
            ? Page.Login
            : Page.Registration;
        }
      }

      return true;
    } catch (error) {
      return false;
    }
  }

  public async signIn(password: string): Promise<boolean> {
    const result: SignInResult = await authentication().signIn({
      email: this.email,
      password,
    });

    return result === SignInResult.Success;
  }

  public async signUp(
    request: {
      firstName: string;
      lastName: string;
      password: string;
    }
  ): Promise<boolean> {
    const result: SignUpResult = await authentication().signUp({
      name: `${request.firstName} ${request.lastName}`,
      firstName: request.firstName,
      lastName: request.lastName,
      password: request.password,
      email: this.email,
    });

    return result === SignUpResult.Success;
  }

  public setPage(page: Page): void {
    this.page = page;
  }

}

export default (): RedeemGiftDirector => {
  return RedeemGiftDirector.getInstance();
};


