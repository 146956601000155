import {
  Campaign,
} from 'app/graphql/types';

class CampaignFactory {

  public static createCampaign(data: any): Campaign {
    return new Campaign(data);
  }

  public static createCampaigns(data: any[]): Campaign[] {
    if (Array.isArray(data)) {
      return data.map(CampaignFactory.createCampaign);
    }

    return [];
  }

}

export default CampaignFactory;
