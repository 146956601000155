import {
  RequestFabric,
  GraphQL,
  Payload,
} from 'app/api';
import {
  UserGiftContribution,
} from 'app/global/contribution/types';
import {
  GetUserGiftContributions,
} from 'app/graphql/queries/contribution.gql';
import ContributionFactory from 'app/factory/Contribution';
import { CreateInternalContribution } from 'app/graphql/mutations';

class ContributionApi {

  public static getUserGiftContributions(payload: Payload.Contribution.GetUserGift): Promise<UserGiftContribution[]> {
    return RequestFabric.graphQL<UserGiftContribution[]>(
      GetUserGiftContributions,
      payload,
      GraphQL.ResponseKey.Contribution.GetContribution,
      ContributionFactory.createUserGiftContributions,
    );
  }

  public static createInternalContribution(payload: Payload.Contribution.CreateInternalContribution): Promise<void> {
    return RequestFabric.graphQL(
      CreateInternalContribution,
      payload,
    );
  }

}

export default ContributionApi;

