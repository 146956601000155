import React from 'react';
import { Helmet } from 'react-helmet-async';
import { ROUTES } from 'app/config/routes';
import Button from 'app/components/common/Button';
import styles from './index.module.scss';
// Assets
import Image404 from './assets/404.svg';
import ImageFace from './assets/face.svg';

export default function Page404(props) {
  const { history } = props;

  const onRouteToHome = () => history.replace(ROUTES.HOME.URL);

  return (
    <>
      <Helmet>
        <title>Oh no... :(</title>
        <meta name="og:title" content="Oh no... :(" />
      </Helmet>

      <div className={styles.background} />

      <div className={styles.RootComponent}>
        <div className={styles.left}>
          <img src={Image404} alt="" />

          <div className={styles.title}>Oh, un problème ! :(</div>

          <div className={styles.text}>
            C’est peut-être une erreur d'orthographe dans l'URL ou la page a été supprimée.
          </div>

          <Button className={styles.button} onClick={onRouteToHome} label="Accueil" />
        </div>

        <div className={styles.right}>
          <img src={ImageFace} alt="" />
        </div>
      </div>
    </>
  );
}
