import { EventCampaign, SetBackground } from '../config';

export enum Step {
  Intro,
  Media,
  Success,
}

export interface ModuleConfig {
  nextStep: Step | null;
}

export interface ModuleProps extends SetBackground {
  onSubmit: () => void;
  campaigns: EventCampaign[];
  vote: (campaignId: number, campusId?: string) => void;
}

export const ModuleConfigMap: Record<Step, ModuleConfig> = {
  [Step.Intro]: {
    nextStep: Step.Media,
  },
  [Step.Media]: {
    nextStep: Step.Success,
  },
  [Step.Success]: {
    nextStep: null
  },
}
