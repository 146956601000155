import { ReactElement, useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import styles from './index.module.scss';

interface VideoProps {
  title: string;
  url: string;
  autoPlayEnabled: boolean;
  onToggled: () => void;
}

export default function Video(
  props: VideoProps,
): ReactElement {
  const { title, url, onToggled, autoPlayEnabled } = props;
  const [playing, setPlaying] = useState<boolean>(false);
  const { ref, inView } = useInView({
    threshold: 0.8,
  });
  const refVideo = useRef<HTMLVideoElement | null>(null);

  const toggleVideo = (): void => {
    if (refVideo.current) {
      const nextPlaying: boolean = !playing;
      
      if (nextPlaying) {
        refVideo.current.play();
      } else {
        refVideo.current.pause();
      }

      setPlaying(nextPlaying);
      onToggled();
    }
  };

  useEffect((): void => {
    if (autoPlayEnabled && refVideo.current) {
      if (inView) {
        refVideo.current.play();
        setPlaying(true);
      } else {
        refVideo.current.pause();
        setPlaying(false);
      }
    }
  }, [inView, autoPlayEnabled]);

  return (
    <div
      ref={ref}
      className={styles.root}>
      <video
        ref={refVideo}
        src={url}
        poster={url.replace('.mp4', '.jpg')}
        controls={false}
        muted={false}
        loop={true} />

      <div
        className={`${styles.play} ${playing && styles.hidden}`}
        onClick={toggleVideo} />

      <div className={styles.title}>
        {title}
      </div>
    </div>
  );
}
