import {
  Suspense,
} from 'react';
import {
  ROUTES_PUBLIC,
  ROUTES_PRIVATE,
  EnterpriseRoute,
} from 'app/config/routes';
import {
  Route,
  Switch,
} from 'react-router-dom';
import {
  Helmet,
} from 'react-helmet-async';
import {
  useAnalytics
} from './use/useAnalytics';
import {
  initializeProjects,
} from 'app/use/useProjects';
import {
  initializeCities,
} from 'app/use/useCities';
import {
  initializeCategories,
} from 'app/use/useCategories';
import {
  initializeGoals,
} from 'app/use/useGoals';
import {
  useAuthentication,
  AuthenticationData,
  user,
} from 'app/module';
import Loading from 'app/scenes/loading';
import AuthRequired from 'app/scenes/authRequired';
import NotFound from 'app/scenes/page404/components';
import HeaderNavigation from './components/common/HeaderNavigation';
import Auth from './components/common/Auth';
import PostModalProvider from './components/common/PostModal/Provider';
import DrawModalProvider from './components/common/DrawModal/Provider';
import UploadModalProvider from './components/common/UploadModal/Provider';
import UserModalProvider from './components/common/UserModal/Provider';
import CreditsModalProvider from './components/common/CreditsModal/Provider';
import ProjectCreateModalProvider from './components/common/CreateCampaignModal/Provider';
import LocationListener from './components/common/LocationListener';
import Gdpr from './components/common/Gdpr';
import Welcome from './components/common/Welcome';
import BackgroundProvider from './components/Background/provider';

let __isMounted = false;

export default function App(): JSX.Element {
  const {
    authenticated,
    initialized,
  }: AuthenticationData = useAuthentication();

  if (__isMounted === false) {
    __isMounted = true;

    initializeProjects();
    initializeCities();
    initializeCategories();
    initializeGoals();
  }

  useAnalytics();

  const renderRoute = (props: any): JSX.Element => {
    return (
      // @ts-ignore
      <Route
        key={props.path}
        exact={true}
        {...props} />
    );
  };

  const renderPrivateRoute = (props: any): JSX.Element => {
    return renderRoute({
      ...props,
      component: initialized === false
        ? Loading
        : authenticated === false
          ? AuthRequired
          : props.component
    });
  };

  return (
    <>
      <Helmet>
        <title>Changez le cours des choses</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="author" content="Kunbis" />
        <meta property="og:locale" content="fr_FR" />
        <meta property="og:title" content="Changez le cours des choses" />
        <meta
          property="og:description"
          content="Tu veux changer le cours des choses ? Nous aussi ! Kunbis a pour unique mission d'aider, accompagner et valoriser des actions qui changent le monde."
        />
        <meta property="og:url" content="https://www.kunbis.com/" />
        <meta property="og:site_name" content="Kunbis" />
        <meta property="og:image" content={`${window.location}/assets/project-share.png`} />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:title" content="Changez le cours des choses" />
        <meta
          property="twitter:description"
          content="Tu veux changer le cours des choses ? Nous aussi ! Kunbis a pour unique mission d'aider, accompagner et valoriser des actions qui changent le monde."
        />
        <meta property="twitter:image" content="https://www.kunbis.com/assets/project-share.png" />
        <meta
          property="description"
          content="Tu veux changer le cours des choses ? Nous aussi ! Kunbis a pour unique mission d'aider, accompagner et valoriser des actions qui changent le monde."
        />
      </Helmet>

      <BackgroundProvider />
      <HeaderNavigation />

      {authenticated === false && (
        <Auth />
      )}

      <Suspense fallback={<Loading />}>
        {/* @ts-ignore*/}
        <Switch>
          {user().isCompany()
            ? renderRoute(EnterpriseRoute.dashboard.routerProps)
            : <>
                {ROUTES_PRIVATE.map(renderPrivateRoute)}
                {ROUTES_PUBLIC.map(renderRoute)}
              </>
          }
          
          {/* @ts-ignore*/}
          <Route component={NotFound} />
        </Switch>
      </Suspense>

      <UserModalProvider />
      <CreditsModalProvider />
      <ProjectCreateModalProvider />
      <LocationListener />
      <PostModalProvider />
      <UploadModalProvider />
      <DrawModalProvider />
      <Gdpr />
      <Welcome />
    </>
  );
}
