import {
  Project,
  ProjectFile,
} from 'app/graphql/types';

class ProjectFactory {

  public static createProject(data: any): Project {
    return new Project(data);
  }

  public static createProjects(data: any[]): Project[] {
    if (Array.isArray(data)) {
      return data.map(ProjectFactory.createProject);
    }

    return [];
  }

  public static createFile(data: any): ProjectFile {
    return new ProjectFile(data);
  }

  public static createFiles(data: any[]): ProjectFile[] {
    if (Array.isArray(data)) {
      return data.map(ProjectFactory.createFile);
    }

    return [];
  }

}

export default ProjectFactory;
