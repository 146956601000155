import {
  useState,
  useEffect,
} from 'react';
import {
  Project,
} from 'app/graphql/types';
import {
  getProjects,
} from 'app/graphql/queries';
import {
  Cache
} from 'app/utils/Cache';
import {
  getUniqueId,
} from 'app/utils';
import {
  apolloClient,
} from 'app/api';

const __cacheKey = '@sk-projects';
const __listeners = {};

let __projects = Cache.get(__cacheKey, []);

function mapProject(item) {
  return new Project(item);
}

function useProjects() {
  const [projects, setProject] = useState(() => {
    return __projects.map(mapProject);
  });

  useEffect(() => {
    const key = getUniqueId();
    
    __listeners[key] = setProject;

    return () => {
      delete __listeners[key];
    };
  }, []);

  return {
    projects,
  };
}

function dispatchListeners(data) {
  __projects = data;

  Cache.set(__cacheKey, data);

  for (const key in __listeners) {
    __listeners[key](
      data.map(mapProject)
    );
  }
}

async function initializeProjects() {
  try {
    const {
      data: {
        projects: data
      }
    } = await apolloClient.query({
      query: getProjects,
    });

    dispatchListeners(data);
  } catch (err) {
    // 
  }
}

export {
  useProjects,
  initializeProjects,
};