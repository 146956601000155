import { ReactElement, useRef, useState } from 'react';
import { getUrlParams } from './utils';
import Header from './Header';
import Vote from './etov';
import Live from './live';
import Projects from './projects';
import { EventCampaign, RootModuleProps, RootPath, SkemaColor } from './config';
import styles from './index.module.scss';
import useEvent, { UseEvent } from 'app/scenes/votePreview/useEvent';
import SplashScreen from './splash';
import Home from './Home';

type Module = (props: RootModuleProps) => ReactElement;

function createEventCampaigns(data: unknown): EventCampaign[] {
  const campaigns: EventCampaign[] = [];

  if (Array.isArray(data)) {
    for (let i = 0; i < data.length; i++) {
      const item: any = data[i];

      campaigns[i] = {
        id: item?.id || 0,
        name: item?.campaignData?.campaignName || '',
        projectTitle: item?.campaignData?.projectName || '',
        description: item?.description || '',
        logo: item?.logo || '',
        media: item?.media?.length ? item?.media[0]?.path || '' : '',
        votesCount: item?.votesCount || 0,
      };
    }
  }

  return campaigns;
}

export default function Skema(): ReactElement {
  const [Component] = useState<Module>((): Module => {
    if (window.location.pathname.includes(RootPath.Projects)) {
      return Projects;
    }

    if (window.location.pathname.includes(RootPath.Live)) {
      return Live;
    }

    if (window.location.pathname.includes(RootPath.Vote)) {
      return Vote;
    }

    return Home;
  });
  const [background, setBackground] = useState<SkemaColor>(SkemaColor.Beige);

  const {
    eventId: eventIdUnknown,
  } = getUrlParams();

  const {
    event,
    vote,
  }: UseEvent = useEvent(parseInt(eventIdUnknown || '4', 10));

  const eventCampaigns: EventCampaign[] = createEventCampaigns(event?.campaigns);

  return (
    <>
      <div
        className={styles.background}
        style={{
          background,
        }} />

      <div className={styles.root}>
        <Header />
        <Component
          campaigns={eventCampaigns}
          setBackground={setBackground}
          vote={vote} />
      </div>

      <SplashScreen />
    </>
  );
}
