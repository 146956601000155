import {
  SignInResult,
  SignUpResult,
  ForgotPasswordResult,
  ResetPasswordResult,
} from './config';
import {
  user,
} from 'app/module';
import {
  UserApi,
  Payload,
} from 'app/api';
import Util from './util';
import useAuthentication from './useAuthentication';
import { events } from 'app/global';
import { EventName } from 'app/global/config';

export * from './types';
export * from './config';
export {
  useAuthentication,
};

class Authentication {
  private static instance?: Authentication;
  public static getInstance(): Authentication {
    if (!Authentication.instance) {
      Authentication.instance = new Authentication();
    }

    return Authentication.instance;
  }

  private _initialized: boolean = false;
  private _authenticated: boolean = false;

  public get initialized(): boolean {
    return this._initialized;
  }

  private set initialized(initialized: boolean) {
    this._initialized = initialized;
    this.notifyEvent();
  }

  public get authenticated(): boolean {
    return this._authenticated;
  }

  private set authenticated(authenticated: boolean) {
    this._authenticated = authenticated;
    this.notifyEvent();
  }

  private constructor() {
    this.authenticate();
  }

  private notifyEvent(): void {
    events().trigger(
      EventName.Authenticated,
      {
        initialized: this.initialized,
        authenticated: this.authenticated,
      },
    );
  }

  public async authenticate(): Promise<boolean> {
    if (await user().fetch() === true) {
      this.authenticated = true;
    }

    this.initialized = true;

    return this.authenticated;
  }

  public async signInSocial(props: Payload.User.LoginSocial): Promise<SignInResult> {
    try {
      await UserApi.loginSocial(props);

      this.authenticate();

      return SignInResult.Success;
    } catch (error: any) {
      return SignInResult.Failed;
    }
  }

  public async signIn(props: Payload.User.Login): Promise<SignInResult> {
    try {
      await UserApi.login(props);

      this.authenticate();

      return SignInResult.Success;
    } catch (error: any) {
      if (error?.status === 401) {
        Util.notify('Email ou mot de passe invalide');
      }

      return SignInResult.Failed;
    }
  }

  public async signInAsAdmin(props: Payload.User.LoginAdmin): Promise<SignInResult> {
    try {
      await UserApi.loginAdmin(props);

      this.authenticate();

      return SignInResult.Success;
    } catch (error: any) {
      return SignInResult.Failed;
    }
  }

  public async signUp(props: Payload.User.Register): Promise<SignUpResult> {
    try {
      await UserApi.register(props);

      this.authenticate();

      return SignUpResult.Success;
    } catch (error: any) {
      if (error?.type === 'USER_ALREADY_ADDED') {
        return SignUpResult.AlreadyAdded;
      }

      if (error?.type === 'USER_ALREADY_EXIST') {
        return SignUpResult.AlreadyExists;
      }

      Util.handleGenericError(error);

      return SignUpResult.Failed;
    }
  }

  public async forgotPassword(email: string): Promise<ForgotPasswordResult> {
    try {
      await UserApi.forgotPassword({
        email,
      });

      return ForgotPasswordResult.Success;
    } catch (error: any) {
      Util.handleGenericError(error);

      return ForgotPasswordResult.Failed;
    }
  }

  public async resetPassword(props: Payload.User.ResetPassword): Promise<ResetPasswordResult> {
    try {
      await UserApi.resetPassword(props);

      this.authenticate();

      return ResetPasswordResult.Success;
    } catch (error: any) {
      Util.handleGenericError(error);

      return ResetPasswordResult.Failed;
    }
  }

  public signOut(): void {
    UserApi
      .logout()
      .finally(() => {
        window.location.href = '/';
      });
  }

}

export default (): Authentication => {
  return Authentication.getInstance();
};
