import { gql } from "@apollo/client";

export const CreateTip = gql`
  mutation createTip($data: NewTipData!) {
    createTip(data: $data) {
      id
      tipUuid
    }
  }
`;

export const UpdateTip = gql`
  mutation updateTip($id: Int!, $data: TipData!) {
    updateTip(id: $id, data: $data) {
      id
      tipUuid
    }
  }
`;

export const CreateRequirement = gql`
  mutation createRequirement($data: NewRequirementData!) {
    createRequirement(data: $data) {
      id
      requirementUuid
    }
  }
`;

export const UpdateRequirement = gql`
  mutation updateRequirement($id: Int!, $data: RequirementData!) {
    updateRequirement(id: $id, data: $data) {
      id
      requirementUuid
    }
  }
`;

export const CreateStrategy = gql`
  mutation createStrategy($data: NewStrategyData!) {
    createStrategy(data: $data) {
      id
      strategyUuid
    }
  }
`;

export const UpdateStrategy = gql`
  mutation updateStrategy($id: Int!, $data: StrategyData!) {
    updateStrategy(id: $id, data: $data) {
      id
      strategyUuid
    }
  }
`;

export const UpdateWithdrawal = gql`
  mutation updateWithdrawal($id: Int!, $data: WithdrawalData!) {
    updateWithdrawal(id: $id, data: $data) {
      id
    }
  }
`;

export const CreateOfflineContribution = gql`
  mutation createOfflineContribution(
    $campaignId: Int!
    $amount: Float!
    $firstName: String
    $lastName: String
    $email: String!
    $startDate: String
  ) {
    createOfflineContribution(
      campaignId: $campaignId
      amount: $amount
      firstName: $firstName
      lastName: $lastName
      email: $email
      startDate: $startDate
    ) {
      id
      contributionUuid
    }
  }
`;

export const createCompany = gql`
  mutation createCompany($data: CompanyData!) {
    createCompany(data: $data) {
      id
    }
  }
`;
