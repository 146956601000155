import { gql } from '@apollo/client';

export const getAdminCampaignList = gql`
  query getAdminCampaigns($limit: Int, $offset: Int, $projectId: Int, $status: Int, $q: String) {
    adminCampaigns(limit: $limit, offset: $offset, projectId: $projectId, status: $status, q: $q) {
      id
      campaignUuid
      name
      status
      strategyId
      project {
        projectUuid
        name
        logo
        phone
      }
    }
  }
`;

export const getAdminProjectList = gql`
  query getAdminProjectList($limit: Int, $offset: Int, $status: Int, $q: String) {
    adminProjects(limit: $limit, offset: $offset, status: $status, q: $q) {
      id
      projectUuid
      name
      logo
      phone
      status
      isDraft
      visibilityDemandCount
    }
  }
`;

export const getStrategies = gql`
  query getStrategies($limit: Int, $offset: Int, $isScenario: Boolean, $q: String) {
    strategies(limit: $limit, offset: $offset, isScenario: $isScenario, q: $q) {
      id
      strategyUuid
      name
      startAt
      isScenario
      strategyDays {
        id
        whichDay
        whichTime
        itemType
        itemTypeId
        item
      }
    }
  }
`;

export const getTips = gql`
  query getTips($limit: Int, $offset: Int, $q: String) {
    tips(limit: $limit, offset: $offset, q: $q) {
      id
      tipUuid
      name
      url
      isGamification
      isDownload
      title
      comment
      tipType
    }
  }
`;

export const getRequirements = gql`
  query getRequirements($limit: Int, $offset: Int, $mediaType: String, $q: String) {
    requirements(limit: $limit, offset: $offset, mediaType: $mediaType, q: $q) {
      id
      requirementUuid
      name
      tagName
      tagColor
      isMendatory
      mediaType
      tips
      exampleUrl
    }
  }
`;

export const getAdminWithdrawals = gql`
  query getAdminWithdrawals($limit: Int, $offset: Int, $status: Int) {
    withdrawals(limit: $limit, offset: $offset, status: $status) {
      id
      amount
      status
      userId
      campaignId
      withdrawAt
      createdAt
      updatedAt
      isOffline
      campaign {
        id
        name
        status
        project {
          id
          name
          logo
          phone
        }
      }
    }
  }
`;
