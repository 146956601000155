import { gql } from '@apollo/client';

export const getGoals = gql`
  query getGoals($goalType: Int) {
    goals(goalType: $goalType) {
      id
      name
      color
      icon
      goalType
    }
  }
`;
