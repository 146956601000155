import { ReactElement, useState } from 'react';
import Card from './Card';
import Confirm from '../../Confirm';
import { ModuleProps } from '../config';
import styles from './index.module.scss';
import { EventCampaign } from 'app/alumni/config';
import { getUrlParams } from 'app/alumni/utils';
import useLanguage from 'app/alumni/useLanguage';
// import useSetBackground from 'app/skema/useSetBackground';

export default function Vote(
  props: ModuleProps,
): ReactElement {
  const { onSubmit, campaigns, vote } = props;
  const [selectedCard, setSelectedCard] = useState<EventCampaign | null>(null);
  const {
    campusId,
  } = getUrlParams();

  const { english } = useLanguage();

  // useSetBackground(setBackground, SkemaColor.RedGradient);

  const onConfirm = (): void => {
    if (selectedCard) {
      vote(selectedCard.id, campusId);
      onSubmit();
    }
  };

  const onAbort = (): void => {
    setSelectedCard(null);
  };

  const renderCard = (
    item: EventCampaign,
  ): ReactElement => {

    const onClick = (): void => {
      setSelectedCard(item);
    };

    return (
      <Card
        key={item.id}
        item={item}
        onClick={onClick} />
    );
  };

  return (
    <div className={styles.root}>
      <div className={styles.title}>
        {english
          ? 'YOUR VOTE MATTERS'
          : 'A TON VOTE !'
        }
      </div>

      <div className={styles.subtitle}>
        {english
          ? 'Click on the RSB Alumni Foundation project of your choice to vote.'
          : 'Clique sur le projet de la fondation RSB Alumni de ton choix pour voter'
        }
      </div>

      <div className={styles.campaigns}>
        {campaigns.map(renderCard)}
      </div>

      {selectedCard !== null && (
        <Confirm
          title={(
            english
              ? 'Confirm my vote and my 5€ donation'
              : 'Confirmer mon vote et mon don de 5 euros'
          )}
          onAbort={onAbort}
          onConfirm={onConfirm} />
      )}
    </div>
  );
}
