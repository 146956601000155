import styles from './index.module.scss';
import { HowToProps } from './types';
import { useState } from 'react';
import Button from 'app/components/common/Button';
import { ROUTES } from 'app/config/routes';
import ImageDesktopA from './a_desktop.jpg';
import ImageMobileA from './a_mobile.jpg';
import ImageDesktopB from './b_desktop.jpg';
import ImageMobileB from './b_mobile.jpg';
import ImageDesktopC from './c_desktop.jpg';
import ImageMobileC from './c_mobile.jpg';

function HowTo(props: HowToProps): JSX.Element {
  const [contentIndex, setContentIndex] = useState<number>(0);
  const content = [
    {
      imageDesktop: ImageDesktopA,
      imageMobile: ImageMobileA,
      text: 'Cliquez ici pour accéder à votre profil et votre suivi d’impact',
    },
    {
      imageDesktop: ImageDesktopB,
      imageMobile: ImageMobileB,
      text: 'Découvrez et suivez votre budget d’impact',
    },
    {
      imageDesktop: ImageDesktopC,
      imageMobile: ImageMobileC,
      text: 'Dépensez une partie ou la totalité de votre budget en scrollant puis en cliquant ici',
    },
  ];

  const next = (): void => {
    const nextIndex: number = contentIndex + 1;

    if (nextIndex >= content.length) {
      // @ts-ignore
      window.location = ROUTES.HOME.URL;
      return;
    }

    setContentIndex(nextIndex);
  };

  return (
    <div className={`${styles.root} ${props.className}`}>
      <img
        className={styles.imageDesktop}
        src={content[contentIndex].imageDesktop}
        alt="" />

      <img
        className={styles.imageMobile}
        src={content[contentIndex].imageMobile}
        alt="" />

      <div className={styles.text}>
        {content[contentIndex].text}
      </div>

      <Button
        className={styles.submit}
        onClick={next}>
        Ok
      </Button>
    </div>
  );
}

export default HowTo;
