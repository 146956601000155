import { OperationType } from 'app/global/config';
import {
  GiftCodeResponse,
  Operation,
  OperationGift,
} from 'app/graphql/types';

class OperationFactory {

  public static createOperations(data?: Operation[]): Operation[] {
    if (Array.isArray(data)) {
      return data.map(OperationFactory.createOperation);
    }

    return [];
  }

  public static createOperation(data?: Operation): Operation {
    return {
      id: data?.id || 0,
      gifts: data?.gifts.map(OperationFactory.createGift) || [],
      title: data?.title || '',
      operationType: data?.operationType || OperationType.Budget,
      budget: data?.budget || 0,
      numberOfPeople: data?.numberOfPeople || 0,
      startAt: data?.startAt || 0,
      expireAt: data?.expireAt ? data.expireAt * 1000 : 0,
      createdAt: data?.createdAt || 0,
    };
  }

  private static createGift(data?: any): OperationGift {
    return {
      id: data?.id || 0,
      acceptedUserId: data?.acceptedUserId || 0,
      contributionCount: data?.contributionCount || 0,
      email: data?.email || '',
      name: data?.name || data?.user?.name,
      status: data?.status || 0,
      wallet: data?.wallet || 0,
      code: data?.code || '',
      amount: data?.amount || 0,
    };
  }

  public static createGiftCodeResponse(data?: GiftCodeResponse): GiftCodeResponse {
    return {
      id: data?.id || 0,
      email: data?.name || '',
      name: data?.name || '',
      code: data?.code || '',
      amount: data?.amount || 0,
      userExists: data?.userExists || false,
      user: {
        name: data?.user?.name || '',
        avatar: data?.user?.avatar || '',
      },
    };
  }

}

export default OperationFactory;
