import { ReactElement } from 'react';
import styles from './index.module.scss';

interface ToggleProps {
  className?: string;
  leftChildren: ReactElement | string;
  rightChildren: ReactElement | string;
  value: boolean;
  onChange: (next: boolean) => void;
  width?: number;
  height?: number;
  sliderSize?: number;
  sliderColor?: string;
  padding?: number;
}

export default function Toggle(props: ToggleProps): ReactElement {
  const {
    className,
    value,
    onChange,
    leftChildren,
    rightChildren,
    width = 82,
    height = 42,
    sliderSize = 32,
    sliderColor = '#5A42EF',
    padding = 5,
  } = props;

  const left: boolean = value === false;
  const right: boolean = value === true;

  const onToggle = (): void => {
    onChange(!value);
  };

  return (
    <div
      className={`${styles.root} ${className}`}
      onClick={onToggle}>
      <div className={`${styles.left} ${left && styles.active}`}>
        {leftChildren}
      </div>

      <div
        className={`${styles.toggle} ${right && styles.active}`}
        style={{
          height,
          width,
        }}>
        <div
          className={`${styles.slider} ${right && styles.active}`}
          style={{
            height: sliderSize,
            width: sliderSize,
            backgroundColor: sliderColor,
            transform: `translateX(${left ? padding : width - sliderSize - padding}px)`
          }} />
      </div>

      <div className={`${styles.right} ${right && styles.active}`}>
        {rightChildren}
      </div>
    </div>
  );
}
