import { gql } from '@apollo/client';

export const getPosts = gql`
  query getPosts($limit: Int, $offset: Int, $campaignId: [Int!], $postType: Int, $postBy: String) {
    posts(
      limit: $limit
      offset: $offset
      campaignId: $campaignId
      postType: $postType
      postBy: $postBy
    ) {
      id
      postUuid
      content
      url
      postType
      likes
      tag
      commentsCount
      isInterested
      isLiked
      publishedAt
      requirementTag
      requirementColor
      createdAt
      user {
        id
        userUuid
        name
        firstName
        lastName
        avatar
      }
      city {
        city
        country
      }
      project {
        id
        projectUuid
        name
        subtitle
        logo
      }
      campaign {
        id
        campaignUuid
        description
        fundingGoal
        milestoneBreakdown
        fundingBreakdown
        startDate
        endDate
        name
      }
    }
  }
`;

export const getSinglePost = gql`
  query post($uuid: String!) {
    post(uuid: $uuid) {
      id
      postUuid
      content
      url
      postType
      likes
      tag
      commentsCount
      isInterested
      isLiked
      publishedAt
      requirementTag
      requirementColor
      createdAt
      user {
        id
        userUuid
        name
        firstName
        lastName
        avatar
      }
      city {
        city
        country
      }
      project {
        id
        projectUuid
        name
        subtitle
        logo
      }
      campaign {
        id
        campaignUuid
        description
        fundingGoal
        milestoneBreakdown
        fundingBreakdown
        startDate
        endDate
        name
      }
    }
  }
`;

export const getPostFeed = gql`
  query getPostFeed(
    $limit: Int
    $offset: Int
    $userId: Int!
    $feedType: Int!
    $projectId: Int
    $campaignIdx: [Int!]
  ) {
    postFeed(
      limit: $limit
      offset: $offset
      userId: $userId
      feedType: $feedType
      projectId: $projectId
      campaignIdx: $campaignIdx
    ) {
      id
      postUuid
      content
      url
      postType
      likes
      tag
      commentsCount
      isInterested
      isLiked
      publishedAt
      requirementTag
      requirementColor
      createdAt
      user {
        id
        userUuid
        name
        firstName
        lastName
        avatar
      }
      city {
        city
        country
      }
      project {
        id
        projectUuid
        name
        subtitle
        logo
      }
      campaign {
        id
        campaignUuid
        description
        fundingGoal
        milestoneBreakdown
        fundingBreakdown
        startDate
        endDate
        name
      }
    }
  }
`;

export const getCampaignPosts = gql`
  query getCampaignPosts(
    $limit: Int
    $offset: Int
    $campaignId: [Int!]
    $postType: Int
    $postBy: String
    $orderBy: String
  ) {
    campaignPosts(
      limit: $limit
      offset: $offset
      campaignId: $campaignId
      postType: $postType
      postBy: $postBy
      orderBy: $orderBy
    ) {
      id
      postUuid
      content
      url
      postType
      likes
      tag
      commentsCount
      isInterested
      isLiked
      publishedAt
      requirementTag
      requirementColor
      createdAt
      user {
        id
        userUuid
        name
        firstName
        lastName
        avatar
      }
      city {
        city
        country
      }
      project {
        id
        projectUuid
        name
        subtitle
        logo
      }
      campaign {
        id
        campaignUuid
        description
        fundingGoal
        milestoneBreakdown
        fundingBreakdown
        startDate
        endDate
        name
      }
    }
  }
`;

export const getCampaignPostsmanage = gql`
  query getCampaignPostsmanage(
    $limit: Int
    $offset: Int
    $campaignId: [Int!]
    $postType: Int
    $postBy: String
    $orderBy: String
  ) {
    campaignPostsManage(
      limit: $limit
      offset: $offset
      campaignId: $campaignId
      postType: $postType
      postBy: $postBy
      orderBy: $orderBy
    ) {
      id
      postUuid
      content
      url
      postType
      likes
      tag
      commentsCount
      isInterested
      isLiked
      publishedAt
      requirementTag
      requirementColor
      createdAt
      user {
        id
        userUuid
        name
        firstName
        lastName
        avatar
      }
      city {
        city
        country
      }
      project {
        id
        projectUuid
        name
        subtitle
        logo
      }
      campaign {
        id
        campaignUuid
        description
        fundingGoal
        milestoneBreakdown
        fundingBreakdown
        startDate
        endDate
        name
      }
    }
  }
`;
