import { Campaign } from './campaign';

export type PostFile = {
  name: string;
  path: string;
  mimeType: string;
  encoding: string;
  thumb: string | undefined;
};

export class Post {
  id: number;
  postUuid: string | null;
  content: string | null;
  url: Array<PostFile> | null;
  postType: number;
  postBy: string;
  tag: Array<string>;
  likes: number;
  interest: number;
  userId: number;
  campaignId: number;
  commentsCount: number;
  isInterested: boolean;
  isLiked: boolean;
  campaign: Campaign | null;
  publishedAt: Date | null;
  createdAt: Date;
  strategyDayId: number | null;
  deletedAt: Date | null;
  requirementTag: string | null;
  requirementColor: string | null;
  isDraft: boolean;

  constructor(data: any) {
    this.id = data.id;
    this.postUuid = data.postUuid;
    this.userId = data.userId;
    this.content = data.content;
    this.url = data.url
      ? data.url.map((p: string) =>
          typeof p === 'string' ? (JSON.parse(p) as PostFile) : (p as PostFile)
        )
      : null;
    this.tag = data.tag || [];
    this.postBy = data.postBy;
    this.likes = data.likes;
    this.interest = data.interest;
    this.postType = data.postType;
    this.campaignId = data.campaignId;
    this.commentsCount = data.commentsCount || 0;
    this.isInterested = data.isInterested || false;
    this.isLiked = data.isLiked || false;
    this.campaign = data.campaign
      ? new Campaign({
          ...data.campaign,
          project: data.project
            ? { ...data.project, city: data.city ? { ...data.city } : data.campaign?.project?.city }
            : data.campaign?.project,
        })
      : null;
    this.strategyDayId = data.strategyDayId;
    this.publishedAt = data.publishedAt ? new Date(data.publishedAt * 1000) : null;
    this.deletedAt = data.deletedAt ? new Date(data.deletedAt * 1000) : null;
    this.createdAt = new Date(data.createdAt * 1000);
    this.requirementTag = data.requirementTag;
    this.requirementColor = data.requirementColor;
    this.isDraft = data.isDraft || false;
  }
}

export class PostData {
  content: string | null;
  url: Array<string> | null;
  tag: Array<string> | null;
  postType: number;
  postBy: string;
  userId: number;
  campaignId: number;
  publishedAt: number | null;
  strategyDayId: number | null;
  requirementTag: string | null;
  requirementColor: string | null;
  isDraft: boolean;

  constructor(data: Post) {
    this.userId = data.userId;
    this.content = data.content;
    this.url = data.url ? data.url.map((p: PostFile) => JSON.stringify(p)) : null;
    this.tag = data.tag ? data.tag : null;
    this.postType = data.postType;
    this.postBy = data.postBy;
    this.campaignId = data.campaignId;
    this.strategyDayId = data.strategyDayId;
    this.publishedAt = data.publishedAt ? data.publishedAt.getTime() / 1000 : null;
    this.requirementTag = data.requirementTag;
    this.requirementColor = data.requirementColor;
    this.isDraft = data.isDraft || false;
  }
}

export class PostUpdateData {
  content: string | null;
  url: Array<string> | null;
  postType: number;
  postBy: string;
  publishedAt: number | null;
  deletedAt: number | null;
  requirementTag: string | null;
  requirementColor: string | null;
  isDraft: boolean;

  constructor(data: Post) {
    this.content = data.content;
    this.url = data.url ? data.url.map((p: PostFile) => JSON.stringify(p)) : null;
    this.postType = data.postType;
    this.postBy = data.postBy;
    this.deletedAt = data.deletedAt ? data.deletedAt.getTime() / 1000 : null;
    this.publishedAt = data.publishedAt ? data.publishedAt.getTime() / 1000 : null;
    this.requirementTag = data.requirementTag;
    this.requirementColor = data.requirementColor;
    this.isDraft = data.isDraft || false;
  }
}

export class ProjectFile {
  id: number;
  campaignId: number;
  postType: number;
  file: PostFile;
  projectId: number;
  createdAt: Date;

  constructor(data: any) {
    this.id = data.id;
    this.projectId = data.projectId;
    this.postType = data.postType;
    this.campaignId = data.campaignId;
    this.file = JSON.parse(data.file) as PostFile;
    this.createdAt = new Date(data.createdAt * 1000);
  }
}

export type GqlPostCreateResponse = {
  createPost: {
    id: number;
    postUuid: string;
  };
};

export type GqlPostsResponse = {
  posts: Array<Post>;
};

export type GqlPostFeedResponse = {
  postFeed: Array<Post>;
};

export type GqlTogglePostLikeResponse = {
  togglePostLike: Post;
};

export type GqlToggleProjectFavoriteFromPostResponse = {
  toggleProjectFavoriteFromPost: Post;
};
