import {
  useState,
  useEffect,
} from 'react';
import {
  authentication,
  AuthenticationData,
} from 'app/module';
import { events } from 'app/global';
import { EventName } from 'app/global/config';

function useAuthentication(): AuthenticationData {
  const [data, setData] = useState<AuthenticationData>(() => {
    return {
      initialized: authentication().initialized,
      authenticated: authentication().authenticated,
    };
  });
  
  useEffect(() => {
    return events().listenTo(
      EventName.Authenticated,
      setData,
    );
  }, []);

  return data;
}

export default useAuthentication;
