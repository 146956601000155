import { CSSProperties, ReactElement, useEffect, useRef } from 'react';
import styles from './index.module.scss';

interface VideoProps {
  className?: string;
  style?: CSSProperties;
  title: string;
  url: string;
  color: string;
  playing: boolean;
  onEnded: () => void;
}

export default function Video(
  props: VideoProps,
): ReactElement {
  const { className = '', style = {}, title, url, color, playing, onEnded } = props;
  const refVideo = useRef<HTMLVideoElement | null>(null);

  useEffect((): void => {
    if (refVideo.current) {
      if (playing) {
        refVideo.current.currentTime = 0;
        refVideo.current.play();
      } else {
        refVideo.current.pause();
      }
    }
  }, [playing]);

  return (
    <div
      className={`${styles.root} ${playing && styles.playing} ${className}`}
      style={{
        borderColor: color,
        backgroundColor: color,
        ...style,
      }}>
      <video
        ref={refVideo}
        src={url}
        poster={url.replace('.mp4', '.jpg')}
        autoPlay={false}
        onEnded={onEnded}
        controls={false}
        muted={false} />

      <div
        className={styles.title}
        style={{
          backgroundColor: color,
        }}>
        {title}
      </div>
    </div>
  );
}
