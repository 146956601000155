import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { getComments } from 'app/graphql/queries';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from 'app/components/common/Button';
import Avatar from 'app/components/common/Avatar';
import Input from '../Input';
import { formatDistanceToNowStrict } from 'app/utils/datefns';
import { showUserModal } from 'app/components/common/UserModal/useUserModal';
import styles from './index.module.scss';
// Assets
import IconResponses from './assets/responses.svg';
import IconLike from './assets/like.svg';

Comment.propTypes = {
  data: PropTypes.object.isRequired,
  responses: PropTypes.array,
  postId: PropTypes.number.isRequired,
  onLike: PropTypes.func.isRequired,
  onAddResponses: PropTypes.func,
  onCommentCreated: PropTypes.func.isRequired,
  isResponse: PropTypes.bool,
};

export default function Comment(props) {
  const {
    data,
    postId,
    isResponse,
    onLike,
    onAddResponses,
    onCommentCreated: _onCommentCreated,
  } = props;
  const {
    id: commentId,
    parentId,
    user,
    content,
    createdAt,
    likes,
    repliesCount,
    responses, // this one is manually mapped, see @onAddResponses
  } = data;
  const { avatar, name, userUuid } = user || {};
  const time = formatDistanceToNowStrict(new Date(createdAt * 1000), {
    addSuffix: true,
  });
  const [isInputVisible, setIsInputVisible] = useState(false);
  const [isProcessingLike, setIsProcessingLike] = useState(false);

  const [loadComments, { loading: isLoadingResponses }] = useLazyQuery(getComments, {
    variables: {
      offset: 0,
      limit: 100,
      commentId,
    },
    onCompleted: ({ comments }) => {
      onAddResponses(commentId, comments);
    },
  });

  const onComment = () => setIsInputVisible((prevState) => !prevState);
  const onCommentCreated = (data) => {
    _onCommentCreated(data);
    setIsInputVisible(false);
  };
  const onClickLike = async () => {
    setIsProcessingLike(true);
    await onLike(commentId);
    setIsProcessingLike(false);
  };
  const onClickUser = () => showUserModal(userUuid);

  const renderResponse = (item, index) => (
    <Comment
      key={item.id}
      postId={postId}
      isResponse={true}
      data={item}
      onLike={onLike}
      onCommentCreated={_onCommentCreated}
    />
  );

  return (
    <div className={styles.RootContainer}>
      <Avatar className={styles.avatar} src={avatar} alt={name} size={34} onClick={onClickUser} />

      <div className={styles.body}>
        <div className={styles.content}>
          <span className={styles.content_name} onClick={onClickUser}>
            {name}
          </span>

          {content}

          {likes > 0 && (
            <Button className={styles.likes} onClick={onClickLike}>
              {isProcessingLike ? (
                <CircularProgress className={styles.likes_loader} color="primary" />
              ) : (
                <img src={IconLike} alt="" />
              )}

              {likes}
            </Button>
          )}
        </div>

        <div className={styles.menu}>
          <Button
            className={styles.like}
            isDisabled={isProcessingLike}
            isAuthRequired={true}
            onClick={onClickLike}
          >
            {isProcessingLike ? (
              <CircularProgress className={styles.like_loader} size={20} color="primary" />
            ) : (
              'Soutenir'
            )}
          </Button>

          {!isResponse && (
            <>
              <div className={styles.menu_line}>-</div>
              <Button className={styles.comment} onClick={onComment} isAuthRequired={true}>
                Répondre
              </Button>
            </>
          )}

          <div className={styles.menu_line}>-</div>

          <div className={styles.time}>{time}</div>
        </div>

        {isInputVisible && (
          <Input
            className={styles.Input}
            postId={postId}
            commentId={parentId || commentId}
            onCommentCreated={onCommentCreated}
          />
        )}

        {!isResponse &&
          (responses?.length ? (
            <div className={styles.responses}>{responses.map(renderResponse)}</div>
          ) : (
            repliesCount > 0 && (
              <Button
                className={styles.responseCount}
                isDisabled={isLoadingResponses}
                onClick={loadComments}
              >
                {isLoadingResponses ? (
                  <CircularProgress
                    className={styles.responseCount_loader}
                    size={12}
                    color="primary"
                  />
                ) : (
                  <img src={IconResponses} alt="" />
                )}

                {`${repliesCount} réponse${repliesCount > 1 ? 's' : ''}`}
              </Button>
            )
          ))}
      </div>
    </div>
  );
}
