import { useState, useEffect } from 'react';

let __listener = null;

export function showCreditsModal() {
  __listener && __listener();
}

export function useCreditsModal() {
  const [isVisible, setIsVisible] = useState(false);

  const onClose = () => setIsVisible(false);

  useEffect(() => {
    __listener = () => setIsVisible(true);

    return () => {
      __listener = null;
    };
  }, []);

  return {
    isVisible,
    onClose,
  };
}
