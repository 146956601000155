import {
  useState,
  useEffect,
} from 'react';
import {
  getGoals,
} from 'app/graphql/queries';
import {
  apolloClient,
} from 'app/api';
import {
  getUniqueId,
} from 'app/utils';

const __listeners = {};

let __goals = [];

function useGoals() {
  const [goals, setGoals] = useState(__goals);

  useEffect(() => {
    const key = getUniqueId();
    
    __listeners[key] = setGoals;

    return () => {
      delete __listeners[key];
    };
  }, []);

  return {
    goals,
  };
}

function dispatchListeners(data) {
  __goals = data;

  for (const key in __listeners) {
    __listeners[key](data);
  }
}

async function initializeGoals(q) {
  try {
    const {
      data: {
        goals: data
      }
    } = await apolloClient.query({
      query: getGoals,
    });
  
    dispatchListeners(data);
  } catch (err) {
    // 
  }
}

export {
  useGoals,
  initializeGoals
};
