import React, { useEffect, useRef, useState } from 'react';
import styles from './index.module.scss';

export default function Error(props) {
  const { style, text, onClick } = props;
  const [isVisible, setIsVisible] = useState(false);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const refRoot = useRef();

  useEffect(() => {
    const { width, height } = refRoot.current.getBoundingClientRect();

    setWidth(width);
    setHeight(height);
    setIsVisible(true);
  }, []);

  return (
    <div
      ref={refRoot}
      className={styles.Root}
      style={{
        ...style,
        opacity: isVisible ? 1 : 0,
        marginTop: -height,
        marginLeft: -Math.floor(width / 2)
      }}
      onClick={onClick}>
      <div className={styles.arrow} />

      {text}
    </div>
  );
}
