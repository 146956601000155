import { gql } from '@apollo/client';
import axios from 'axios';
import { FileType } from 'app/graphql/types';

export const UploadRequest = gql`
  mutation uploadRequest($data: UploadRequest!) {
    uploadRequest(data: $data) {
      url
    }
  }
`;

export const upoadFile = (URL: string, file: FileType, metadata: Object): Promise<string> => {
  // let data = new FormData()

  // data.append('name', 'image')
  // data.append('file', file?.binary)

  const config = {
    headers: {
      'Content-Type': file?.type,
      'x-amz-acl': 'public-read',
      ...metadata,
    },
  };

  return new Promise((resolve, reject) => {
    axios
      .put(URL, file?.binary, config)
      .then(() => {
        const s3Url = URL.split('?')[0];
        // NOTE: replace s3 to CDN.
        resolve(
          s3Url.replace(
            process.env.REACT_APP_S3_ENDPOINT || '',
            process.env.REACT_APP_CDN_ENDPOINT || ''
          )
        );
      })
      .catch(reject);
  });
};
