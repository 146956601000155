import {
  useState,
  useEffect,
} from 'react';
import {
  useAuthModalCore,
} from './useAuthModal';
import {
  useLocation,
} from 'react-router';
import {
  toastSuccess,
} from 'app/utils/toast';
import {
  ROUTES,
} from 'app/config/routes';
import {
  authentication,
  SignInResult,
  SignUpResult,
  ForgotPasswordResult,
  ResetPasswordResult,
} from 'app/module';
import Social from './Social';
import ErrorMessage from './ErrorMessage';
import Form from './Form';
import Banner from './Banner';
import Modal from 'app/components/common/Modal';
import ForgotPassword from './ForgotPassword';
import styles from './index.module.scss';

function isConnectionPage(pathname) {
  return pathname === ROUTES.AUTH.URL;
}

export default function Auth() {
  const {
    pathname,
    search,
  } = useLocation();

  const { isVisible, setIsVisible, initialIsLogin } = useAuthModalCore();
  const [isLogin, setIsLogin] = useState(initialIsLogin);
  const [email, setEmail] = useState('');
  const [isForgotPasswordRequest, setIsForgotPasswordRequest] = useState(false);
  const [isForgotPasswordReset, setIsForgotPasswordReset] = useState(false);
  const [isForgotPasswordSuccess, setIsForgotPasswordSuccess] = useState(false);
  const [isProcessingForgotPassword, setIsProcessingForgotPassword] = useState(false);
  const [isProcessingForm, setIsProcessingForm] = useState(false);
  const [passwordResetParams, setPasswordResetParams] = useState(null);
  const [showUserAlreadyAddedMessage, setShowUserAlreadyAddedMessage] = useState(false);

  const hide = () => {
    setIsVisible(false);
  };

  const onModalRequestClose = () => {
    if (isConnectionPage(pathname)) {
      return;
    }

    hide();
  };

  const onForgotPassword = () => {
    setIsForgotPasswordRequest(true);
  };

  const onTryAgain = () => {
    setIsForgotPasswordSuccess(false);
  };

  const onSubmitForgotPassword = async (email, callback) => {
    setIsProcessingForgotPassword(true);

    const result = await authentication().forgotPassword(email);

    if (result === ForgotPasswordResult.Success) {
      setIsForgotPasswordSuccess(true);
    }

    setIsProcessingForgotPassword(false);
    callback && callback();    
  };

  const onSubmitPasswordReset = async (password) => {
    setIsProcessingForgotPassword(true);

    const result = await authentication().resetPassword({
      uid: passwordResetParams.uid,
      token: passwordResetParams.token,
      password,
    });

    if (result === ResetPasswordResult.Failed) {
      setIsProcessingForgotPassword(false);
    }
  };

  const onLogin = async (loginProps) => {
    setIsProcessingForm(true);

    const result = await authentication().signIn(loginProps);

    if (result === SignInResult.Failed) {
      setIsProcessingForm(false);
    }
  };

  // TODO: show registration success screen
  // import Success from 'app/components/common/Success';
  const onRegister = async (registerProps) => {
    setIsProcessingForm(true);

    const result = await authentication().signUp(registerProps);

    if (result !== SignUpResult.Success) {
      setIsProcessingForm(false);

      if (result === SignUpResult.AlreadyAdded) {
        setShowUserAlreadyAddedMessage(true);
      }
    }
  };

  useEffect(() => {
    setIsLogin(initialIsLogin);

    if (!isVisible) {
      setIsForgotPasswordRequest(false);
      setIsForgotPasswordSuccess(false);
      setIsForgotPasswordReset(false);
    }
  }, [isVisible, initialIsLogin]);

  useEffect(() => {
    if (typeof search === 'string') {
      const splitQuery = search.slice(1).split('&');
      let token;
      let uid;

      splitQuery.forEach((keyValue) => {
        const splitKeyValue = keyValue.split('=');

        if (splitKeyValue[0] === 'token') {
          token = splitKeyValue[1];
        } else if (splitKeyValue[0] === 'id') {
          uid = splitKeyValue[1];
        }
      });

      if (token && uid) {
        setPasswordResetParams({ token, uid });
        setIsForgotPasswordReset(true);
        setIsVisible(true);
      }
    }
  }, [search, setIsVisible]);

  const forgotPasswordCallback = () => {
    toastSuccess('Vous avez reçu un email pour configurer votre mot de passe !');
    setShowUserAlreadyAddedMessage(false);
    hide();
  };

  if (!isVisible) {
    return null;
  }

  return (
    <Modal
      isCustomBody={true}
      onClose={onModalRequestClose}>
      {isForgotPasswordRequest || isForgotPasswordReset
        ? <div className={styles.bodyForgotPassword}>
            <ForgotPassword
              isProcessing={isProcessingForgotPassword}
              isSuccess={isForgotPasswordSuccess}
              isReset={isForgotPasswordReset}
              onTryAgain={onTryAgain}
              onSubmitRequest={onSubmitForgotPassword}
              onSubmitReset={onSubmitPasswordReset} />
        </div>
      : <>
          {!isLogin && (
            <Banner
              className={styles.BannerLeft}
              isLogin={isLogin}
              setIsLogin={setIsLogin} />
          )}

          <div className={styles.body}>
            <div className={styles.body_content}>
              <h1 className={styles.title}>
                {isLogin
                  ? 'Nous sommes heureux de vous revoir'
                  : 'Bienvenue parmi nous'
                }
              </h1>

              <Social />

              <div className={styles.subTitle}>
                Ou utilisez votre Email
              </div>

              {showUserAlreadyAddedMessage
                ? <ErrorMessage
                    message="Vous avez déjà un rôle sur Kunbis ! Veuillez créer votre mot de passe"
                    onCancel={() => {
                      setShowUserAlreadyAddedMessage(false);
                    }}
                    onOk={() => {
                      onSubmitForgotPassword(email, forgotPasswordCallback);
                    }} />
                : <Form
                    isProcessing={isProcessingForm}
                    isLogin={isLogin}
                    setIsLogin={setIsLogin}
                    onLogin={onLogin}
                    onRegister={onRegister}
                    onForgotPassword={onForgotPassword}
                    email={email}
                    setEmail={setEmail} />
              }
            </div>
          </div>

          {isLogin && (
            <Banner
              className={styles.BannerRight}
              isLogin={isLogin}
              setIsLogin={setIsLogin} />
          )}
        </>
      }
    </Modal>
  );
}
