import { gql } from '@apollo/client';

export const getComments = gql`
  query getComments($limit: Int, $offset: Int, $postId: [Int!], $userId: Int, $commentId: Int) {
    comments(
      limit: $limit
      offset: $offset
      postId: $postId
      userId: $userId
      commentId: $commentId
    ) {
      id
      commentUuid
      content
      parentId
      mentions
      likes
      repliesCount
      createdAt
      user {
        id
        userUuid
        name
        firstName
        lastName
        avatar
      }
    }
  }
`;
