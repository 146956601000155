import {
  useState,
  useEffect,
} from 'react';
import {
  getCities,
} from 'app/graphql/queries';
import {
  CreateCity,
} from 'app/graphql/mutations';
import {
  apolloClient,
} from 'app/api';
import {
  getUniqueId,
} from 'app/utils';

const __listeners = {};

let __cities = [];

function useCities(props) {
  const {
    q,
  } = props || {};

  const [cities, setCities] = useState(__cities);

  let citiesFiltered = cities;

  if (q) {
    const qLowerCase = q.toLocaleLowerCase();

    citiesFiltered = [];

    for (let i = 0, ix = 0, len = cities.length; i < len; i++) {
      const item = cities[i];
      const {
        city,
      } = item;

      const citySearchable = (
        city
          .replace('-', ' ')
          .replace('[`´]', '\'')
          .toLocaleLowerCase()
      );
      
      if (citySearchable.indexOf(qLowerCase) > -1) {
        citiesFiltered[ix++] = item;
      }
    }
  }

  useEffect(() => {
    const key = getUniqueId();
    
    __listeners[key] = setCities;

    return () => {
      delete __listeners[key];
    };
  }, []);

  useEffect(() => {
    if (typeof q === 'string' && q.length >= 2) {
      const onTimeout = async () => {
        initializeCities(q);
      };

      const timeout = setTimeout(onTimeout, 320);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [q]);

  return {
    cities: citiesFiltered,
  };
}

async function createCity(nextCityData) {
  try {
    const {
      data: {
        createCity: data
      }
    } = await apolloClient.query({
      query: CreateCity,
      variables: {
        data: nextCityData
      },
    });

    dispatchListeners([
      data
    ]);
  } catch (err) {
    //
  }
};

function dispatchListeners(data) {
  for (let i = 0, lenI = data.length; i < lenI; i++) {
    const item = data[i];

    let isExisting = false;

    for (let x = 0, lenX = __cities.length; x < lenX; x++) {
      if (__cities[x].id === item.id) {
        isExisting = true;
        break;
      }
    }

    if (isExisting === false) {
      __cities.push(item);
    }
  }

  for (const key in __listeners) {
    __listeners[key]([
      ...__cities,
    ]);
  }
}

async function initializeCities(q) {
  try {
    const {
      data: {
        cities: data
      }
    } = await apolloClient.query({
      query: getCities,
      variables: {
        country: 'FR',
        q: q || '',
      },
    });
  
    dispatchListeners(data);
  } catch (err) {
    // 
  }
}

export {
  useCities,
  createCity,
  initializeCities
};
