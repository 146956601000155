import { ReactElement } from 'react';
import styles from './index.module.scss';
import Button from 'app/alumni/Button';
import useLanguage from 'app/alumni/useLanguage';

export default function Success(): ReactElement {

  const { english } = useLanguage();

  const goToResults = (): void => {
    window.location.href = '/alumni/live';
  };

  return (
    <div className={styles.root}>
      <div className={styles.checkIcon} />

      <span>
        {english
          ? 'YOUR VOTE HAS BEEN SUCCESSFULLY RECORDED'
          : 'TON VOTE A BIEN ÉTÉ PRIS EN COMPTE'
        }
        
      </span>

      <Button
        className={styles.submit}
        onClick={goToResults}>
        {english
          ? 'ACCESS RESULTS'
          : 'ACCÉDER AUX RESULTATS'
        }
      </Button>
    </div>
  );
}
