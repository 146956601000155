import { useState } from 'react';
import { UploadRequest } from 'app/graphql/mutations';
import { useMutation } from '@apollo/client';
import axios from 'axios';

export const uploadFile = (props) => {
  const { url, file, onProgress, metadata } = props;

  const onUploadProgress = (progressEvent) => {
    const { loaded, total } = progressEvent;

    if (onProgress) {
      onProgress(Math.round((loaded * 100) / total));
    }
  };

  const config = {
    headers: {
      'Content-Type': file?.type,
      'x-amz-acl': 'public-read',
      ...(metadata || {}),
    },
    onUploadProgress,
  };

  return new Promise((resolve, reject) => {
    axios
      .put(url, file?.binary, config)
      .then(() => {
        const s3Url = url.split('?')[0];

        // NOTE: replace s3 to CDN.
        resolve(
          s3Url.replace(
            process.env.REACT_APP_S3_ENDPOINT || '',
            process.env.REACT_APP_CDN_ENDPOINT || ''
          )
        );
      })
      .catch(reject);
  });
};

export function useUpload(props) {
  const { onChangeIsProgressing: _onChangeIsProgressing, onProgress = null } = props || {};
  const [uploadRequest] = useMutation(UploadRequest);
  const [isProcessing, setIsProcessing] = useState(false);

  const onChangeIsProgressing = (nextValue) => {
    setIsProcessing(nextValue);
    _onChangeIsProgressing && _onChangeIsProgressing(nextValue);
  };

  const upload = (file, metadata) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      const fileName = file?.name || '';
      const fileType = file?.type || '';
      const isMedia = fileType.indexOf('image') > -1 || fileType.indexOf('video') > -1;
      const path = isMedia ? 'videos/' : '';

      if (Array.isArray(metadata)) {
        const mapMetadata = (item) => ({
          ...item,
          value: `${item.value}`,
        });

        metadata = metadata.map(mapMetadata);
      } else {
        metadata = [];
      }

      onProgress && onProgress(0);
      onChangeIsProgressing(true);

      reader.onload = () => {
        const fileData = {
          path,
          name: fileName,
          mimeType: fileType,
          encoding: '',
          thumb: undefined,
          metadata,
        };

        file.binary = reader.result;

        uploadRequest({
          variables: { data: fileData },
        })
          .then(async ({ data }) => {
            if (data?.uploadRequest.url) {
              const metadataObject = {};

              for (let i = 0, len = metadata.length; i < len; i++) {
                const { key, value } = metadata[i];

                metadataObject[`x-amz-meta-${key}`] = value;
              }

              const uri = await uploadFile({
                url: data.uploadRequest.url,
                metadata: metadataObject,
                file,
                onProgress,
              });

              resolve({
                uri,
                file: {
                  ...fileData,
                  path: uri,
                  thumb: fileData.mimeType.match(/video\//)
                    ? uri
                        .replace('/videos/', '/processed-videos/')
                        .replace(
                          /(\.mp4|\.mp4|\.m4a|\.m4p|\.m4b|\.m4r|\.m4v|\.mov|\.MOV|\.webm|\.WEBM)/,
                          '.jpg'
                        )
                    : null,
                },
              });
            }
          })
          .catch(reject)
          .finally(() => {
            onChangeIsProgressing(false);
          });
      };

      if (typeof file === 'string') {
        reader.readAsDataURL(file);
      } else {
        reader.readAsArrayBuffer(file);
      }
    });
  };

  return {
    isProcessing,
    upload,
  };
}
