// import PropTypes from 'prop-types';
import {
  useState,
} from 'react';
import {
  useHistory,
} from 'react-router';
import {
  ROUTES,
} from 'app/config/routes';
import {
  Slider,
} from '@material-ui/core';
import {
  addCredit,
} from 'app/use/useCart';
import {
  useCreditsModal,
} from './useCreditsModal';
import Modal from 'app/components/common/Modal';
import InputText from 'app/components/common/Input/Text';
import Button from 'app/components/common/Button';
import styles from './index.module.scss';

export default function CreditsModalProvider() {
  const {
    isVisible,
    onClose
  } = useCreditsModal();

  const history = useHistory();

  const [isRechargeSelected, setIsRechargeSelected] = useState(false);

  const [amount, setAmount] = useState(10);
  const [customAmount, setCustomAmount] = useState('');

  const closeAndClear = () => {
    setIsRechargeSelected(false);
    onClose();
  };

  const onSelectRecharge = () => {
    setIsRechargeSelected(true);
  };

  const onRouteToDondation = () => {
    onClose();
    history.push(ROUTES.DONATION.URL());
  };

  const onChangeAmount = (e, nextAmount) => {
    setAmount(nextAmount);
    setCustomAmount('');
  };

  const onChangeCustomAmount = (value) => {
    const parsedAmount = parseInt(value);

    setCustomAmount(value);

    if (!isNaN(parsedAmount)) {
      setAmount(parsedAmount);
    }
  };

  const onSubmit = () => {
    addCredit({
      amount,
      contributeAmount: amount,
    });
    onClose();
    history.push(ROUTES.CHECKOUT.URL);
  };

  if (!isVisible) {
    return null;
  }

  const renderRecharge = () => {
    return (
      <>
        <div className={styles.yourDonation}>
          Définissez votre budget
        </div>

        <div className={styles.amount}>
          {amount} €
        </div>

        <Slider
          value={amount}
          min={1}
          max={500}
          onChange={onChangeAmount}
          aria-labelledby="continuous-slider" />

        <InputText
          className={styles.customInput}
          placeholder="Un autre montant ?"
          value={customAmount}
          onChangeNumber={onChangeCustomAmount} />

        <Button
          className={styles.submit}
          isDisabled={amount < 1}
          label="Ok !"
          onClick={onSubmit} />
      </>
    );
  };

  const renderSelection = () => {
    return (
      <>
        <div className={styles.title}>
          Rechargez votre tirelire
        </div>

        <div className={styles.buttonRouteContainer}>
          <Button
            className={styles.buttonRoute}
            label="Recharge ponctuelle"
            onClick={onSelectRecharge} />

          <Button
            className={styles.buttonRoute}
            label="Abonnement"
            onClick={onRouteToDondation} />
        </div>
      </>
    );  
  };  

  return (
    <Modal className={styles.Modal} onClose={closeAndClear}>
      {isRechargeSelected
        ? renderRecharge()
        : renderSelection()
      }
    </Modal>
  );
}
