import { gql } from '@apollo/client';

export const getCities = gql`
  query getCities($country: String, $limit: Int, $offset: Int, $q: String) {
    cities(country: $country, limit: $limit, offset: $offset, q: $q) {
      id
      city
      country
      lat
      lng
      iso2
    }
  }
`;
