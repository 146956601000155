import { gql } from '@apollo/client';

export const getUserProjects = gql`
  query getUserProjects($limit: Int, $offset: Int, $userId: Int) {
    userProjects(limit: $limit, offset: $offset, userId: $userId) {
      id
      projectUuid
      name
      logo
      status
      isDraft
      activeCampaignCount
    }
  }
`;

export const getProjects = gql`
  query getProjects($limit: Int, $offset: Int, $userId: Int, $q: String) {
    projects(limit: $limit, offset: $offset, userId: $userId, q: $q) {
      id
      projectUuid
      name
      subtitle
      logo
      status
      isDraft
      images
      videos
      goalProject {
        goalId
        projectId
        goal {
          id
          name
          color
          icon
          goalType
        }
      }
      categoryProject {
        category {
          id
          name
          categoryType
        }
      }
    }
  }
`;

export const getSingleProjects = gql`
  query getSingleProject($uuid: String!) {
    projectSmall(uuid: $uuid) {
      id
      projectUuid
      name
      logo
      projectType
      description
      subtitle
      website
      descriptionFounder
      interestCount
      isDraft
      isRecusFiscaux
      recusFiscauxSignature
      status
      images
      videos
      isInterested
      createdAt
      user {
        id
      }
      city {
        id
        city
        country
      }
      projectUsers {
        id
        userId
        projectId
        name
        avatar
        firstName
        lastName
        position
        role
        linkedinLink
      }
      goalProject {
        goalId
        projectId
        goal {
          id
          name
          color
          icon
          goalType
        }
      }
      categoryProject {
        id
        categoryId
        projectId
        category {
          id
          name
          categoryType
        }
      }
      address {
        id
        address1
        address2
        name
        firstName
        lastName
        phone
        dob
        state
        zipCode
        addressType
        userId
        projectId
        lat
        lng
        city {
          id
          city
          country
          iso2
        }
      }
    }
  }
`;
export const GetProjectById = gql`
  query getSingleProject($id: Int!) {
    projectById(id: $id) {
      id
      projectUuid
      name
      logo
      projectType
      description
      subtitle
      website
      descriptionFounder
      createdAt
      interestCount
      businessType
      businessName
      isDraft
      isRecusFiscaux
      recusFiscauxSignature
      status
      images
      videos
      isInterested
      user {
        id
      }
      city {
        id
        city
        country
      }
      projectUsers {
        id
        userId
        projectId
        name
        avatar
        firstName
        lastName
        position
        role
        linkedinLink
      }
      goalProject {
        goalId
        projectId
        goal {
          id
          name
          color
          icon
          goalType
        }
      }
      categoryProject {
        id
        categoryId
        projectId
        category {
          id
          name
          categoryType
        }
      }
      address {
        id
        address1
        address2
        name
        firstName
        lastName
        phone
        dob
        state
        zipCode
        addressType
        userId
        projectId
        lat
        lng
        city {
          id
          city
          country
          iso2
        }
      }
    }
  }
`;

export const getProjectUpdate = gql`
  query getSingleProject($uuid: String!) {
    project(uuid: $uuid) {
      id
      projectUuid
      email
      name
      logo
      projectType
      description
      subtitle
      website
      images
      videos
      descriptionFounder
      createdAt
      interestCount
      accountNumber
      accountBic
      accountIban
      businessType
      businessName
      businessRegistrationNumber
      isDraft
      isRecusFiscaux
      recusFiscauxSignature
      status
      createdAt
      stripeAccountStatus
      stripeDocumentFront
      stripeDocumentFrontId
      stripeDocumentBack
      stripeDocumentBackId
      stripeDocumentType
      user {
        id
      }
      city {
        id
        city
        country
      }
      projectUsers {
        id
        userId
        projectId
        name
        avatar
        firstName
        lastName
        position
        role
        linkedinLink
      }
      goalProject {
        goalId
        projectId
        goal {
          id
          name
          color
          icon
          goalType
        }
      }
      categoryProject {
        categoryId
        projectId
        category {
          id
          name
          categoryType
        }
      }
      address {
        id
        address1
        address2
        name
        firstName
        lastName
        phone
        dob
        state
        zipCode
        addressType
        userId
        projectId
        lat
        lng
        city {
          id
          city
          country
        }
      }
    }
  }
`;

export const getProjectFiles = gql`
  query getProjectFiles($projectId: Int!) {
    projectFiles(projectId: $projectId) {
      id
      campaignId
      postType
      file
      projectId
      createdAt
    }
  }
`;
