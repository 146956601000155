import PropTypes from 'prop-types';
import {
  ROUTES
} from 'app/config/routes';
import styles from './index.module.scss';
// Assets
import ImageA from './assets/image-a.png';
import ImageB from './assets/image-b.png';
import ImageC from './assets/image-c.png';
import ImageD from './assets/image-d.png';
import ImageE from './assets/image-e.png';

const __items = [
  {
    image: ImageA,
    title: 'A propos',
    description: 'On vous dit tout en une minute, deux clics, trois mouvements',
    route: ROUTES.LANDING_REPORTER.URL
  },
  {
    image: ImageB,
    title: 'Je suis une association',
    description: 'On s’engage à vos côtés pour déployer vos actions',
    route: ROUTES.LANDING_MAIN.URL
  },
  {
    image: ImageC,
    title: 'Je suis une entreprise',
    description: 'Engageons votre communauté, financez les solutions',
    route: ROUTES.LANDING_PROJECT.URL
  },
  {
    image: ImageD,
    title: 'Je suis un.e citoyen.ne',
    description: 'Je définis un budget impact et je change le cours des choses',
    route: ROUTES.DONATION.URL()
  },
  {
    image: ImageE,
    title: 'Offrir de l’impact',
    description: 'Un cadeau qui a du sens et qui rend le monde plus beau',
    route: ROUTES.GIFT.URL
  },
];

ImpactNav.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object.isRequired,
};

export default function ImpactNav(props) {
  const {
    className,
    history
  } = props;

  const renderItem = (item, index) => {
    const {
      image,
      title,
      description,
      route
    } = item;

    const onClick = () => {
      history.push(route);
    };

    return (
      <div
        key={index}
        className={styles.item}
        onClick={onClick}>
        <div>
          <img src={image} alt='' />
          <div />
        </div>

        <div>
          <strong>
            {title}
          </strong>

          <span>
            {description}
          </span>
        </div>
      </div>
    );
  };

  return (
    <div className={`${styles.root} ${className}`}>
      {__items.map(renderItem)}
    </div>
  );
}
