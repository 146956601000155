import { MouseEvent, ReactElement, useState } from 'react';
import styles from './index.module.scss';
import useLanguage from 'app/alumni/useLanguage';
import { EventCampaign } from 'app/alumni/config';

interface CardProps {
  item: EventCampaign;
  onClick: () => void;
}

export default function Card(
  props: CardProps,
): ReactElement {
  const { item, onClick } = props;
  const { logo, name, name_eng, projectTitle, projectTitle_eng, description, description_eng } = item;
  const [expanded, setExpanded] = useState<boolean>(false);

  const { english } = useLanguage();

  const expand = (event: MouseEvent<HTMLDivElement>): void => {
    event.preventDefault();
    event.stopPropagation();
    setExpanded(true);
  };

  return (
    <div
      className={styles.root}
      onClick={onClick}>
      <img className={styles.image} src={logo} alt={english ? name_eng : name} />

      <div className={styles.body}>
        <div className={styles.title}>
          {english
            ? name_eng
            : name
          }
        </div>

        <div className={styles.subtitle}>
          {english
            ? projectTitle_eng
            : projectTitle
          }
        </div>

        {expanded
          ? <div className={styles.description}>
              {english
                ? description_eng
                : description
              }
            </div>
          : <div
              className={styles.expand}
              onClick={expand}>
              {english
                ? 'Learn more'
                : 'En savoir plus '
              }
            </div>
        }
      </div>
      
    </div>
  );
}
