import {
  UserGiftContribution,
} from 'app/global/contribution/types';
import ProjectFactory from './Project';

class ContributionFactory {

  public static createUserGiftContributions(data?: UserGiftContribution[]): UserGiftContribution[] {
    if (Array.isArray(data)) {
      return data.map(ContributionFactory.createUserGiftContribution);
    }

    return [];
  }

  public static createUserGiftContribution(data?: UserGiftContribution): UserGiftContribution {
    const goalProject: {
      goal: {
        id: number
        color: string;
        icon: string;
        name: string;
      },
    }[] = [];
    
    if (data?.campaign?.project?.goalProject?.length) {
      for (let i = 0; i < data.campaign.project.goalProject.length; i++) {
        goalProject[i] = {
          goal: {
            id: data.campaign.project.goalProject[i].goal?.id || 0,
            color: data.campaign.project.goalProject[i].goal?.color || '',
            icon: data.campaign.project.goalProject[i].goal?.icon || '',
            name: data.campaign.project.goalProject[i].goal?.name || '',
          },
        };
      }
    }
    
    return {
      id: data?.id || 0,
      amount: data?.amount || 0,
      campaign: {
        id: data?.campaign?.id || 0,
        project: ProjectFactory.createProject(data?.campaign?.project),
      },
    };
  }

}

export default ContributionFactory;
