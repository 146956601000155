import {
  useState,
} from 'react';
import Text from 'app/components/common/Input/Text';
import styles from './index.module.scss';
import Button from 'app/components/common/Button';
import { LoginProps } from './types';
import director from '../director';
import Header from '../Header';
import SocialLogin from 'app/components/SocialLogin';

function Login(props: LoginProps): JSX.Element {
  const [password, setPassword] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const login = async (): Promise<void> => {
    setLoading(true);

    const success: boolean = await director().signIn(password);
  
    if (success === false) {
      setLoading(false);
    }
  };

  return (
    <div className={`${styles.root} ${props.className}`}>
      <Header />

      <SocialLogin
        className={styles.SocialLogin} />

      <div className={styles.orEmail}>
        Ou utilisez votre Email
      </div>

      <Text
        className={styles.input}
        value={director().email} />

      <Text
        className={styles.input}
        type="password"
        placeholder="Entrez un mot de passe"
        onChangeText={setPassword}
        value={password} />

      <Button
        className={styles.login}
        isDisabled={password === ''}
        isProcessing={loading}
        onClick={login}>
        Commencer
      </Button>
    </div>
  );
}

export default Login;
