import { Post } from './post';
import { User } from './user';

export class Comment {
  id: number;
  commentUuid: string;
  content: string | null;
  parentId: number | null;
  repliesCount: number;
  mentions: String;
  likes: number;
  userId: number;
  postId: number;
  createdAt: number;
  post: Post;
  user: User;

  constructor(data: any) {
    this.id = data.id;
    this.commentUuid = data.commentUuid;
    this.userId = data.userId;
    this.content = data.content;
    this.parentId = data.parentId;
    this.repliesCount = data.repliesCount || 0;
    this.mentions = JSON.parse(data.mentions);
    this.likes = data.likes;
    this.postId = data.postId;
    this.createdAt = data.createdAt;
    this.post = data.post;
    this.user = data.user;
  }
}

export class CommentData {
  content: string | null;
  parentId: number | null;
  mentions: String;
  userId: number;
  postId: number;

  constructor(data: any) {
    this.userId = data.userId;
    this.content = data.content;
    this.parentId = data.parentId;
    this.mentions = JSON.stringify(data.mentions);
    this.postId = data.postId;
  }
}

export type GqlCommentCreateResponse = {
  createComment: {
    id: number;
    commentUuid: string;
  };
};

export type GqlCommentsResponse = {
  comments: Array<Comment>;
};

export type GqlToggleCommentLikeResponse = {
  toggleCommentLike: Comment;
};
