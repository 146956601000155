// Assets
import IconMegaphone from './assets/navigation/megaphone.svg';
import IconMegaphoneActive from './assets/navigation/megaphone-colored.svg';
import IconPaperPlane from './assets/navigation/paper-plane.svg';
import IconPaperPlaneActive from './assets/navigation/paper-plane-colored.svg';
import IconEye from './assets/navigation/eye.svg';
import IconEyeActive from './assets/navigation/eye-colored.svg';
import IconNotifications from './assets/navigation/notifications.svg';
import IconNotificationsActive from './assets/navigation/notifications-colored.svg';
import IconMoney from './assets/navigation/money.svg';
import IconMoneyActive from './assets/navigation/money-colored.svg';
import IconCamera from './assets/navigation/camera.svg';
import IconCameraActive from './assets/navigation/camera-colored.svg';
import IconSettings from './assets/navigation/settings.svg';
import IconSettingsActive from './assets/navigation/settings-colored.svg';

export const NAVIGATION_TYPES = {
  FEED: 'fil-de-transparence',
  FEED_CONTRIBUTED: 'contribue',
  FEED_FOLLOWED: 'suivis',
  NOTIFICATIONS: 'notifications',
  CONTRIBUTIONS: 'mes-contributions',
  HELPER: 'mon-job-de-helper',
  SETTINGS: 'réglages',
};

export const NAVIGATION_ITEMS = [
  {
    id: NAVIGATION_TYPES.FEED,
    icon: IconMegaphone,
    iconActive: IconMegaphoneActive,
    label: 'Fil de transparence',
    items: [
      {
        id: NAVIGATION_TYPES.FEED_CONTRIBUTED,
        icon: IconPaperPlane,
        iconActive: IconPaperPlaneActive,
        label: 'Contribué',
      },
      {
        id: NAVIGATION_TYPES.FEED_FOLLOWED,
        icon: IconEye,
        iconActive: IconEyeActive,
        label: 'Suivis',
      },
    ],
  },
  {
    id: NAVIGATION_TYPES.NOTIFICATIONS,
    icon: IconNotifications,
    iconActive: IconNotificationsActive,
    label: 'Notifications',
  },
  {
    id: NAVIGATION_TYPES.CONTRIBUTIONS,
    icon: IconMoney,
    iconActive: IconMoneyActive,
    label: 'Mes contributions',
  },
  {
    id: NAVIGATION_TYPES.HELPER,
    icon: IconCamera,
    iconActive: IconCameraActive,
    label: 'Mon job de helper',
  },
  {
    id: NAVIGATION_TYPES.SETTINGS,
    icon: IconSettings,
    iconActive: IconSettingsActive,
    label: 'Réglages',
  },
];
