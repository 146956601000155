import {
  Campaign,
  Project,
  ProjectFile,
  ProjectUpdateData,
} from 'app/graphql/types';
import {
  UpdateProject,
  UpdateVisibilityDemand,
  ToggleProjectFavorite,
} from 'app/graphql/mutations';
import {
  GetProjectById,
  getProjectFiles as GetProjectFiles,
  getSingleProjects as GetPublicProjectByUuid,
  getUserProjects as GetUserProjects,
  getProjectUpdate as GetPrivateProjectByUuid
} from 'app/graphql/queries/project.gql';
import {
  getProjectCampaigns as GetProjectCampaigns,
} from 'app/graphql/queries/campaign.gql';
import {
  RequestFabric,
  GraphQL,
} from 'app/api';
import {
  ProjectFactory,
  CampaignFactory,
} from 'app/factory';

class ProjectApi {

  public static getById(projectId: number): Promise<Project> {
    return RequestFabric.graphQL<Project>(
      GetProjectById,
      { id: projectId },
      GraphQL.ResponseKey.Project.GetById,
      ProjectFactory.createProject,
    );
  }

  public static getPrivateByUuid(projectUuid: string): Promise<Project> {
    return RequestFabric.graphQL<Project>(
      GetPrivateProjectByUuid,
      { uuid: projectUuid },
      GraphQL.ResponseKey.Project.GetPrivateByUuid,
      ProjectFactory.createProject,
    );
  }

  public static getPublicByUuid(projectUuid: string): Promise<Project> {
    return RequestFabric.graphQL<Project>(
      GetPublicProjectByUuid,
      { uuid: projectUuid },
      GraphQL.ResponseKey.Project.GetPublicByUuid,
      ProjectFactory.createProject,
    );
  }

  public static getUserProjects(userId: number): Promise<Project[]> {
    return RequestFabric.graphQL<Project[]>(
      GetUserProjects,
      { userId },
      GraphQL.ResponseKey.Project.GetUserProjects,
      ProjectFactory.createProjects,
    );
  }

  public static getCampaigns(projectId: number): Promise<Campaign[]> {
    return RequestFabric.graphQL<Campaign[]>(
      GetProjectCampaigns,
      { projectId },
      GraphQL.ResponseKey.Project.GetCampaigns,
      CampaignFactory.createCampaigns,
    );
  }

  public static getFiles(projectId: number): Promise<ProjectFile[]> {
    return RequestFabric.graphQL<ProjectFile[]>(
      GetProjectFiles,
      { projectId },
      GraphQL.ResponseKey.Project.GetFiles,
      ProjectFactory.createFiles,
    );
  }

  public static updateProject(projectId: number, payload: ProjectUpdateData): Promise<void> {
    return RequestFabric.graphQL(
      UpdateProject,
      {
        projectId,
        data: payload
      },
    );
  }

  public static updateVisibilityDemand(projectId: number): Promise<void> {
    return RequestFabric.graphQL(
      UpdateVisibilityDemand,
      { projectId },
    );
  }

  public static toggleFavorite(projectId: number): Promise<void> {
    return RequestFabric.graphQL(
      ToggleProjectFavorite,
      { projectId },
    );
  }

}

export default ProjectApi;

