import { ReactElement, useEffect, useState } from 'react';
import Intro from './Intro';
import Campaign from './VoteCampaign';
import Success from './Success';
import { ModuleConfig, ModuleConfigMap, ModuleProps, Step } from './config';
import { RootModuleProps } from '../config';
import { authentication } from 'app/module';
import { register } from '../utils';
import Language from './Language';

type Module = (props: ModuleProps) => ReactElement;

const ModuleMap: Record<Step, Module> = {
  [Step.Language]: Language,
  [Step.Intro]: Intro,
  [Step.Vote]: Campaign,
  [Step.Success]: Success,
} as const;

export default function Vote(
  props: RootModuleProps,
): ReactElement {
  const { campaigns, vote, setBackground } = props;
  const [step, setStep] = useState<Step>(Step.Language);
  const Component: Module = ModuleMap[step];

  useEffect((): void => {
    authentication().authenticate().then((authenticated: boolean): void => {
      if (authenticated) {
        setStep(Step.Success);
      }
    });
  }, []);

  // preLoadVideos(campaigns);

  const onSubmit = (): void => {
    const config: ModuleConfig = ModuleConfigMap[step];

    if (config.nextStep) {
      if (config.nextStep === Step.Vote) {
        register();
      }

      setStep(config.nextStep);
    }
  };

  return (
    <Component
      onSubmit={onSubmit}
      campaigns={campaigns}
      setBackground={setBackground}
      vote={vote} />
  );
}
