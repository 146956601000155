// import PropTypes from 'prop-types';
import React from 'react';
import Modal from 'app/components/common/Modal';
import Button from 'app/components/common/Button';
import SignatureCanvas from 'react-signature-canvas';
import {
  useDrawModal
} from './useDrawModal';
import styles from './index.module.scss';

export default function DrawModalProvider() {
  const {
    isVisible,
    isValid,
    isUploading,
    onClose,
    onDrawEnd,
    onSubmit,
    onClear,
    refCanvas,
  } = useDrawModal();
  const onRef = (ref) => refCanvas.current = ref;

  if (!isVisible) {
    return null;
  }

  return (
    <Modal
      className={styles.Modal}
      onClose={onClose}>
      <SignatureCanvas
        ref={onRef}
        penColor='black'
        onEnd={onDrawEnd}
        canvasProps={{
          className: styles.SignatureCanvas,
          width: 310,
          height: 180
        }} />

      <div className={styles.footer}>
        <Button
          className={styles.cancel}
          isDisabled={isUploading}
          label="Annuler"
          onClick={onClose} />

        <Button
          className={styles.clear}
          isDisabled={isUploading}
          label="effacer"
          onClick={onClear} />

        <Button
          className={styles.submit}
          isProcessing={isUploading}
          isDisabled={!isValid}
          label="Ok !"
          onClick={onSubmit} />
      </div>
    </Modal>
  );
}
