import { ReactElement } from 'react';
import Button from '../../Button';
import { ModuleProps } from '../config';
import styles from './index.module.scss';
import { SkemaColor } from 'app/skema/config';
import useSetBackground from 'app/skema/useSetBackground';

export default function Intro(
  props: ModuleProps,
): ReactElement {
  const { onSubmit, setBackground } = props;

  useSetBackground(setBackground, SkemaColor.RedGradient);

  return (
    <div className={styles.root}>
      Cher(e) admissible,
      <div className={styles.spacing} />
      Et si tu soutenais l’un des projets associatifs à impact au sein de SKEMA Business School ?
      <div className={styles.spacing} />
      Un appel à projets a été lancé auprès de nos étudiants :
      <div className={styles.spacing} />
      Découvre en vidéo un aperçu et 
      rendez-vous aux espaces CHALLENGES afin de relever nos 5 grands défis.
      <div className={styles.spacing} />
      Ton vote est précieux, car à l’issue des épreuves orales, le projet le plus soutenu sera accompagné financièrement 
      grâce à toi !
      <div className={styles.spacing} />
      <strong>
        OSE, AGIS POUR LE MONDE, VIS LA DIVERSITE, ENGAGE-TOI DURABLEMENT, VISE L’EXCELLENCE
      </strong>
      <div className={styles.spacing} />
      Avec SKEMA Business School

      <Button
        className={styles.submit}
        onClick={onSubmit}>
        Compris
      </Button>
    </div>
  );
}
