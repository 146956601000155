import { ReactElement, useState } from 'react';
import Card from './Card';
import Confirm from '../../Confirm';
import { ModuleProps } from '../config';
import styles from './index.module.scss';
import { EventCampaign, SkemaColor } from 'app/skema/config';
import { getUrlParams } from 'app/skema/utils';
import useSetBackground from 'app/skema/useSetBackground';

export default function Vote(
  props: ModuleProps,
): ReactElement {
  const { onSubmit, campaigns, vote, setBackground } = props;
  const [selectedCard, setSelectedCard] = useState<EventCampaign | null>(null);
  const {
    campusId,
  } = getUrlParams();

  useSetBackground(setBackground, SkemaColor.RedGradient);

  const onConfirm = (): void => {
    if (selectedCard) {
      vote(selectedCard.id, campusId);
      onSubmit();
    }
  };

  const onAbort = (): void => {
    setSelectedCard(null);
  };

  const renderCard = (
    item: EventCampaign,
  ): ReactElement => {

    const onClick = (): void => {
      setSelectedCard(item);
    };

    return (
      <Card
        key={item.id}
        logo={item.logo}
        title={item.name}
        subtitle={item.projectTitle}
        description={item.description}
        onClick={onClick} />
    );
  };

  return (
    <div className={styles.root}>
      <div className={styles.title}>
        A ton vote !
      </div>

      <div className={styles.subtitle}>
        Clique sur l’association de ton choix
      </div>

      <div className={styles.campaigns}>
        {campaigns.map(renderCard)}
      </div>

      {selectedCard !== null && (
        <Confirm
          title='Confirmer mon vote'
          onAbort={onAbort}
          onConfirm={onConfirm} />
      )}
    </div>
  );
}
