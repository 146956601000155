import PropTypes from 'prop-types';
import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { CommentData } from 'app/graphql/types';
import { CreateComment } from 'app/graphql/mutations';
import { useAuthModal } from 'app/components/common/Auth/useAuthModal';
import {
  useUser,
} from 'app/module';
import Button from 'app/components/common/Button';
import Avatar from 'app/components/common/Avatar';
import styles from './index.module.scss';

CommentInput.propTypes = {
  className: PropTypes.string,
  postId: PropTypes.number.isRequired,
  commentId: PropTypes.number,
  onCommentCreated: PropTypes.func.isRequired,
};

export default function CommentInput(props) {
  const { className, postId, commentId, onCommentCreated } = props;
  const isResponse = !!commentId;

  const {
    user,
  } = useUser();
  
  const [comment, setComment] = useState('');
  const { showAuthModal } = useAuthModal();

  const [createComment, { loading: isProcessing }] = useMutation(CreateComment, {
    variables: {
      data: new CommentData({
        parentId: commentId,
        content: comment,
        mentions: [],
        userId: user?.id,
        postId,
      }),
    },
    onCompleted({ createComment }) {
      onCommentCreated(createComment);
      setComment('');
    },
  });

  const onChangeComment = ({ target }) => setComment(target.value);
  const onSubmitComment = () => {
    if (user?.id) {
      createComment();
    } else {
      showAuthModal();
    }
  };
  const onKeyDown = ({ key }) => key === 'Enter' && onSubmitComment();

  return (
    <div className={`${styles.RootContainer} ${className}`}>
      {!isResponse && (
        <Avatar
          className={styles.Avatar}
          src={user?.avatar}
          alt={user?.name}
          size={32} />
      )}

      <div className={styles.inputContainer}>
        <input
          placeholder="Votre commentaire"
          className={styles.input}
          disabled={isProcessing}
          onChange={onChangeComment}
          onKeyDown={onKeyDown}
          value={comment}
        />

        {!!comment && (
          <Button
            className={styles.submit}
            isProcessing={isProcessing}
            label="Envoyer"
            onClick={onSubmitComment}
          />
        )}
      </div>
    </div>
  );
}
