import { HeaderProps } from './types';
import styles from './index.module.scss';
import { GiftCodeResponse } from 'app/graphql/types';
import { useGiftCodeResponse } from '../useGiftCodeResponse';

function Header(props: HeaderProps): JSX.Element {
  const giftCodeResponse: GiftCodeResponse | null = useGiftCodeResponse();

  return (
    <div className={`${styles.root} ${props.className}`}>
      <div className={styles.title1}>
        Bienvenue sur Kunbis !
      </div>

      <div className={styles.title2}>
        Vous venez de recevoir un cadeau

        {giftCodeResponse?.user.name && (
          <span>
            de
            <strong>
              {giftCodeResponse.user.name}
            </strong>
          </span>
        )}
      </div>

      <div className={styles.title3}>
        (Ce cadeau <strong>change le monde</strong>, il est important de l’utiliser 😉)
      </div>
    </div>
  );
}

export default Header;
