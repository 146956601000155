import PropTypes from 'prop-types';
import React from 'react';
import { useCampaignProgress } from 'app/use/useCampaignProgress';
import styles from './index.module.scss';

Progress.propTypes = {
  className: PropTypes.string,
  campaign: PropTypes.object.isRequired,
};

export default function Progress(props) {
  const { className, campaign } = props;
  const { amount, percent, daysLeft } = useCampaignProgress(campaign);

  return (
    <div className={`${styles.RootContainer} ${className}`}>
      <div className={styles.header}>
        <div className={styles.header_text}>{amount}€</div>

        <div className={styles.header_text}>{percent}%</div>

        <div className={styles.header_text}>{daysLeft}</div>
      </div>

      <div className={styles.progress}>
        {percent > 0 && (
          <div
            className={styles.progress_bar}
            style={{ width: `${percent >= 100 ? 100 : percent}%` }}
          />
        )}
      </div>

      <div className={styles.footer}>
        <div className={styles.footer_text}>Engagé</div>

        <div className={styles.footer_text}>Financé</div>

        <div className={styles.footer_text}>Jours restants</div>
      </div>
    </div>
  );
}
