import { ReactElement } from 'react';
import Button from '../../Button';
import { ModuleProps } from '../config';
import styles from './index.module.scss';
import useLanguage from 'app/alumni/useLanguage';

export default function Intro(
  props: ModuleProps,
): ReactElement {
  const { onSubmit } = props;

  const { english } = useLanguage();

  if (english) {
    return (
      <div className={styles.root}>
        <div className={styles.title}>
          GIVING WINGS TO ALL AMBITIONS
        </div>

        <div className={styles.spacing} />
        As an extension of the school's social and societal commitment, and through its support and assistance programs dedicated to its students, the Rennes School of Business Foundation was established in 2022 under the aegis of the Fondation de France.
        <div className={styles.spacing} />
        Supported by its Alumni and benefactors, its primary mission is to make higher education accessible to young talents from all backgrounds. This Foundation aims to be a crossroads for exchanges, facilitating meetings between students, professionals, associations, and companies from various fields. All are united by the same ambition: to learn, to surpass themselves, to challenge conventions, and to commit to supporting a youth that drives change.
        <div className={styles.spacing} />
        
        <strong>
        Vote for one of the Foundation’s three projects and allocate your 5-euro donation.
        </strong>

        <Button
          className={styles.submit}
          onClick={onSubmit}>
          I VOTE FOR A PROJECT
        </Button>
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <div className={styles.title}>
        DONNONS DES AILES A TOUTES LES AMBITIONS
      </div>

      <div className={styles.spacing} />
      Dans le prolongement de l'engagement social et sociétal de l'école, et à travers ses programmes d'accompagnement et d'aide dédiés à ses étudiants, la Fondation Rennes School of Business a vu le jour en 2022 sous l'égide de la Fondation de France.
      <div className={styles.spacing} />
      Soutenue par ses Alumni et ses mécènes, sa mission première est de rendre l'enseignement supérieur accessible aux jeunes talents de tous horizons. Cette Fondation se veut un carrefour d'échanges, facilitant les rencontres entre étudiants, professionnels, associations et entreprises, issus de divers univers. Tous sont unis par une même ambition : apprendre, se dépasser, bousculer les codes, et s'engager pour soutenir une jeunesse porteuse de changement.
      <div className={styles.spacing} />
      
      <strong>
      Vote pour l'un des trois projets de la fondation et attribue ton don de 5 euros.
      </strong>

      <Button
        className={styles.submit}
        onClick={onSubmit}>
        JE VOTE POUR UN PROJET
      </Button>
    </div>
  );
}
