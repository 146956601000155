import { gql } from '@apollo/client';

export const CreatePost = gql`
  mutation createPost($data: PostData!) {
    createPost(data: $data) {
      id
      postUuid
    }
  }
`;

export const UpdatePost = gql`
  mutation updatePost($postId: Int!, $data: IgnoreNoneUpdatePost!) {
    updatePost(postId: $postId, data: $data) {
      id
      postUuid
    }
  }
`;

export const TogglePostLike = gql`
  mutation togglePostLike($postId: Int!) {
    togglePostLike(postId: $postId) {
      id
      postUuid
      content
      url
      postType
      likes
      tag
      commentsCount
      isInterested
      isLiked
      user {
        id
        userUuid
        name
        firstName
        lastName
        avatar
      }
      campaign {
        id
        campaignUuid
        description
        fundingGoal
        milestoneBreakdown
        fundingBreakdown
        startDate
        endDate
        name
        project {
          id
          projectUuid
          name
          subtitle
          logo
          city {
            city
            country
          }
        }
      }
    }
  }
`;

export const ToggleProjectFavoriteFromPost = gql`
  mutation toggleProjectFavoriteFromPost($projectId: Int!, $postUuid: Uuid!) {
    toggleProjectFavoriteFromPost(projectId: $projectId, postUuid: $postUuid) {
      id
      postUuid
      content
      url
      postType
      likes
      tag
      commentsCount
      isInterested
      isLiked
      user {
        id
        userUuid
        name
        firstName
        lastName
        avatar
      }
      campaign {
        id
        campaignUuid
        description
        fundingGoal
        milestoneBreakdown
        fundingBreakdown
        startDate
        endDate
        name
        project {
          id
          projectUuid
          name
          subtitle
          logo
          city {
            city
            country
          }
        }
      }
    }
  }
`;
