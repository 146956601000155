import {
  OperationVariables,
  ApolloQueryResult,
} from '@apollo/client';

export namespace GraphQL {

  export namespace ResponseKey {

    export enum User {
      GetMe = 'getMe',
    }

    export enum Campaign {
      Posts = 'campaignPosts',
    }

    export enum Project {
      GetById = 'projectById',
      GetPrivateByUuid = 'project',
      GetPublicByUuid = 'projectSmall',
      GetUserProjects = 'userProjects',
      GetCampaigns = 'campaigns',
      GetFiles = 'projectFiles',
    }

    export enum Payment {
      GetIntent = 'processCheckout',
    }

    export enum Event {
      GetEvents = 'events',
      GetEvent = 'event',
    }

    export enum Operation {
      CreateOperation = 'createOperation',
      GetOperations = 'operations',
      CheckGiftCode = 'checkCode',
    }

    export enum Contribution {
      GetContribution = 'contributions',
    }
  
    export type Type = (
      User |
      Campaign |
      Project |
      Payment |
      Event |
      Operation |
      Contribution
    );

  }

  export namespace Query {

    export type Payload = OperationVariables;
  
    export type Response = ApolloQueryResult<
      {
        [key: string]: unknown
      }
    >;

    export interface Factory<Result> {
      (data: any): Result;
    }

  }

}