import SocialLogin, {
  Props,
} from 'react-social-login';

function SocialButton(props: Props): JSX.Element {
  return (
    <button
      {...props}
      onClick={props.triggerLogin}>
      {props.children}
    </button>
  );
}

export default SocialLogin(SocialButton);