import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Menu from './Menu';
import Comment from './Comment';
import Input from './Input';
import { getComments } from 'app/graphql/queries';
import { ToggleCommentLike } from 'app/graphql/mutations';
import { useQuery, useMutation } from '@apollo/client';
import Button from 'app/components/common/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import styles from './index.module.scss';

Comments.propTypes = {
  className: PropTypes.string,
  postId: PropTypes.number.isRequired,
  postUuid: PropTypes.string.isRequired,
  postCommentCount: PropTypes.number.isRequired,
};

export default function Comments(props) {
  const { className, postId, postCommentCount: _postCommentCount } = props;
  const [postCommentCount, setPostCommentCount] = useState(_postCommentCount);
  const [comments, setComments] = useState([]);
  const [isShowAllComments, setIsShowAllComments] = useState(false);
  const [isCommentsVisible, setisCommentsVisible] = useState(false);
  const [toggleCommentLike] = useMutation(ToggleCommentLike);
  const isMoreCommentsToLoad = _postCommentCount > 3;
  const { loading: isLoadingComments } = useQuery(getComments, {
    variables: {
      postId,
      offset: isShowAllComments ? 3 : 0,
      limit: isShowAllComments ? 100 : 3,
    },
    onCompleted: ({ comments }) => {
      setComments((prevComments) => [...prevComments, ...comments]);
    },
  });

  const onShowMoreComments = () => setIsShowAllComments(true);
  const onToggleComments = () => setisCommentsVisible((prevState) => !prevState);
  const onAddResponses = (commentId, responses) => {
    setComments((prevComments) => {
      const mapComment = (item) => {
        if (item.id === commentId) {
          const prevResponses = item.responses || [];

          return {
            ...item,
            responses: [...responses, ...prevResponses],
          };
        }

        return item;
      };

      return prevComments.map(mapComment);
    });
  };
  const onCommentCreated = (newComment) => {
    if (newComment.parentId) {
      onAddResponses(newComment.parentId, [newComment]);
    } else {
      setComments((prevComments) => [newComment, ...prevComments]);
    }

    setPostCommentCount((prevState) => prevState + 1);
  };
  const onLike = async (commentId) => {
    return new Promise((resolve) => {
      toggleCommentLike({
        variables: {
          commentId,
        },
      })
        .then(({ data }) => {
          if (data?.toggleCommentLike) {
            setComments((prevComments) => {
              const { parentId } = data.toggleCommentLike;
              const mapComment = (item) => {
                if (item.id === commentId) {
                  return { ...item, ...data.toggleCommentLike };
                }

                return item;
              };
              const mapCommentResponse = (item) => {
                if (item.id === parentId) {
                  return { ...item, responses: item.responses.map(mapComment) };
                }

                return item;
              };

              return parentId ? prevComments.map(mapCommentResponse) : prevComments.map(mapComment);
            });
          }
        })
        .finally(resolve);
    });
  };

  const renderComment = (item) => (
    <Comment
      key={item.id}
      data={item}
      postId={postId}
      onAddResponses={onAddResponses}
      onCommentCreated={onCommentCreated}
      onLike={onLike}
    />
  );

  return (
    <div
      className={`${styles.RootContainer} ${className} ${
        isCommentsVisible ? styles.RootContainer_isCommentsVisible : ''
      }`}
    >
      <Menu
        {...props}
        postCommentCount={postCommentCount}
        onToggleComments={onToggleComments}
        isCommentsVisible={isCommentsVisible}
      />

      {isCommentsVisible && (
        <>
          {!isShowAllComments && isMoreCommentsToLoad && (
            <Button className={styles.showMoreComments} onClick={onShowMoreComments}>
              Voir les anciens commentaires
            </Button>
          )}

          <div className={styles.comments}>{comments.map(renderComment)}</div>

          {isLoadingComments && (
            <CircularProgress className={styles.loader} size={20} color="primary" />
          )}

          <div className={styles.line} />

          <Input className={styles.Input} postId={postId} onCommentCreated={onCommentCreated} />
        </>
      )}
    </div>
  );
}
