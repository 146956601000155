import PropTypes from 'prop-types';
import React from 'react';
import Modal from 'app/components/common/Modal';
import Avatar from 'app/components/common/Avatar';
import { showUserModal } from 'app/components/common/UserModal/useUserModal';
import styles from './index.module.scss';

Contributors.propTypes = {
  onClose: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
};

export default function Contributors(props) {
  const { onClose, data } = props;

  const renderItem = (item, index) => {
    const { user, amount } = item;
    const { avatar, name, userUuid } = user || {};
    const onClick = () => showUserModal(userUuid);

    return (
      <div key={index} className={styles.item}>
        <Avatar src={avatar} alt={name} size={100} onClick={onClick} />

        <div className={styles.item_name} onClick={onClick}>
          {name}
        </div>

        <div className={styles.item_amount}>
          Contribution de <span>{amount}€</span>
        </div>
      </div>
    );
  };

  return (
    <Modal onClose={onClose}>
      <div className={styles.title}>Les contributeurs</div>

      <div className={styles.list}>{data.map(renderItem)}</div>
    </Modal>
  );
}
