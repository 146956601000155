import styles from './index.module.scss';
import IconGoogle from './assets/google.svg';
import IconFacebook from './assets/facebook.svg';
import SocialButton from './SocialButton';
import { LoginSuccessResult, SocialLoginProps } from './types';
import { authentication } from 'app/module';

function SocialLogin(props: SocialLoginProps): JSX.Element {

  const onLoginSuccess = (result?: LoginSuccessResult): void => {
    if (result?._provider &&
        result?._profile?.id &&
        result?._profile?.name &&
        result?._profile?.email) {
      authentication().signInSocial({
        provider: result?._provider,
        provider_id: result?._profile.id,
        name: result?._profile.name,
        email: result?._profile.email,
      });
    }
  };

  const onLoginFailure = (result: any): void => {
    console.log(result);
  };

  return (
    <div className={`${styles.root} ${props.className}`}>
      <div className={styles.label}>
        Connexion rapide
      </div>

      <SocialButton
        className={styles.google}
        provider="google"
        appId="721617455090-pvhdt3vdimb95pp4vj819sk853ct7ne0.apps.googleusercontent.com"
        onLoginSuccess={onLoginSuccess}
        onLoginFailure={onLoginFailure}
        variant="contained">
        <img src={IconGoogle} alt="Google +" />
        Connexion avec Google
      </SocialButton>

      <SocialButton
        className={styles.facebook}
        provider="facebook"
        appId="207112394357141"
        onLoginSuccess={onLoginSuccess}
        onLoginFailure={onLoginFailure}
        variant="contained">
        <img src={IconFacebook} alt="Facebook" />
        Connexion avec Facebook
      </SocialButton>
    </div>
  );
}

export default SocialLogin;
