import {
  useEffect,
} from 'react';
import {
  createPortal,
} from 'react-dom';
import {
  Unsubscribe,
} from 'app/global';
import {
  ModalProps,
} from './types';
import styles from './index.module.scss';

function Modal(props: ModalProps): JSX.Element {

  useEffect((): Unsubscribe => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  return createPortal(
    <div
      className={`${styles.root} ${props.withoutHeaderPadding && styles.root__withoutPadding}`}>
      <div
        className={`${styles.overlayBlur} ${props.withoutHeaderPadding && styles.overlayBlur__withoutPadding}`} />

      <div
        className={styles.overlayTransparent}
        onClick={props.onClose} />

      {props.isCustomBody
        ? props.children
        : <div className={`${styles.body} ${props.className || ''}`}>
            {props.children}
          </div>
      }
    </div>,
    window.document.body
  );
}

export default Modal;
