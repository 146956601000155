import React, {
  useEffect,
  useState,
  useRef,
} from 'react';
import { useLazyQuery } from '@apollo/client';
import { getProjects } from 'app/graphql/queries';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useOutsideClick } from 'app/use/useOutsideClick';
import { ROUTES } from 'app/config/routes';
import styles from './index.module.scss';
// Assets
import IconSearh from './assets/search.svg';

export default function Search(props) {
  const {
    className,
    history,
    onClick,
    onChangeFocusState,
  } = props;
  const [items, setItems] = useState([]);
  const [q, setQ] = useState('');
  const [isItemsVisibe, setIsItemsVisibe] = useState(false);
  const [isFocus, setIsFocus] = useState(false);
  const refRoot = useRef();

  const hideItems = () => {
    setIsItemsVisibe(false);
  };
  const onClickInput = () => {
    setIsItemsVisibe(items.length > 0 || !!q);
    onClick && onClick();
  };
  const onChangeQ = (e) => {
    setQ(e.target.value);
  };
  const onFocus = () => {
    setIsFocus(true);
  };
  const onBlur = () => {
    setIsFocus(false);
  };

  const [searchProjects, { loading: isLoading }] = useLazyQuery(getProjects, {
    onCompleted: ({ projects }) => {
      setItems(projects);
      setIsItemsVisibe(true);
    },
  });

  useOutsideClick(refRoot, hideItems);

  useEffect(() => {
    let timeout;

    if (q) {
      timeout = setTimeout(() => {
        searchProjects({
          variables: {
            q,
            limit: 5,
            offset: 0,
          },
        });
      }, 360);
    } else {
      setIsItemsVisibe(false);
      setItems([]);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [searchProjects, q]);

  useEffect(() => {
    if (onChangeFocusState) {
      onChangeFocusState(isItemsVisibe || isFocus);
    }
  }, [onChangeFocusState, isItemsVisibe, isFocus]);

  const renderItem = (item) => {
    const {
      logo,
      name,
      id,
    } = item;
  
    const onClick = () => {
      setIsItemsVisibe(false);
      history.push(ROUTES.PROJECT_BY_NAME.URL(id, {
        projectName: name,
      }));
    };

    return (
      <div
        key={id}
        className={styles.item}
        onClick={onClick}>
        <img src={logo} alt='' />

        <div className={styles.itemName}>
          {name}
        </div>
      </div>
    );
  };

  return (
    <div
      ref={refRoot}
      className={`${styles.Root} ${className}`}>
      <div className={styles.icon}>
        {isLoading
          ? <CircularProgress size={18} />
          : <img src={IconSearh} alt='' />
        }
      </div>

      <input
        className={`${styles.input} ${(isFocus || q !== '') && styles.input__isFocus}`}
        placeholder={isFocus ? 'Trouver un projet' : 'Tous les projets'}
        spellCheck={false}
        value={q}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={onChangeQ}
        onClick={onClickInput} />

      {isItemsVisibe && (
        <div className={styles.results}>
          {items.length === 0
            ? <div className={styles.empty}>
              Ce projet n'est pas encore enregistré sur Kunbis
            </div>
            : items.map(renderItem)
          }
        </div>
      )}
    </div>
  );
}

// <div className={`${styles.search} ${isSearchActive ? styles.search__isActive : ''}`}>
//   <div className={styles.search_icon}>
//     <img src={IconSearch} alt="" />
//   </div>
//
//   <Select
//     className={styles.search_input}
//     placeholder="Trouver un projet"
//     emptyChildren="Ce projet n'est pas enregistré sur Kunbis"
//     options={options}
//     onChange={onRouteToProject}
//     removeStyle={true}
//     loading={isLoading}
//     values={[]}
//     dropdownItemPropsParser={dropdownItemPropsParser}
//     searchFn={onChangeSearch}
//     onDropdownOpen={onFocus}
//     onDropdownClose={onBlur}
//     closeOnSelect={true}
//   />
// </div>
