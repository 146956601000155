import { useState, useEffect } from 'react';

let __listener = null;

export function showCreateCampaignModal(projectId) {
  __listener && __listener(projectId);
}

export function useCreateCampaignModal() {
  const [isVisible, setIsVisible] = useState(false);
  const [projectId, setProjectId] = useState(false);

  const onClose = () => setIsVisible(false);

  useEffect(() => {
    __listener = (pId) => {
      setProjectId(pId);
      setIsVisible(true);
    };

    return () => {
      __listener = null;
    };
  }, []);

  return {
    isVisible,
    projectId,
    onClose,
  };
}
