import { useState, useEffect, useRef } from 'react';
import { useUpload } from 'app/use/useUpload';

let __listener = null;

export function showUploadModal(props) {
  __listener && __listener(props);
}

export function useUploadModal() {
  const [isVisible, setIsVisible] = useState(false);
  const [files, setFiles] = useState([]);
  const [activeFileIndex, setActiveFileIndex] = useState(-1);
  const refProps = useRef();
  const [isProcessing, setIsProcessing] = useState(false);
  const [progress, setProgress] = useState(0);
  const { upload } = useUpload({ onProgress: setProgress });
  const {
    isMultiple,
    fileInputProps,
    title,
    onSuccess,
  } = refProps.current || {};
  const isInput = files.length === 0 || isMultiple === true;

  const onClose = () => {
    setActiveFileIndex(-1);
    setIsVisible(false);
    setFiles([]);
  };
  const onSubmit = async () => {
    const uploadedFiles = [];

    setIsProcessing(true);

    for (let i = 0, len = files.length; i < len; i++) {
      const { inputFile, metadata } = files[i];

      setProgress(0);

      try {
        const { file } = await upload(inputFile, metadata);

        uploadedFiles.push(file);
      } catch (err) {
        //
      }
    }

    setIsProcessing(false);
    onClose();
    onSuccess && onSuccess(uploadedFiles);
  };
  const onRemoveInputFile = (index) => {
    const filter = (item, _index) => _index !== index;

    setFiles((prevState) => prevState.filter(filter));
  };
  const onSubmitInputFile = (data) => {
    const { index } = data;

    setFiles((prevState) => {
        if (index === prevState.length) {
          return [...prevState, data];
        }

        const map = (item, _index) => {
          if (_index === index) {
            return data;
          }

          return item;
        };

        return prevState.map(map);
    });
  };

  useEffect(() => {
    __listener = (props) => {
      refProps.current = {
        fileInputProps: props?.fileInputProps || {},
        isMultiple: props?.isMultiple || false,
        title: props?.title || '',
        onSuccess: props?.onSuccess || null,
      };

      setIsVisible(true);
    };

    return () => {
      __listener = null;
    };
  }, []);

  return {
    title,
    fileInputProps,
    onSubmitInputFile,
    onRemoveInputFile,
    files,
    progress,
    activeFileIndex,
    setActiveFileIndex,
    isInput,
    isMultiple,
    isVisible,
    isProcessing,
    onClose,
    onSubmit,
  };
}

export function useInput(props) {
  const { index, setActiveFileIndex, onSubmit, data } = props;
  const [inputDataUrl, setInputDataUrl] = useState(data?.inputDataUrl || null);
  const [inputFile, setInputFile] = useState(data?.inputFile || null);

  const onModifyInputFile = () => setActiveFileIndex(index);
  const onChangeInputFile = async (e) => {
    const file = e.target.files[0];

    if (file.type.indexOf('video') > -1) {
      setInputDataUrl(await getDataUrlByVideoFile(file));
    } else {
      setInputDataUrl(await getDataUrlByFile(file));
    }

    setInputFile(file);
    setActiveFileIndex(index);
  };
  const onSubmitInputFile = (data) => {
    if (data) {
      const {
        dataURL: croppedDataUrl,
        metadata
      } = data;

      onSubmit({
        index,
        inputFile,
        inputDataUrl,
        croppedDataUrl,
        metadata,
      });
    }

    setActiveFileIndex(-1);
  };

  return {
    inputFile,
    inputDataUrl,
    onChangeInputFile,
    onModifyInputFile,
    onSubmitInputFile,
  };
}

function getDataUrlByFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    const onLoad = () => resolve(reader.result);

    reader.addEventListener('load', onLoad, false);
    reader.readAsDataURL(file);
  });
}

function getDataUrlByVideoFile(file) {
  return new Promise((resolve) => {
    const video = document.createElement('video');
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const initCanvas = (e) => {
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
    };
    const drawFrame = (e) => {
      video.pause();
      ctx.drawImage(video, 0, 0);
      resolve(canvas.toDataURL('image/png'));
      URL.revokeObjectURL(video.src);
    };

    video.muted = true;
    video.addEventListener('loadedmetadata', initCanvas, false);
    video.addEventListener('timeupdate', drawFrame, false);
    video.src = URL.createObjectURL(file);
    video.play();
  });
}
