import { gql } from "@apollo/client";

export const CreateOperation = gql`
  mutation createOperation($data: OperationData!) {
    createOperation(data: $data) {
      id
      gifts {
        code
        amount
      }
    }
  }
`;

export const CheckGiftCode = gql`
  mutation checkCode($code: String!, $email: String) {
    checkCode(code: $code, email: $email) {
      id
      email
      name
      code
      amount
      userExists
      user {
        name
        avatar
      }
    }
  }
`;

export const AcceptGiftCode = gql`
  mutation acceptGift($code: String!) {
    acceptGift(code: $code) {
      id
    }
  }
`;
