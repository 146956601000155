import { useEffect } from 'react';

export function useOutsideClick(ref, callback) {
  useEffect(() => {
    if (ref?.current && callback) {
      const onMouseDown = (e) => {
        if (ref?.current?.contains && !ref.current.contains(e.target)) {
          callback(e);
        }
      };

      document.addEventListener('mousedown', onMouseDown);

      return () => {
        document.removeEventListener('mousedown', onMouseDown);
      };
    }
  }, [ref, callback]);
}
