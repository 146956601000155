


export enum SignInResult {
  Failed,
  Success,
}

export enum SignUpResult {
  AlreadyAdded,
  AlreadyExists,
  Failed,
  Success,
}

export enum ForgotPasswordResult {
  Failed,
  Success,
}

export enum ResetPasswordResult {
  Failed,
  Success,
}
