import React, { PureComponent } from 'react';
import SocialLogin from 'react-social-login';
import { API_ENDPOINT } from 'app/graphql/variables';
import axios from 'axios';
import styles from './index.module.scss';
// Assets
import IconGoogle from './assets/google.svg';
import IconFacebook from './assets/facebook.svg';

class _AuthButton extends PureComponent {
  render() {
    const { children, triggerLogin, ...props } = this.props;

    return (
      <button style={{ border: 0 }} onClick={triggerLogin} {...props}>
        {children}
      </button>
    );
  }
}

const AuthButton = SocialLogin(_AuthButton);

export default function AuthSocial(props) {
  const onLoginSuccess = (user) => {
    if (user?._provider && user?._profile) {
      axios
        .post(
          `${API_ENDPOINT}user/social-login`,
          {
            provider: user._provider,
            provider_id: user._profile.id,
            name: user._profile.name,
            email: user._profile.email,
          },
          {
            withCredentials: true,
          }
        )
        .then(({ data }) => {
          const currentUrl = window.location;

          window.location = currentUrl;
        });
    }
  };
  const onLoginFailure = (err) => console.error(err);

  return (
    <div className={styles.RootContainer}>
      <div className={styles.label}>Connexion rapide</div>

      <AuthButton
        className={styles.google}
        provider="google"
        appId="721617455090-pvhdt3vdimb95pp4vj819sk853ct7ne0.apps.googleusercontent.com"
        onLoginSuccess={onLoginSuccess}
        onLoginFailure={onLoginFailure}
        variant="contained"
      >
        <img src={IconGoogle} alt="Google +" />
        Connexion avec Google
      </AuthButton>

      <AuthButton
        className={styles.facebook}
        provider="facebook"
        appId="207112394357141"
        onLoginSuccess={onLoginSuccess}
        onLoginFailure={onLoginFailure}
        variant="contained"
      >
        <img src={IconFacebook} alt="Facebook" />
        Connexion avec Facebook
      </AuthButton>
    </div>
  );
}
