import PropTypes from 'prop-types';
import { Fragment, useState, useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import ImageViewer from 'react-simple-image-viewer';
import { useWindowResize } from 'app/use/useWindowResize';
import { POST_CONTENT_TYPES } from 'app/config';
import ReactHlsPlayer from 'react-hls-player';
import ReactPlayer from 'react-player';
import styles from './index.module.scss';
// Assets
import IconPlay from './assets/play.svg';
import IconLink from './assets/link.svg';

function mapImageViewerData(item) {
  const { path } = item || {};

  return path || 'https://via.placeholder.com/280x280';
}

Content.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object.isRequired,
};

export default function Content(props) {
  const { className, data } = props;
  const { content, postType, url, tag, requirementTag, requirementColor } = data;
  const { ref, inView } = useInView({
    threshold: 1,
  });
  const { width: windowWidth } = useWindowResize();
  const [imageViewerIndex, setImageViewerIndex] = useState(-1);
  const refVideoPlayer = useRef();
  const refMounted = useRef(true);
  const [playingVideo, setPlayingVideo] = useState(false);
  const [isShowAllMedia, setIsShowAllMedia] = useState(false);
  const [isLinkImageError, setIsLinkImageError] = useState(false);
  const [maxWidthLink, setMaxWidthLink] = useState(null);
  const refLinkContainer = useRef();
  const isMoreMediaToShow = url?.length > 4;
  const {
    path: mediaUrl,
    name: mediaName,
    thumb,
    // hlsPath,
  } = url?.length
    ? url[0] || {}
    : {};
  
  const hlsPath = null;

  const ImageViewerSrc = (url || []).map(mapImageViewerData);

  const onCloseImageViewer = () => setImageViewerIndex(-1);
  const onLinkImageError = () => setIsLinkImageError(true);

  useEffect(() => {
    refMounted.current = true;
    
    return () => {
      refMounted.current = false;
    };
  }, []);

  useEffect(() => {
    let timeout = null;

    const setPlayState = () => {
      if (refMounted.current) {
        if (refVideoPlayer.current) {
          if (inView) {
            refVideoPlayer.current.play();
          } else {
            refVideoPlayer.current.pause();
          }
        } else {
          timeout = setTimeout(setPlayState, 1000);
        }
      }
    };

    if (postType === POST_CONTENT_TYPES.VIDEO) {
      setPlayState();
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [postType, inView]);

  useEffect(() => {
    setPlayingVideo(inView);
  }, [inView]);

  useEffect(() => {
    if (refLinkContainer.current?.clientWidth) {
      setMaxWidthLink(refLinkContainer.current.clientWidth - 30);
    }
  }, [windowWidth]);

  const renderSingleTag = () =>
    requirementTag ? (
      <div className={styles.singleTag} style={{ backgroundColor: requirementColor }}>
        {requirementTag}
      </div>
    ) : null;

  const renderTag = (tag, index) => (
    <div key={index} className={styles.tag}>
      {tag}
    </div>
  );

  const [controls, setControls] = useState(true);

  const toggleControls = () => {
    setControls(!controls);
  };

  const renderMedia = () => {
    switch (postType) {
      case POST_CONTENT_TYPES.VIDEO:
        return (
          <>
            <div className={styles.video} onClick={toggleControls}>
              {hlsPath
                ? <ReactHlsPlayer
                    playerRef={refVideoPlayer}
                    src={hlsPath}
                    autoPlay={false}
                    controls={controls}
                    muted={false}
                    playsInline={true}
                    width="100%"
                    height="auto"
                    poster={thumb} />
                : <ReactPlayer
                    url={mediaUrl}
                    controls={controls}
                    autoPlay={false}
                    playing={playingVideo}
                    playIcon={
                      <div
                        className={styles.preview}
                        style={{
                          backgroundImage: `url(${thumb})`,
                        }}>
                        <img src={IconPlay} alt="" />
                      </div>
                    }
                    muted={false}
                    playsInline={true}
                    width="100%"
                    height="auto" />
              }
            </div>

            {renderSingleTag()}
          </>
        );

      case POST_CONTENT_TYPES.IMAGE:
      case POST_CONTENT_TYPES.ILLUSTRATION: {
        if (url?.length > 1) {
          const renderImage = (item, index) => {
            const { name, path } = item;
            const isRenderToggle = (
              isMoreMediaToShow &&
              !isShowAllMedia &&
              index === 3
            );
            const onClickImage = () => setImageViewerIndex(index);
            const onClickToggle = () => setIsShowAllMedia(true);

            return (
              <div
                key={index}
                className={styles.gallery_image}>
                <img
                  onClick={onClickImage}
                  src={path}
                  alt={name} />

                {isRenderToggle && (
                  <div
                    className={styles.gallery_toggle}
                    onClick={onClickToggle}>
                    {`+ ${url.length - 4}`}
                  </div>
                )}
              </div>
            );
          };

          return (
            <div className={styles.gallery}>
              {url.slice(0, isShowAllMedia ? undefined : 4).map(renderImage)}
            </div>
          );
        }

        const onClickImage = () => setImageViewerIndex(0);

        return (
          <>
            <div className={styles.imageWrapper}>
              <img
                onClick={onClickImage}
                className={styles.image}
                src={mediaUrl}
                alt={mediaName} />
            </div>

            {renderSingleTag()}
          </>
        );
      }

      case POST_CONTENT_TYPES.TEXT: {
        const renderLine = (chunk, index) => (
          <Fragment key={index}>
            {chunk}
            <br />
          </Fragment>
        );

        return (
          <div className={styles.text}>
            {content.split('\n').map(renderLine)}

            {!!tag?.length && <div className={styles.text_tags}>{tag.map(renderTag)}</div>}
          </div>
        );
      }

      case POST_CONTENT_TYPES.LINKS: {
        if (url?.length !== 1) {
          return null;
        }

        const {
          image,
          title,
          description,
          url: link
        } = url[0];
        const styleMaxWidth = {
          maxWidth: maxWidthLink
        };

        return (
          <>
            {(!!image && !isLinkImageError) && (
              <a
                className={styles.linkImage}
                href={link}
                target='_blank'
                rel='noreferrer'>
                <img
                  className={styles.image}
                  src={image}
                  alt=''
                  onError={onLinkImageError} />
              </a>
            )}

            <a
              className={styles.link}
              href={link}
              target='_blank'
              rel='noreferrer'>
              <img src={IconLink} alt='' />

              <div
                ref={refLinkContainer}
                className={styles.link_body}>
                {!!title && (
                  <div
                    className={styles.link_title}
                    style={styleMaxWidth}>
                    {title}
                  </div>
                )}

                {!!description && (
                  <div className={styles.link_description}>
                    {description}
                  </div>
                )}
              </div>
            </a>
          </>
        );
      }

      default:
        return null;
    }
  };

  return (
    <>
      <div ref={ref} className={`${styles.Root} ${className}`}>
        {renderMedia()}
      </div>

      {imageViewerIndex > -1 && (
        <ImageViewer
          src={ImageViewerSrc}
          currentIndex={imageViewerIndex}
          disableScroll={true}
          closeOnClickOutside={true}
          backgroundStyle={{ background: 'rgba(0,0,0,0.65)' }}
          onClose={onCloseImageViewer}
        />
      )}
    </>
  );
}
