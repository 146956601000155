import { ReactElement } from 'react';
import styles from './index.module.scss';

export default function Success(): ReactElement {
  return (
    <div className={styles.root}>
      <div className={styles.checkIcon} />

      <span>
        Ton vote a bien été pris en compte
      </span>
    </div>
  );
}
