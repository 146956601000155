import {
  useEffect,
  useState,
  useRef
} from 'react';
import {
  useHistory
} from 'react-router';
import {
  ROUTES
} from 'app/config/routes';
import {
  setRouterHistory,
} from 'app/utils/router';
import {
  useUser,
  user as userModule,
} from 'app/module';
import Avatar from 'app/components/common/Avatar';
import Button from 'app/components/common/Button';
import Center from './Center';
import Drawer from './Drawer';
import Search from './Search';
import UserMenu from './UserMenu';
import ImpactNav from './ImpactNav';
import styles from './index.module.scss';
// Assets
import IconLogo from './assets/logo.svg';
import IconMenu from './assets/menu.svg';

export default function HeaderNavigation() {
  const {
    user,
  } = useUser();

  const history = useHistory();

  // TODO: temporary solution until upgrade to new router...
  setRouterHistory(history);
  
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);

  const [isSearchFocused, setIsSearchFocused] = useState(false);

  const refProfile = useRef();
  const [isMouseOverProfileButton, setIsMouseOverProfileButton] = useState(false);
  const [isMouseOverProfile, setIsMouseOverProfile] = useState(false);
  const [isProfileSticky, setIsProfileSticky] = useState(false);
  const isProfileVisible = (
    isMouseOverProfileButton ||
    isMouseOverProfile ||
    isProfileSticky
  );

  const refImpactNav = useRef();
  const [isMouseOverImpactNavButton, setIsMouseOverImpactNavButton] = useState(false);
  const [isMouseOverImpactNav, setIsMouseOverImpactNav] = useState(false);
  const [isImpactNavSticky, setIsImpactNavSticky] = useState(false);
  const isImpactNavVisible = (
    isMouseOverImpactNavButton ||
    isMouseOverImpactNav ||
    isImpactNavSticky
  );

  const onClickSearch = () => {
    if (window.location.pathname !== ROUTES.PROJECTS.URL) {
      history.push(ROUTES.PROJECTS.URL);
    }
  };

  const onRouteToHome = () => history.push(ROUTES.HOME.URL);
  const onRouteToManifesto = () => history.push(ROUTES.LANDING_MANIFESTO.URL);

  const onMouseEnterImpactNavButton = () => {
    setIsMouseOverImpactNavButton(true);
  };
  const onMouseLeaveImpactNavButton = () => {
    setIsMouseOverImpactNavButton(false);
  };
  const onMouseEnterImpactNav = () => {
    setIsMouseOverImpactNav(true);
  };
  const onMouseLeaveImpactNav = () => {
    setIsMouseOverImpactNav(false);
  };
  const onSetStickyImpactNav = () => {
    setIsImpactNavSticky(true);
  };

  const onMouseEnterProfileButton = () => {
    setIsMouseOverProfileButton(true);
  };
  const onMouseLeaveProfileButton = () => {
    setIsMouseOverProfileButton(false);
  };
  const onMouseEnterProfile = () => {
    setIsMouseOverProfile(true);
  };
  const onMouseLeaveProfile = () => {
    setIsMouseOverProfile(false);
  };
  const onSetStickyProfile = () => {
    setIsProfileSticky(true);
  };

  const onShowMenu = () => {
    setIsDrawerVisible(true);
  };
  const onHideDrawer = () => {
    setIsDrawerVisible(false);
  };

  useEffect(() => {
    if (isImpactNavVisible) {
      const onMouseDown = (e) => {
        if (!refImpactNav.current.contains(e.target)) {
          setIsImpactNavSticky(false);
        }
      };

      window.document.addEventListener('mousedown', onMouseDown);

      return () => {
        window.document.removeEventListener('mousedown', onMouseDown);
      };
    }
  }, [isImpactNavVisible]);

  useEffect(() => {
    if (isProfileVisible) {
      const onMouseDown = (e) => {
        if (!refProfile.current.contains(e.target)) {
          setIsProfileSticky(false);
        }
      };

      window.document.addEventListener('mousedown', onMouseDown);

      return () => {
        window.document.removeEventListener('mousedown', onMouseDown);
      };
    }
  }, [isProfileVisible]);

  if (history.location.pathname.indexOf(ROUTES.VOTE_PREVIEW.PROPS.pathname) > -1 ||
      userModule().isCompany()) {
    return null;
  }

  return (
    <>
      <div className={styles.placeholder} />

      <div className={styles.Root}>
        <div className={styles.desktop}>
          <div className={styles.desktop_left}>
            <Search
              className={styles.Search}
              onClick={onClickSearch}
              history={history} />
          </div>

          <Center
            className={styles.desktop_center}
            onClick={onRouteToHome} />

          <div className={styles.desktop_right}>
            <Button
              className={styles.buttonManifesto}
              label="Manifesto"
              onClick={onRouteToManifesto} />

            {user?.id
              ? <Button
                className={styles.user}
                onClick={onSetStickyProfile}
                onMouseEnter={onMouseEnterProfileButton}
                onMouseLeave={onMouseLeaveProfileButton}>
                <Avatar
                    src={user.avatar}
                    alt={user.name}
                    size={36} />

                <div className={styles.user_name}>
                  {user.name}
                </div>

                {!!user.notificationCount && (
                  <div className={styles.user_notifications}>
                    {user.notificationCount}
                  </div>
                )}
              </Button>
              : <>
                <Button
                  className={`${styles.buttonImpactNav} ${isImpactNavSticky && styles.buttonImpactNav__isSticky}`}
                  label="Engagez-vous"
                  onClick={onSetStickyImpactNav}
                  onMouseEnter={onMouseEnterImpactNavButton}
                  onMouseLeave={onMouseLeaveImpactNavButton} />

                <Button
                  className={styles.buttonLogin}
                  label="Connexion"
                  isAuthRequired={true} />
              </>
            }
          </div>
        </div>

        <div className={styles.mobile}>
          <div className={styles.mobile_left}>
            <Button className={styles.buttonMenu} onClick={onShowMenu}>
              <img src={IconMenu} alt="" />
            </Button>
          </div>

          {!isSearchFocused && (
            <Button
              className={styles.logoSmall}
              onClick={onRouteToHome}>
              <img src={IconLogo} alt="Logo Kunbis" />
            </Button>
          )}

          <div className={`${styles.mobile_right} ${isSearchFocused && styles.mobile_right__isActive}`}>
            <Search
              className={`${styles.SearchMobile} ${isSearchFocused && styles.SearchMobile__isFocused}`}
              history={history}
              onChangeFocusState={setIsSearchFocused} />
          </div>
        </div>
      </div>

      {user?.id
        ? <div
          ref={refProfile}
          className={`${styles.Profile} ${isProfileVisible && styles.Profile__isVisible}`}
          onMouseEnter={onMouseEnterProfile}
          onMouseLeave={onMouseLeaveProfile}>
          <div />
          <UserMenu history={history} />
        </div>
        : <div
          ref={refImpactNav}
          className={`${styles.ImpactNav} ${isImpactNavVisible && styles.ImpactNav__isVisible}`}
          onMouseEnter={onMouseEnterImpactNav}
          onMouseLeave={onMouseLeaveImpactNav}>
          <div />
          <ImpactNav
            history={history} />
        </div>
      }

      <Drawer
        user={user}
        history={history}
        isVisible={isDrawerVisible}
        onClose={onHideDrawer} />
    </>
  );
}
