import { ReactElement, useState } from 'react';
import Intro from './Intro';
import Media from './Media';
import Success from './Success';
import { ModuleConfig, ModuleConfigMap, ModuleProps, Step } from './config';
import { RootModuleProps } from '../config';
import { preLoadVideos } from '../utils';

type Module = (props: ModuleProps) => ReactElement;

const ModuleMap: Record<Step, Module> = {
  [Step.Intro]: Intro,
  [Step.Media]: Media,
  [Step.Success]: Success,
} as const;

export default function Home(
  props: RootModuleProps,
): ReactElement {
  const { campaigns, vote, setBackground } = props;
  const [step, setStep] = useState<Step>(Step.Intro);
  const Component: Module = ModuleMap[step];

  preLoadVideos(campaigns);

  const onSubmit = (): void => {
    const config: ModuleConfig = ModuleConfigMap[step];

    if (config.nextStep) {
      setStep(config.nextStep);
    }
  };

  return (
    <Component
      onSubmit={onSubmit}
      campaigns={campaigns}
      setBackground={setBackground}
      vote={vote} />
  );
}
