import React from 'react';
import { useInput } from '../useUploadModal';
// import CircularProgress from '@material-ui/core/CircularProgress';
// <CircularProgress size={60} />
//
// <div className={styles.progress}>
//   {`${progress}%`}
// </div>
import Modify from './Modify';
import styles from './index.module.scss';
// Assets
import IconPlus from './assets/plus.svg';
import IconEdit from './assets/edit.svg';
import IconModify from './assets/modify.svg';
import IconRemove from './assets/remove.svg';

export default function Input(props) {
  const {
    className,
    fileInputProps,
    onSubmit,
    onRemove: _onRemove,
    index,
    data,
    isActive,
    setActiveFileIndex
  } = props;
  const {
    inputDataUrl,
    onModifyInputFile,
    onChangeInputFile,
    onSubmitInputFile,
  } = useInput({
    index,
    data,
    onSubmit,
    setActiveFileIndex,
  });
  const { croppedDataUrl } = data || {};

  const onRemove = () => _onRemove(index);

  const renderInput = () => (
    <input
      {...fileInputProps}
      className={styles.input}
      type='file'
      onChange={onChangeInputFile} />
  );

  if (isActive) {
    return (
      <Modify
        className={styles.Modify}
        data={inputDataUrl}
        onSubmit={onSubmitInputFile} />
    );
  }

  return (
    <div className={`${styles.Root} ${className}`}>
      {croppedDataUrl
        ? <>
            <img
              className={styles.image}
              src={croppedDataUrl}
              alt='' />

            <div className={styles.options}>
              <div className={styles.edit}>
                <img src={IconEdit} alt='' />
                {renderInput()}
              </div>

              <div
                className={styles.modify}
                onClick={onModifyInputFile}>
                <img src={IconModify} alt='' />
              </div>
            </div>

            <div
              className={styles.remove}
              onClick={onRemove}>
              <img src={IconRemove} alt='' />
            </div>
          </>
        : <>
          <img src={IconPlus} alt='' />
          {renderInput()}
        </>
      }
    </div>
  );
}
