import {
  formatDistanceToNowStrict as _formatDistanceToNowStrict,
  formatDistance as _formatDistance,
  format as _format
} from 'date-fns';
import { fr } from 'date-fns/locale';

export function formatDistanceToNowStrict(date, options = {}) {
  return _formatDistanceToNowStrict(date, {
    ...options,
    locale: fr,
  });
}

export function formatDistance(dateA, dateB, options = {}) {
  return _formatDistance(dateA, dateB, {
    ...options,
    locale: fr,
  });
}

export function format(date, dateFormat, options = {}) {
  return _format(date, dateFormat, {
    ...options,
    locale: fr,
  });
}
