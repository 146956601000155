import React, { useState } from 'react';
import { ROUTES } from 'app/config/routes';
import Text from 'app/components/common/Input/Text';
import Checkbox from 'app/components/common/Input/Checkbox';
import Button from 'app/components/common/Button';
import isEmail from 'validator/lib/isEmail';
import isStrongPassword from 'validator/lib/isStrongPassword';
import styles from './index.module.scss';

export default function AuthForm(props) {
  const {
    email,
    setEmail,
    isProcessing,
    isLogin,
    setIsLogin,
    onForgotPassword,
    onLogin: _onLogin,
    onRegister: _onRegister,
  } = props;
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [isStayConnected, setIsStayConnected] = useState(false);
  const [isTermsAccepeted, setIsTermsAccepeted] = useState(false);
  const [isCompany, setIsCompany] = useState(false);
  const isEmailError = !isEmail(email);
  const isPasswordError = !isStrongPassword(password);
  const isPasswordConfirmError = password !== passwordConfirm;
  const isSubmitDisabled =
    !firstName ||
    !lastName ||
    !isTermsAccepeted ||
    isEmailError ||
    isPasswordError ||
    isPasswordConfirmError;

  const onLogin = () => {
    _onLogin({
      email,
      password
    });
  };

  const onRegister = () => {
    _onRegister({
      name: `${firstName} ${lastName}`,
      firstName,
      lastName,
      email,
      password,
    });
  };

  const onSetRegier = () => setIsLogin(false);
  const onSetLogin = () => setIsLogin(true);

  return (
    <div className={styles.RootContainer}>
      {!isLogin && (
        <>
          <Checkbox
            className={`${styles.Checkbox} ${styles.Checkbox__isCompany}`}
            text='Je veux m’enregistrer en tant qu’entreprise'
            onChange={setIsCompany}
            isChecked={isCompany}
          />

          <Text
            className={styles.Text}
            placeholder={isCompany ? "Forme juridique de l’entreprise" : "Prénom"}
            autoComplete="given-name"
            onChangeText={setFirstName}
            value={firstName}
          />

          <Text
            className={styles.Text}
            placeholder={isCompany ? "Nom de l’entreprise" : "Nom"}
            autoComplete="family-name"
            onChangeText={setLastName}
            value={lastName}
          />
        </>
      )}

      <Text
        className={styles.Text}
        type="email"
        autoComplete="email"
        placeholder="Email"
        error="Merci de renseigner une adresse e-mail valide"
        isError={isEmailError}
        onChangeText={setEmail}
        value={email}
      />

      <Text
        className={styles.Text}
        type="password"
        placeholder="Mot de passe"
        error="Votre mot de passe doit comporter un minimum de 8 caractères et contenir une majuscule, un symbole et un chiffre"
        isError={isPasswordError}
        onChangeText={setPassword}
        value={password}
      />

      {!isLogin && (
        <Text
          className={styles.Text}
          type="password"
          placeholder="Entrez à nouveau votre mot de passe"
          error="Vos deux mots de passe ne sont pas identiques"
          isError={isPasswordConfirmError}
          onChangeText={setPasswordConfirm}
          value={passwordConfirm}
        />
      )}

      {isLogin ? (
        <>
          <Checkbox
            className={styles.Checkbox}
            text="Rester connecté"
            onChange={setIsStayConnected}
            isChecked={isStayConnected}
          />

          <div className={styles.buttonForgotPassword} onClick={onForgotPassword}>
            Vous avez oublié votre mot de passe?
          </div>

          <div className={styles.login}>
            <Button
              className={styles.login_button}
              isProcessing={isProcessing}
              isDisabled={!email || !password}
              label="Connexion"
              onClick={onLogin}
            />
          </div>

          <Button className={styles.buttonSetLogin} onClick={onSetRegier}>
            Vous n’avez pas de compte ?<span>Créez-en un</span>
          </Button>
        </>
      ) : (
        <>
          <div className={styles.register}>
            <Checkbox
              className={styles.Checkbox}
              text={
                <>
                  Je déclare avoir lu, compris et accepté
                  <a
                    className={styles.Checkbox_link}
                    href={ROUTES.TERMS.URL}
                    target="_blank"
                    rel="noreferrer"
                  >
                    les conditions d'utilisation.
                  </a>
                </>
              }
              onChange={setIsTermsAccepeted}
              isChecked={isTermsAccepeted}
            />

            <Button
              className={styles.register_button}
              isProcessing={isProcessing}
              isDisabled={isSubmitDisabled}
              label="S’enregistrer"
              onClick={onRegister}
            />
          </div>

          <Button className={styles.buttonSetLogin} onClick={onSetLogin}>
            Vous avez déjà un compte ?<span>Connectez-vous</span>
          </Button>
        </>
      )}
    </div>
  );
}
