import { ReactElement, useEffect, useRef, useState } from 'react';
import BrandLogo from '../logo.png';
import styles from './index.module.scss';

const LogoTransformDelay: number = 600;
const LogoTransformDuration: number = 1600;
const TotalLogoTransformTime: number = LogoTransformDelay + LogoTransformDuration;
const OpacityDelay: number = TotalLogoTransformTime;
const OpacityDuration: number = 1000;
const TotalAnimationTime: number = TotalLogoTransformTime + OpacityDuration;

// interface SplashScreenProps {}

export default function SplashScreen(
  // props: SplashScreenProps,
): ReactElement | null {
  const [animate, setAnimate] = useState<boolean>(false);
  const refLastAnimate = useRef<boolean>(animate);
  const [done, setDone] = useState<boolean>(false);

  if (refLastAnimate.current !== animate) {
    refLastAnimate.current = animate;

    if (animate) {
      setTimeout((): void => {
        setDone(true);
      }, TotalAnimationTime);
    }
  }
  
  useEffect((): void => {
    setTimeout((): void => {
      setAnimate(true);
    }, 250);
  }, []);

  if (done) {
    return null;
  }

  return (
    <div
      className={`${styles.root} ${animate && styles.hide}`}
      style={{
        transitionDelay: `${OpacityDelay}ms`,
        transitionDuration: `${OpacityDuration}ms`,
      }}>
      <div
        style={{
          animationDelay: `${LogoTransformDelay}ms`,
          animationDuration: `${LogoTransformDuration}ms`,
        }}
        className={animate ? styles.animate : ''}>
        <img src={BrandLogo} alt='' />
      </div>
    </div>
  );
}
