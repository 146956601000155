import {
  gql,
} from '@apollo/client';

export const GetOperations = gql`
  query operations($limit: Int, $offset: Int, $userId: Int, $q: String, $companyId: Int) {
    operations(limit: $limit, offset: $offset, userId: $userId, q: $q, companyId: $companyId) {
      id
      title
      operationType
      budget
      numberOfPeople
      startAt
      expireAt
      createdAt
      gifts {
        id
        acceptedUserId
        contributionCount
        email
        name
        user {
          name
        }
        status
        wallet
        code
        amount
      }
    }
  }
`;
