import {
  GiftCodeResponse,
  Operation,
  OperationCreate,
} from "app/graphql/types";
import {
  CreateOperation,
  CheckGiftCode,
  AcceptGiftCode,
} from 'app/graphql/mutations';
import {
  GetOperations,
} from "app/graphql/queries";
import {
  RequestFabric,
  GraphQL,
  Payload,
} from 'app/api';
import {
  OperationFactory,
} from 'app/factory';

class OperationApi {

  public static create(
    data: OperationCreate,
  ): Promise<Operation> {
    return RequestFabric.graphQL<Operation>(
      CreateOperation,
      {
        data,
      },
      GraphQL.ResponseKey.Operation.CreateOperation,
      OperationFactory.createOperation,
    );
  }

  public static getAll(companyId?: number): Promise<Operation[]> {
    return RequestFabric.graphQL<Operation[]>(
      GetOperations,
      {
        companyId,
      },
      GraphQL.ResponseKey.Operation.GetOperations,
      OperationFactory.createOperations,
    );
  }

  public static checkGiftCode(payload: Payload.Operation.CheckGiftCodePayload): Promise<GiftCodeResponse> {
    return RequestFabric.graphQL<GiftCodeResponse>(
      CheckGiftCode,
      payload,
      GraphQL.ResponseKey.Operation.CheckGiftCode,
      OperationFactory.createGiftCodeResponse,
    );
  }

  public static acceptGiftCode(code: string): Promise<void> {
    return RequestFabric.graphQL(
      AcceptGiftCode,
      {
        code,
      },
    );
  }

}

export default OperationApi;

