// export function renderPrice(price) {
//   price = `${price}`;
//
//   const euro = price.slice(0, -2) || '0';
//   const cent = price.slice(-2);
//
//   return `${euro},${cent.length === 1 ? `0${cent}` : cent} €`;
// }

export function getUniqueId() {
  return `${Math.random().toString(36).slice(2, 9)}${Date.now()}`;
}

export function renderPrice(price) {
  price = `${price}`.replace(/,/g, '.');

  const indexOfDot = price.indexOf('.');

  if (indexOfDot > -1) {
    const cents = price.slice(indexOfDot + 1);

    if (cents.length === 1) {
      price = `${price}0`;
    }
  }

  return `${price} €`;
}

/**
 * min (inclusive)
 * max (inclusive)
 */
export function getRandomNumber(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}


export function createImage(dataURL) {
  return new Promise((resolve) => {
    const image = new Image();
    const onLoad = () => resolve(image);

    image.addEventListener('load', onLoad);
    image.src = dataURL;
  });
}

export function dataURLtoFile(dataUrl, name = `file-${Date.now()}`){
  const arr = dataUrl.split(',');
  const type = arr[0].match(/:(.*?);/)[1];
  const ext = type.slice(type.indexOf('/') + 1);
  const bstr = atob(arr[1]);

  let n = bstr.length;

  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File(
    [u8arr],
    `${name}.${ext}`,
    {
      type
    },
  );
}
