
import {
  GetObservableData,
  Listener,
  Unsubscribe,
} from './types';

export * from './types';

class Observer<ObservableData> {
  private getObservableData: GetObservableData<ObservableData>;
  private listeners: Listener<ObservableData>[] = [];

  constructor(getObservableData: GetObservableData<ObservableData>) {
    this.getObservableData = getObservableData;
  }

  public observe(listener: Listener<ObservableData>): Unsubscribe {
    this.listeners.push(listener);
    this.notify(listener);

    return (): void => {
      this.detach(listener);
    };
  }

  public notify(listener?: Listener<ObservableData>): void {
    const data: ObservableData = this.getObservableData();

    if (listener) {
      listener(data);
    } else {
      for (const listener of this.listeners) {
        listener(data);
      }
    }
  }

  public detach(listener: Listener<ObservableData>): void {
    for (let i = 0; i < this.listeners.length; i++) {
      if (this.listeners[i] === listener) {
        this.listeners.splice(i, 1);
        break;
      }
    }
  }

}

export default Observer;