
let __history;

function getRouterHistory() {
  return __history;
}

function setRouterHistory(history) {
  __history = history;
}

export {
  setRouterHistory,
  getRouterHistory,
};