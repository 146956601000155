import Modal from 'app/components/common/Modal';
import Button from 'app/components/common/Button';
import styles from './index.module.scss';
import ContributionApi from 'app/api/module/Contribution';
import { user } from 'app/module';
import { ConfirmInternalContributionProps } from './types';
import { useState } from 'react';
import IconCheck from './check.svg';
import { useGiftCodeResponse } from '../../useGiftCodeResponse';
import { GiftCodeResponse } from 'app/graphql/types';
import Logo from './logo.png';

function InternalContribution(props: ConfirmInternalContributionProps): JSX.Element {
  const giftCodeResponse: GiftCodeResponse | null = useGiftCodeResponse();

  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const onClose = (): void => {
    if (loading) {
      return;
    }

    if (success) {
      props.onDone();
    } else {
      props.onClose();
    }
  };

  const submit = async (): Promise<void> => {
    setLoading(true);

    try {
      await ContributionApi.createInternalContribution({
        userId: user().user?.id || 0,
        giftId: giftCodeResponse?.id || 0,
      });

      setSuccess(true);
    } catch (err) {
      //
      setSuccess(false);
    }

  };

  return (
    <Modal
      isCustomBody={true}
      onClose={onClose}>
      <div className={styles.root}>
        {success
          ? <>
              <img src={IconCheck} alt='' />

              <div className={styles.text}>
                Votre don de 5 € a bien été distribué à la Fondation Rennes School of Business !
              </div>

              <Button
                className={styles.submit}
                label='Ok'
                onClick={props.onDone} />
            </>
          : <>
              <div className={styles.logo}>
                <img src={Logo} alt="" />
              </div>

              <div className={styles.title}>
                SOUTENIR UNE JEUNESSE 
                <br />
                à l’impact positif
              </div>

              <div className={styles.text}>
                Dans la continuité de l’engagement social et sociétal de l’école,
                au travers des programmes et des dispositifs d’aide et d’accompagnement en faveur de ses étudiants,
                la Fondation Rennes School of Business a été créée en 2022 sous l’égide de la Fondation de France.
                <br /><br />
                Portée par ses Alumni et des mécènes,
                elle a pour mission prioritaire de faciliter l’accès à l’enseignement supérieur de jeunes talents de tous horizons.
                Cette Fondation se veut facilitatrice de rencontres entre différents acteurs provenant d’univers tout autant différents étudiants,
                professionnels, associations et entreprises. Tous animés par une même envie d’apprendre, de se surpasser,
                de faire bouger les lignes et de s’engager en soutenant une jeunesse à impact.
              </div>

              <Button
                className={styles.submit}
                isProcessing={loading}
                label={`Donner ${giftCodeResponse?.amount} euros`}
                onClick={submit} />

              <Button
                className={styles.cancel}
                label='Retour'
                onClick={onClose} />
            </>
        }
      </div>
    </Modal>
  );
}

export default InternalContribution;
