import * as Sentry from '@sentry/react';
import Observer from 'app/util/Observer';
import useUser from './useUser';
import {
  UserApi,
  ProjectApi,
} from 'app/api';
import {
  UserFactory,
} from 'app/factory';
import {
  UserTypes,
} from 'app/graphql/types/user';
import {
  Project,
} from 'app/graphql/types';
import {
  UserData,
} from './types';

export * from './types';
export {
  useUser,
};

class User {
  private static instance?: User;
  public static getInstance(): User {
    if (!User.instance) {
      User.instance = new User();
    }

    return User.instance;
  }

  private _observer: Observer<UserData>;
  private _user?: UserTypes.User | undefined;
  private _projects: Project[] = [];
  private sentrySet: boolean = false;
  private projectsFetched: boolean = false;
  
  public get observer(): Observer<UserData> {
    return this._observer;
  }

  public get user(): UserTypes.User | undefined {
    return this._user;
  }

  private set user(user: UserTypes.User | undefined) {
    this._user = user;
    this.observer.notify();
  }

  private get projects(): Project[] {
    return this._projects;
  }

  private set projects(projects: Project[]) {
    this._projects = projects;
    this.observer.notify();
  }

  private constructor() {
    this._observer = new Observer(this.getObservableData);
  }

  public getObservableData = (): UserData => {
    return {
      user: this.user,
      projects: this.projects,
    };
  }

  public async fetch(): Promise<boolean> {
    const user: UserTypes.User | void = await this.fetchUser();

    if (user) {
      if (this.sentrySet === false) {
        this.sentrySet = true;
        this.setSentry();
      }

      if (this.projectsFetched === false) {
        this.projectsFetched = true;
        this.fetchProjects();
      }
    }
    
    return !!user;
  }

  private async fetchUser(): Promise<UserTypes.User | void> {
    try {
      const user: UserTypes.User = await UserApi.getUser();

      this.user = user;

      return user;
    } catch (error) {
      console.error(error);
    }
  }

  private async fetchProjects(): Promise<Project[]> {
    if (this.user) {
      try {
        const projects: Project[] = await ProjectApi.getUserProjects(this.user.id);
  
        this.projects = projects;
  
        return projects;
      } catch (error) {
        console.error(error);
      }
    }

    return [];
  }

  public async updateUser(data: UserTypes.UpdateUser): Promise<void> {
    if (await this.update(data)) {
      await this.fetchUser();
    }
  }

  public updatePassword(data: UserTypes.UpdatePassword): Promise<boolean> {
    return this.update(data);
  }

  public updateNotifications(data: UserTypes.UpdateNotification): Promise<boolean> {
    return this.update(data);
  }

  public isCompany(): boolean {
    return this.user?.role === 'company';
  }

  private async update(data: UserTypes.Update): Promise<boolean> {
    if (this.user) {
      try {
        await UserApi.updateUser(
          this.user.id, 
          UserFactory.createUpdateUser(data),
        );

        return true;
      } catch (error) {
        console.error(error);
      }
    }

    return false;
  }

  private setSentry(): void {
    if (this.user) {
      Sentry.setUser({
        id: `${this.user.id}`,
        name: `${this.user.firstName} ${this.user.lastName}`,
        email: this.user.email,
      });
    }
  }

}

export default (): User => {
  return User.getInstance();
};
