import { ReactElement, useState } from 'react';
import Video from './Video';
import Button from '../../Button';
import { ModuleProps } from '../config';
import styles from './index.module.scss';
import { EventCampaign, SkemaColor } from 'app/skema/config';
import useSetBackground from 'app/skema/useSetBackground';

export default function Media(
  props: ModuleProps,
): ReactElement {
  const { onSubmit, campaigns, setBackground } = props;
  const [autoPlayEnabled, setAutoPlayEnabled] = useState<boolean>(false);

  useSetBackground(setBackground, SkemaColor.Beige);

  const enableAutoPlay = (): void => {
    setAutoPlayEnabled(true);
  };

  const renderVideo = (
    item: EventCampaign,
  ): ReactElement => {
    return (
      <Video
        key={item.id}
        title={item.name}
        url={item.media}
        autoPlayEnabled={autoPlayEnabled}
        onToggled={enableAutoPlay} />
    );
  };

  return (
    <div className={styles.root}>
      <div className={styles.title}>
        OSE, AGIS POUR LE MONDE, VIS LA DIVERSITE, ENGAGE-TOI DURABLEMENT, VISE L’EXCELLENCE
      </div>

      <div className={styles.videos}>
        {campaigns.map(renderVideo)}
      </div>

      <Button onClick={onSubmit}>
        Compris
      </Button>
    </div>
  );
}
