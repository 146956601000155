import { gql } from '@apollo/client';

export const CreateCampaign = gql`
  mutation createCampaign($data: CampaignData!) {
    createCampaign(data: $data) {
      id
      campaignUuid
      name
      description
      fundingGoal
      fundingBreakdown
      milestoneBreakdown
      startDate
      endDate
      knowReporterSystem
      receivePhysicalHelp
      expertHelp
      explorerHelp
      campaignUsers {
        user {
          name
        }
        role
      }
      project {
        projectUuid
      }
    }
  }
`;

export const CreateUserRequest = gql`
  mutation createUserRequest($data: UserRequestData!) {
    createUserRequest(data: $data) {
      id
      campaignId
    }
  }
`;

export const Updatecampaign = gql`
  mutation updateCampaign($campaignId: Int!, $data: IgnoreNoneUpdateCampaign!) {
    updateCampaign(campaignId: $campaignId, data: $data) {
      id
      campaignUuid
      name
      description
      fundingGoal
      fundingBreakdown
      milestoneBreakdown
      startDate
      endDate
      financedAmount
      financedText
      knowReporterSystem
      receivePhysicalHelp
      expertHelp
      explorerHelp
      campaignUsers {
        user {
          name
        }
        role
      }
    }
  }
`;

export const UpdateCampaignAdmin = gql`
  mutation updateCampaignAdmin(
    $campaignId: Int!
    $status: Int
    $strategyId: Int
    $data: IgnoreNoneUpdateCampaign!
  ) {
    updateCampaignAdmin(
      campaignId: $campaignId
      status: $status
      strategyId: $strategyId
      data: $data
    ) {
      id
      campaignUuid
      name
      description
      fundingGoal
      fundingBreakdown
      milestoneBreakdown
      startDate
      endDate
      knowReporterSystem
      receivePhysicalHelp
      expertHelp
      explorerHelp
      campaignUsers {
        user {
          id
          name
        }
        role
      }
    }
  }
`;

export const CreateContribution = gql`
  mutation createContribution($campaignId: Int!, $amount: Float!, $isAnonymousContribution: Boolean, $giftId: Int) {
    createContribution(campaignId: $campaignId, amount: $amount, isAnonymousContribution: $isAnonymousContribution, giftId: $giftId) {
      id
      contributionUuid
    }
  }
`;

export const RequestWithdrawal = gql`
  mutation createWithdrawal($data: NewWithdrawalData!) {
    createWithdrawal(data: $data) {
      id
    }
  }
`;

export const RemoveCampaignUser = gql`
  mutation removeCampaignUser($userId: Int!, $campaignId: Int!) {
    removeCampaignUser(userId: $userId, campaignId: $campaignId)
  }
`;
