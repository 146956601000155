import {
  useState,
} from 'react';
import {
  useHistory,
} from 'react-router';
import {
  CATEGORY_COLORS,
  CATEGORY_TYPES,
} from 'app/config';
import Modal from 'app/components/common/Modal';
import Avatar from 'app/components/common/Avatar';
import Menu, {
  MenuItem,
} from 'app/components/common/Menu';
import {
  ROUTES,
} from 'app/config/routes';
import {
  useUserModalProvider,
} from './useUserModal';
import styles from './index.module.scss';
// Assets
import IconMarker from './assets/marker.svg';
import IconDrill from './assets/drill.svg';
import IconClose from './assets/close.svg';

const CATEGORY_MAP = {
  reporter: [CATEGORY_TYPES.DEFAULT],
  expert: [CATEGORY_TYPES.EXPERT],
  explorer: [CATEGORY_TYPES.EXPLORER],
};

function getActivityTitle(campaign, project, activityType) {
  if (campaign) {
    if (project) {
      return `${campaign.name}, ${project.name}`;
    }

    return campaign.name;
  }

  return activityType;
}

export default function UserModalProvider() {
  const history = useHistory();

  const {
    isVisible,
    data,
    onClose
  } = useUserModalProvider();

  const [isReported, setIsReported] = useState(false);

  const onReport = () => setIsReported(true);

  const renderActivity = (item, index) => {
    const campaign = item.campaign;
    const project = item.campaign?.project;
    const title = getActivityTitle(campaign, project, item.activityType);
    const description = project?.projectDescription || '';

    const onClick = () => {
      if (campaign && project) {
        history.push(
          ROUTES.PROJECT_BY_NAME.URL(project.id, {
            projectName: project.name,
            campaignId: campaign.id,
            campaignName: campaign.name,
          }),
        );

        onClose();
      }
    };

    return (
      <div
        key={index}
        className={styles.item}>
        <Avatar
          isProject={true}
          src={project?.logo}
          alt={title}
          size={64}
          onClick={onClick} />

        <div className={styles.item_body}>
          <div className={styles.item_title}>
            {title}
          </div>

          <div className={styles.item_description}>
            {description}
          </div>
        </div>
      </div>
    );
  };

  const renderRoles = (item, index) => {
    return (
      <div
        key={index}
        className={styles.experience}
        style={{
          backgroundColor: CATEGORY_COLORS[CATEGORY_MAP[item]],
        }}>
        {item}
      </div>
    );
  };

  if (!isVisible) {
    return null;
  }

  return (
    <Modal className={styles.Modal} onClose={onClose}>
      <div className={styles.header}>
        <Avatar
          src={data?.avatar}
          alt={data?.name}
          size={84} />

        <div className={styles.info}>
          <div
            className={styles.name}
            style={{
              fontSize: data?.location ? 18 : 22,
            }}>
            {data?.name}
          </div>

          {data?.activity?.length
            ? !!data?.location && (
              <div className={styles.location}>
                <img src={IconMarker} alt="" />

                {data.location}
              </div>
            )
            : <div className={styles.noData}>
                <img src={IconDrill} alt="" />

                <div>
                  Désolé, <span>{data?.name}</span> n'a pas commencé à récomoder le monde
                </div>
              </div>
          }

          {!!data?.campaignRoles?.length && (
            <div className={styles.experiences}>
              {[...new Set(data.campaignRoles)].map(renderRoles)}
            </div>
          )}
        </div>

        <Menu className={styles.menu}>
          <MenuItem
            label={isReported ? 'Signalé avec succès' : 'Signaler cet utilisateur'}
            isDisabled={isReported}
            onClick={onReport} />
        </Menu>
      </div>

      {!!data?.activity?.length && (
        <>
          <div className={styles.description}>
            {data.description}
          </div>

          <div className={styles.items}>
            <div className={styles.items_title}>
              <div className={styles.items_title_amount}>{data.activity.length}</div>
              Désolé, <span>{data?.name}</span> est occupé à raccomoder le monde
            </div>

            {data.activity.map(renderActivity)}
          </div>
        </>
      )}

      <div className={styles.close} onClick={onClose}>
        <img src={IconClose} alt="" />
      </div>
    </Modal>
  );
}
