// import PropTypes from 'prop-types';
import React, {
  useState,
  useEffect
} from 'react';
import {
  createPortal
} from 'react-dom';
import Button from 'app/components/common/Button';
import styles from './index.module.scss';
// Assets
import IconCheckmark from './assets/checkmark.svg';

const STORAGE_KEY = '@welcome';

// Welcome.propTypes = {};

export default function Welcome() {
  const [isVisible, setIsVisible] = useState(false);

  const onAccept = () => {
    localStorage.setItem(STORAGE_KEY, 'false');
    setIsVisible(false);
  };

  useEffect(() => {
    if (!localStorage.getItem(STORAGE_KEY)) {
      setIsVisible(true);
    }
  }, []);

  if (!isVisible) {
    return null;
  }

  return createPortal(
    <div className={styles.Root}>
      <div className={styles.body}>
        <div>
          <div className={styles.title}>
            🌻 Bienvenue sur Kunbis !
          </div>

          <div className={styles.text}>
            Kunbis est une plateforme qui met en lumière des solutions concrètes face à l’urgence climatique, écologique et sociale en donnant la parole à ceux qui agissent sur le terrain.
            <br /><br />
            Chaque clic a été conçu pour faire avancer l’impact d’un projet.
            <br /><br />
            En interagissant sur Kunbis, je suis conscient :
            <br /><br />
            <img src={IconCheckmark} alt='' /> de rejoindre une communauté de créateurs d’impact ! 🤝
            <br />
            <img src={IconCheckmark} alt='' /> qu’ensemble on peut changer le cours des choses. 💪
          </div>
        </div>

        <div className={styles.footer}>
          <Button
            className={styles.buttonAccept}
            label='Je clique'
            onClick={onAccept} />
        </div>
      </div>
    </div>,
    window.document.body
  );
}
