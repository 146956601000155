import {
  BackgroundProps,
} from './types';
import styles from './index.module.scss';
import { useEffect, useState } from 'react';
import director from './director';
import { Unsubscribe } from 'app/global';

function BackgroundProvider(): JSX.Element | null {
  const [props, setProps] = useState<BackgroundProps>(director().backgroundProps);
  const classNames: string[] = [
    styles.root,
    props.className || '',
    props.headerOnly ? styles.headerOnly : '',
  ];

  useEffect((): Unsubscribe => {
    return director().observe(() => {
      setProps(director().backgroundProps);
    });
  }, []);

  if (!props.image) {
    return null;
  }
  
  return (
    <div
      className={classNames.join(' ')}
      style={{
        backgroundImage: `url(${props.image})`,
      }} />
  );
}

export default BackgroundProvider;
