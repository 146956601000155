import { ReactElement } from 'react';
import styles from './index.module.scss';

interface CardProps {
  logo: string;
  title: string;
  subtitle: string;
  description: string;
  onClick: () => void;
}

export default function Card(
  props: CardProps,
): ReactElement {
  const { logo, title, subtitle, description, onClick } = props;

  return (
    <div
      className={styles.root}
      onClick={onClick}>
      <div className={styles.header}>
        <img src={logo} alt='' />
        
        <div className={styles.body}>
          <div className={styles.title}>
            {title}
          </div>

          <div className={styles.subtitle}>
            {subtitle}
          </div>
        </div>
      </div>

      <div className={styles.description}>
        {description}
      </div>
    </div>
  );
}
