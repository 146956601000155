import { ReactElement } from 'react';
// import { Colors } from 'app/scenes/votePreview/config';
import styles from './index.module.scss';
import { RootModuleProps, EventCampaign } from '../config';
import { useWindowResize } from 'app/use/useWindowResize';
import useLanguage from 'app/alumni/useLanguage';
// import useSetBackground from '../useSetBackground';

export default function Live(
  props: RootModuleProps,
): ReactElement {
  const { campaigns } = props;
  const { height } = useWindowResize();

  const { english } = useLanguage();

  // useSetBackground(setBackground, SkemaColor.RedGradient);

  // let highestVoteCount: number = 0;
  let totalVoteCount: number = 0;

  for (let i = 0; i < campaigns.length; i++) {
    const campaign = campaigns[i];

    // if (campaign.votesCount > highestVoteCount) {
    //   highestVoteCount = campaign.votesCount;
    // }

    totalVoteCount += campaign.votesCount;
  }
 
  const renderCampaign = (
    item: EventCampaign,
    index: number,
  ): ReactElement => {
    // const color: string = Colors[index];
    let pecentage: number = 0;

    if (totalVoteCount > 0) {
      pecentage = Math.round((item.votesCount / totalVoteCount) * 100);
    }

    return (
      <div
        key={item.id}
        className={styles.campaign}
        style={{
          height: `${height * 0.7}px`,
        }}>
        <div className={styles.percent}>
          {pecentage}%
          <div>
            {item.votesCount > 1
              ? `${item.votesCount} ${english ? 'voters' : 'votants'}`
              : `${item.votesCount} ${english ? 'voter' : 'votant'}`
            }
          </div>
          {item.votesCount > 0 && (
            <div>
              {item.votesCount * 5} €
            </div>
          )}
        </div>

        <div
          className={styles.media}
          style={{
            height: `${pecentage}%`,
          }}>
          <div className={styles.logo}>
            <img src={item.logo} alt='' />
          </div>
          
          {/* <video
            src={item.media}
            poster={item.media.replace('.mp4', '.jpg')}
            controls={false} /> */}

          {/* <div
            className={styles.overlay} /> */}

          <div className={styles.campaignName}>
            {english
              ? item.name_eng
              : item.name
            }
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.root}>
      <div className={styles.title}>
        {english
          ? 'REAL-TIME VOTES'
          : 'VOTES EN TEMPS RÉEL'
        }
      </div>

      <div className={styles.campaigns}>
        {campaigns.map(renderCampaign)}
      </div>
    </div>
  );
}
