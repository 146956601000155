import React, {
  useEffect
} from 'react';
import * as Sentry from '@sentry/react';
import Button from 'app/components/common/Button';
// import styles from './index.module.scss';
// Assets
import IconKunbis from './assets/logo-kunbis.svg';

export default function Center(props) {
  const {
    className,
    onClick
  } = props;

  useEffect(() => {
    if (window.location.search.indexOf('Feedback=1') > -1) {
      Sentry.captureException(new Error('Feedback'));
    }
  }, []);

  return (
    <Button className={className}>
      <img
          onClick={onClick}
          src={IconKunbis}
          alt="Logo Kunbis" />
    </Button>
  );
}
