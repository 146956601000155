
import {
  CityTypes,
} from 'app/graphql/types/city';
import {
  AddressTypes,
} from 'app/graphql/types/address';
import {
  CategoryType,
} from 'app/graphql/types/category';
import {
  UserTypes,
} from 'app/graphql/types/user';
import {
  LocationFactory,
  CategoryFactory,
} from '.';

class UserFactory {

  public static createUser(user: any): UserTypes.User {
    const city: CityTypes.City = LocationFactory.createCity(user?.city);
    const explorerCity: CityTypes.City = LocationFactory.createCity(user?.explorerCity);
    const address: AddressTypes.Address[] = LocationFactory.createAddress(user?.address);
    const explorerCategory: CategoryType.Explorer[] = CategoryFactory.createExplorerCategory(user?.explorerCategory);
    const expertCategory: CategoryType.Expert[] = CategoryFactory.createExpertCategory(user?.expertCategory);
    const campaignRoles: string[] = UserFactory.createCampaignRoles(user?.campaignRoles);

    return {
      id: user?.id || 0,
      userUuid: user?.userUuid || '',
      email: user?.email || '',
      phone: user?.phone || undefined,
      role: user?.role || '',
      title: user?.title || undefined,
      name: user?.name || undefined,
      firstName: user?.firstName || undefined,
      lastName: user?.lastName || undefined,
      description: user?.description || undefined,
      instagramHandle: user?.instagramHandle || undefined,
      linkedinLink: user?.linkedinLink || undefined,
      position: user?.position || undefined,
      avatar: user?.avatar || undefined,
      helperDescription: user?.helperDescription || undefined,
      explorerHowLong: user?.explorerHowLong || undefined,
      explorerCanCityOut: user?.explorerCanCityOut || undefined,
      explorerHowLongType: user?.explorerHowLongType || undefined,
      explorerStartAt: user?.explorerStartAt || undefined,
      expertTitle: user?.expertTitle || undefined,
      expertHowLong: user?.expertHowLong || undefined,
      expertHowLongType: user?.expertHowLongType || undefined,
      expertMoreTimes: user?.expertMoreTimes || undefined,
      isNewRepliesNotiff: user?.isNewRepliesNotiff || false,
      isProjectUpdatesNotiff: user?.isProjectUpdatesNotiff || false,
      isNewsletterNotiff: user?.isNewsletterNotiff || false,
      isFollowedNotiff: user?.isFollowedNotiff || false,
      isNewSupportNotiff: user?.isNewSupportNotiff || false,
      isNewCommentNotiff: user?.isNewCommentNotiff || false,
      isCancelSupportNotiff: user?.isCancelSupportNotiff || false,
      isNewQuestionNotiff: user?.isNewQuestionNotiff || false,
      isPasswordSet: user?.isPasswordSet || false,
      helperFilled: user?.helperFilled || false,
      createdAt: user?.createdAt || 0,
      updatedAt: user?.updatedAt || 0,
      verifiedAt: user?.verifiedAt || undefined,
      lastNotificationReadAt: user?.lastNotificationReadAt || undefined,
      wallet: user?.wallet || undefined,
      city: city.id ? city : undefined,
      explorerCity: explorerCity.id ? explorerCity : undefined,
      address,
      explorerCategory,
      expertCategory,
      campaignRoles,
      favoriteCount: user?.favoriteCount || 0,
      contributionCount: user?.contributionCount || 0,
      notificationCount: user?.notificationCount || 0,
      gifts: user?.gifts?.length ? user.gifts.map(UserFactory.createUserGift) : [],
      companyId: user?.company?.id || 0,
      company: {
        id: user?.company?.id || 0,
        logo: user?.company?.logo || null,
        name: user?.company?.name || '',
      }
    };
  }

  private static createUserGift(data?: UserTypes.Gift): UserTypes.Gift {
    return {
      id: data?.id || 0,
      wallet: data?.wallet || 0,
      amount: data?.wallet || 0,
      user: {
        name: data?.user?.name || '',
      },
    };
  }

  public static createCampaignRoles(roles: any[]): string[] {
    if (Array.isArray(roles)) {
      return roles;
    }

    return [];
  }

  public static createUpdateUser(update: any): UserTypes.UpdateUser {
    const address: AddressTypes.AddressUpdate[] = LocationFactory.createAddressUpdate(update?.address);
    const expertCategory: CategoryType.ExpertUpdate[] = CategoryFactory.createExpertCategoryUpdate(update?.expertCategory);
    const explorerCategory: CategoryType.ExplorerUpdate[] = CategoryFactory.createExplorerCategoryUpdate(update?.explorerCategory);

    return {
      phone: update?.phone,
      firstName: update?.firstName,
      lastName: update?.lastName,
      name: update?.name,
      title: update?.title,
      instagramHandle: update?.instagramHandle,
      linkedinLink: update?.linkedinLink,
      avatar: update?.avatar,
      position: update?.position,
      description: update?.description,
      helperDescription: update?.helperDescription,
      cityId: update?.cityId,
      explorerCityId: update?.explorerCityId,
      explorerCanCityOut: update?.explorerCanCityOut,
      explorerHowLong: update?.explorerHowLong,
      explorerHowLongType: update?.explorerHowLongType,
      explorerStartAt: update?.explorerStartAt,
      expertTitle: update?.expertTitle,
      expertHowLong: update?.expertHowLong,
      expertHowLongType: update?.expertHowLongType,
      expertMoreTimes: update?.expertMoreTimes,
      helperFilled: update?.helperFilled,
      expertCategory,
      explorerCategory,
      address,
      deletedAt: update?.deletedAt,
      lastNotificationReadAt: update?.lastNotificationReadAt,
      lastFavReadAt: update?.lastFavReadAt,
      isNewRepliesNotiff: update?.isNewRepliesNotiff,
      isProjectUpdatesNotiff: update?.isProjectUpdatesNotiff,
      isNewsletterNotiff: update?.isNewsletterNotiff,
      isFollowedNotiff: update?.isFollowedNotiff,
      isNewSupportNotiff: update?.isNewSupportNotiff,
      isNewCommentNotiff: update?.isNewCommentNotiff,
      isCancelSupportNotiff: update?.isCancelSupportNotiff,
      isNewQuestionNotiff: update?.isNewQuestionNotiff,
      oldPassword: update?.oldPassword,
      password: update?.password,
    };
  }

}

export default UserFactory;
