import {
  gql,
} from "@apollo/client";

export const GetUserGiftContributions = gql`
  query contributions($userId: Int, $giftId: Int) {
    contributions(userId: $userId, giftId: $giftId) {
      id
      amount
      campaign {
        id
        project {
          name
          logo
          subtitle
          description
          city {
            city
            country
          }
          goalProject {
            goal {
              id
              color
              icon
              name
            }
          }
        }
      }
    }
  }
`;