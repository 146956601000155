
export enum RootPath {
  Vote = '/vote',
  Projects = '/projects',
  Live = '/live',
}

export interface SetBackground {
  setBackground: (color: SkemaColor) => void;
}

export interface RootModuleProps extends SetBackground {
  campaigns: EventCampaign[];
  vote: (campaignId: number) => void;
}

export interface EventCampaign {
  id: number;
  name: string;
  projectTitle: string;
  description: string;
  logo: string;
  media: string;
  votesCount: number;
}

export enum SkemaColor {
  Beige = '#F1ECE1',
  RedGradient = 'linear-gradient(130.86deg, #E7433C 6.26%, #BF0030 100%)',
}
