import * as Sentry from '@sentry/react';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Integrations } from '@sentry/tracing';
import 'intersection-observer';
import 'toastify-js/src/toastify.css';
import './index.css';

Sentry.init({
  dsn: 'https://166a6ecf945e4c77a0e765510ce38394@o1066848.ingest.sentry.io/6059940',
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,

  beforeSend(event, hint) {
    // @ts-ignore
    if (event?.exception?.values?.length > 0 && event.exception.values[0]?.value === 'Feedback') {
      Sentry.showReportDialog({
        eventId: event.event_id
      });
    }

    return event;
  },
});

const root = createRoot(
  document.getElementById('root')
);

if (window.location.href.includes('skema')) {
  const Skema = require('app/skema').default;

  root.render(
    <StrictMode>
      <Skema />
    </StrictMode>
  );
} else if (window.location.href.includes('alumni')) {
  const Alumni = require('app/alumni').default;

  root.render(
    <StrictMode>
      <Alumni />
    </StrictMode>
  );
} else {
  const { ApolloProvider } = require('@apollo/client');
  const { BrowserRouter } = require('react-router-dom');
  const { HelmetProvider } = require('react-helmet-async');
  const { Elements } = require('@stripe/react-stripe-js');

  const App = require('./app').default;

  const { apolloClient } = require('app/api');
  const { loadStripe } = require('@stripe/stripe-js');

  const stripe = loadStripe(process.env.REACT_APP_STRIPE_KEY);

  root.render(
    <StrictMode>
      <ApolloProvider client={apolloClient}>
        <BrowserRouter>
          <HelmetProvider>
            <Elements stripe={stripe}>
            <App />
            </Elements>
          </HelmetProvider>
        </BrowserRouter>
      </ApolloProvider>
    </StrictMode>
  );
}
