import {
  ApiDomain,
  RequestMethod,
} from './config';
import {
  Payload,
  Endpoint,
} from 'app/api';

export * from './config';

class GenericRequest {
  private method: RequestMethod;
  private endpoint: Endpoint.Type;
  private payload?: Payload.Type;

  constructor(method: RequestMethod, endpoint: Endpoint.Type, payload?: Payload.Type) {
    this.method = method;
    this.endpoint = endpoint;
    this.payload = payload;
  }

  public async start(): Promise<unknown> {
    const url: string = this.buildFetchURL();
    const params: RequestInit = this.buildFetchParams();

    const response: Response = await fetch(url, params);

    if (response.status >= 400) {  
      throw await response.json();
    }
    
    return await response.json();
  }

  private buildFetchURL(): string {
    const url: string = `${ApiDomain}${this.endpoint}`;

    if (this.method === RequestMethod.Post) {
      return url;
    }
    
    if (this.method === RequestMethod.Get) {
      return `${url}${this.buildQueryString()}`;
    }

    throw new Error(`Unsupported request method: ${this.method}`);
  }

  private buildQueryString(): string {
    if (this.payload) {
      // @ts-ignore
      const query: string = new URLSearchParams(this.payload).toString();

      return `?${query}`;
    }

    return '';
  }

  private buildFetchParams(): RequestInit {
    return {
      method: this.method,
      headers: new Headers({
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      }),
      referrerPolicy: 'strict-origin-when-cross-origin',
      credentials: 'include',
      body: this.buildPostBody(),
    };
  }

  private buildPostBody(): string | null {
    if (this.method === RequestMethod.Post) {
      if (this.payload) {
        return JSON.stringify(this.payload);
      }
    }

    return null;
  }

}

export default GenericRequest;