import React, { useState } from 'react';
import Button from 'app/components/common/Button';
import Text from 'app/components/common/Input/Text';
import styles from './index.module.scss';
// Assets
import IconSuccess from './assets/success.svg';

export default function AuthForgotPassword(props) {
  const {
    onSubmitRequest: _onSubmitRequest,
    onSubmitReset: _onSubmitReset,
    onTryAgain,
    isProcessing,
    isSuccess,
    isReset,
  } = props;
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');

  const onSubmitRequest = () => _onSubmitRequest(email);
  const onSubmitReset = () => _onSubmitReset(password);

  const renderRequest = () => (
    <>
      <div className={styles.title}>Vous avez oublié votre mot de passe ?</div>

      <div className={styles.text}>
        Saisissez l'adresse e-mail que vous avez utilisé pour vous inscrire et nous vous enverrons
        un lien pour réinitialiser votre mot de passe.
      </div>

      <Text
        type="email"
        autoComplete="email"
        placeholder="Email"
        onChangeText={setEmail}
        value={email}
      />

      <Button
        className={styles.submit}
        isProcessing={isProcessing}
        isDisabled={!email}
        label="Ok"
        onClick={onSubmitRequest}
      />
    </>
  );
  const renderSuccess = () => (
    <>
      <img className={styles.iconSuccess} src={IconSuccess} alt="" />

      <div className={styles.title}>Un e-mail vous a été envoyé !</div>

      <div className={styles.text}>
        Consultez votre boite mail
        <span>{email}</span>
        pour obtenir nos instructions afin de réinitialiser votre mot de passe.
      </div>

      <div className={styles.text2}>
        Si vous ne recevez pas un Email dans les minutes qui suivent,
        <span onClick={onTryAgain}>veuillez réessayer.</span>
      </div>
    </>
  );
  const renderReset = () => (
    <>
      <div className={styles.title}>Définir un nouveau mot de passe</div>

      <div className={styles.text}>
        Veuillez saisir un mot de passe d'au moins 12 caractères dont 3 types de caractères
        différents : majuscules, minuscules et chiffres
      </div>

      <Text
        className={styles.InputPassword}
        type="password"
        placeholder="Entrez votre nouveau mot de passe"
        onChangeText={setPassword}
        value={password}
      />

      <Text
        type="password"
        placeholder="Saisissez à nouveau votre nouveau mot de passe"
        onChangeText={setPasswordConfirm}
        value={passwordConfirm}
      />

      <Button
        className={styles.submit}
        isProcessing={isProcessing}
        isDisabled={!password || password !== passwordConfirm}
        label="Ok"
        onClick={onSubmitReset}
      />
    </>
  );

  return (
    <div className={styles.RootContainer}>
      <div className={styles.body}>
        {isReset ? renderReset() : isSuccess ? renderSuccess() : renderRequest()}
      </div>
    </div>
  );
}
